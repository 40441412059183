<template>
  <div>
    <div class="selected_room card">
      <slot name="bottom_part">
        <div class="bottom">
          <div class="left-pane">
              <carousel size="small" :imgs="[cover_image]" v-if="cover_image && cover_image.url"></carousel>
            <div class="list-card-pic" v-else>
              <div class="no-img">
                <i class="el-icon-picture-outline no-img-icon"></i>
                <span>No Image Found</span>
              </div>
            </div>
          </div>
          <div class="right-pane">
            <div class="name">{{room_details.first_name+" "+ room_details.last_name}}</div>
            <span class="bold">{{ room_details.room_type_name + (room_details.room_unit_number ? ' - ' + room_details.room_unit_number : '')}}</span>
            <slot name="rate_name">
              <span>{{ display_code ?  display_code : room_details.rate_code_name }}</span>
            </slot>
            <span>{{ room_details.check_in_date | formatDate('dddd, MMM DD')}} - {{room_details.check_out_date | formatDate('dddd, MMM DD YYYY')}}</span>
            <span>
                  {{ totalNights }}{{ adults ? `, ${adults}` : '' }}{{ children ? ` ${children}` : '' }}
              </span>

            <slot name="pane_content">
                  <span class="package" v-if="!!strg_amenities && strg_amenities.length > 0">
                      {{ $t('lbl_package_includes') }}: {{ strg_amenities.slice(0, -2) }}
                  </span>
            </slot>
            <slot name="bottom_right"></slot>
          </div>
        </div>
      </slot>
      <div class="none-data">
        <div class="check_in_date">{{ room_details.check_in_date | formatDate }}</div>
        <div class="check_out_date">{{ room_details.check_out_date | formatDate }}</div>
        <div class="destination_city">{{ property.city }}</div>
        <div class="destination_state">{{ property.country }}</div>
        <div class="hotel_property">{{ property.name }}</div>
        <!--        <div class="number_of_rooms">{{ reservationInfo.rooms_requested.length }}</div>-->
        <div class="property_id">{{ property.code }}</div>
        <div :class="`number_of_guests-${index}`">{{
            adultsCount + childrenCount
          }}
        </div>
        <div :class="`purchase_price-${index}`">{{ room_details.total_amount }}</div>
        <div class="purchase_currency">{{ property.currency }}</div>
        <div :class="`confirmation-number-${index}`">{{ room_details.confirmation_no }}</div>
        <div class="hashed_email">{{ hashed_email }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import carousel from '@/components/carousel/carousel.vue';
import * as CryptoJS from 'crypto-js'
import {mapMutations, mapState} from 'vuex';
import dayJs from "dayjs";

export default {
  components: {
    carousel,
  },
  props: {
    room_details: {
      type: Object,
      required: true,
    },
    amenities: {
      required: false,
    },
    index: {
      type: Number
    },
    display_code:  String
  },
  computed: {
    ...mapState({
      mobileView: state => state.mobileView,
      steps: state => state.steps,
      property: state => state.property.details,
      selected_room_details: state => state.selected_room_details

    }),
    totalNights() {
      let nightCount = dayJs(this.room_details.check_out_date).utc().diff(dayJs(this.room_details.check_in_date).utc(), 'days')
      return nightCount > 1 ? nightCount + ` ${this.$i18n.t('lbl_night')}s` : nightCount + ` ${this.$i18n.t('lbl_night')}`;
    },
    adultsCount() {
      return this.room_details?.number_of_adults
    },
    childrenCount() {
      return this.room_details?.number_of_children
    },
    adults() {
      return this.room_details?.adults && this.room_details?.adults + ` ${this.room_details?.adults > 1 ? this.$i18n.t('lbl_adults') : this.$i18n.t('lbl_adult')}`;
    },
    children() {
      return this.room_details?.children && this.room_details?.children + ` ${this.room_details?.children > 1 ? this.$i18n.t('lbl_children') : this.$i18n.t('lbl_child')}`;
    },
    hashed_email() {
      if (this.$store.state.guest_data) {
        let hash = CryptoJS.SHA256(this.$store.state.guest_data.email)
        return CryptoJS.enc.Base64.stringify(hash)
      }
      else return null
    },
    cover_image() {
      if (this.room_details?.cover_photo) {
        return {
          url: this.room_details.cover_photo
        }
      }
      if (this.room_details?.room?.images && this.room_details?.room?.images?.length > 0) {
        return {
          url: this.room_details.room.images[0].url
        }
      }
      return {
        url: null
      }
    },
    strg_amenities() {
      return !!this.amenities && this.amenities.length > 0 ? this.amenities.reduce((b, a) => {
        return b += `${a.name.toLowerCase()}, `;
      }, '') : null;
    },

  },
  methods: {
    ...mapMutations({
      CHANGE_STEP: 'CHANGE_STEP'
    }),
    async bookAnotherReservation() {
      await this.CHANGE_STEP(this.findFirstStep().key);
    },
    findFirstStep() {
      let arrayOfSteps = Object.entries(this.steps).map(s => s)
      let firstStep = arrayOfSteps.find(s => s[1].index === 0)[1]
      return firstStep;
    },
  },
  beforeMount(){
    console.log(this.display_code)
  }
};
</script>

<style lang="scss" scoped>
@import "src/assets/sass/config/mixins";

.dates {
  text-transform: uppercase;
}

.top {
  p,
  span {
    font-size: .85rem;
    font-weight: bold;
    margin: 0px;
  }
}

.bottom {
  display: flex;

  .right-pane {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-left: 20px;

    span, p {
      font-size: .85rem;
      margin: 0px;
    }

    .package {
      font-weight: normal;
      text-transform: capitalize;
      word-break: break-all;
      color: #868383;
    }
  }
}
.list-card-pic {
  background-color: lightgray;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  width: 250px;
  border-radius: 5px;
  .no-img-icon{
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-content: center;
    padding-bottom: 5px;
  }
}
@include media('<=tablet') {
  .bottom {
    flex-direction: column;
  }
  .right-pane {
    margin: 10px 0px !important;
  }
  .list-card-pic{
    width: 100%;
  }
}

.none-data {
  display: none;
}
.name{
  font-weight: bold;
}
</style>
