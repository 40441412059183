<template>
  <div>
    <div v-if="property">
      <div class="icon">
         <svg class="calendar-icon">
            <use xlink:href="@/assets/calendar_event.svg#calendarIcon"></use>
          </svg>
      </div>
      <div class="label">
        <div class="checkIn-label">Check In</div>
        <div class="checkOut-label">Check Out</div>
      </div>
      <DatePicker
        :gridStyle="false"
        :showPrice="true"
        :periodDates="periodDates"
        ref="date-picker"
        format="MMM DD, YYYY"
        :hoveringTooltip="false"
        class="color-active"
        :startDate="getCbdAsDate"
        :endDate="getEndDate"
        :key="pickerInputKey"
      />
    </div>
    <div v-else style="position:relative;height:30px !important" class="flexed justify-center align-center">
      <loading></loading>
    </div>

  </div>
</template>

<script>
import DatePicker from 'vue-hotel-datepicker'
import 'vue-hotel-datepicker/dist/vueHotelDatepicker.css';
import dayjs from "dayjs";
import loading from '@/components/common/loading.vue';
import { mapState } from 'vuex';

export default {
  name: "date-filter",
  components: {
    DatePicker, loading
  },
  data() {
    return {
      loading: false,
      newDate: dayjs().add(1, 'd').toDate(),
      periodDates: [],
      activeMonthIndex: null,
      pickerInputKey: 1
    }
  },
  beforeMount() {

  },
  computed: {
    ...mapState( {
      property: state => state.abs.property,
      businessDay: state => state.abs.property.business_day.date.split('T')[0]
    }),
    getCbdAsDate(){
      return dayjs(this.businessDay).format()
    },
    getEndDate() {
      return dayjs(this.businessDay).add((this.profile?.restrictions?.booking_profile?.max_los || 200) + 1, 'd').format()
    }

  },
  methods: {
  },
}
</script>
<style lang="scss">
@import "../../../../assets/sass/config/variables";
@import "../../../../assets/sass/config/mixins";

.icon{
  height: 0;
   position: relative;
  svg.calendar-icon{
    height: 21px;
    width:21px;
    margin-right: 3px;
    fill: $hk-primary-color !important;
    position: relative;
    left: 10px;
    transform: translateY(50% + 30);
  }

}
.vhd__datepicker__dummy-wrapper {
  display: flex;
  align-items: center;
  border: 1px solid #DCDFE6;
  border-radius: 5px;
}

.vhd__datepicker__wrapper { height: 55px;
  background-origin: content-box;
  background-position: 10px 50%;
  background-size: 18px;
  background:none !important;
  .vhd__datepicker {
    width: 200% !important;

    .vhd__datepicker__months {
      width: unset !important;
    }
  }
}

@include media('<=tablet') {
  .vhd__datepicker__wrapper {
    height: 55px;

    .vhd__datepicker {
      width: 100% !important;

      .vhd__datepicker__months {
        width: unset !important;
      }
    }
  }
}


.vhd__datepicker__clear-button {
  width: .6em;
  margin-right: 10px;
  font-size: 30px;
}

.vhd__datepicker__month-button {
  width: 20px;
  min-width: 80px;
  border-color: $hk-primary-color !important;
  border-radius: 5px;
  background-color: $hk-primary-color !important;
  color: white !important;
  background-image: url("../../../../assets/right.svg");
  background-size: 14px;
}

.vhd__datepicker__dummy-wrapper--is-active {
  border-color: $hk-primary-color !important;
  border-radius: 5px;
}

.vhd__datepicker__clear-button svg {
  opacity: 0.7;
  width: .36em;
}

.vhd__datepicker__header-mobile {
  display: flex;
  justify-content: space-between;
}

.vhd__datepicker__tooltip--mobile {
  display: none;

}

.vhd__datepicker .vhd__show-tooltip .vhd__datepicker__months {
  margin-top: 2.2em;
}

.vhd__datepicker__header {
  display: flex;
  justify-content: space-between;
}

.vhd__datepicker__month-day--today .vhd__datepicker__month-day-wrapper {
  border-color: $hk-primary-color;
  border-radius: 5px;
}

.vhd__datepicker__month-day-wrapper:hover {
  background-color: $hk-primary-color !important;
  color: white;
}

.vhd__datepicker__month-day--first-day-selected,
.vhd__datepicker__month-day--last-day-selected {
  background-color: $hk-primary-color !important;
}

.vhd__datepicker__month-day--last-day-selected {
  border-bottom-right-radius: 50%;
  border-top-right-radius: 50%;
}

.vhd__datepicker__month-day--first-day-selected {
  border-bottom-left-radius: 50%;
  border-top-left-radius: 50%;
}

.vhd__datepicker__month-day--selected {
  background-color: $hk-primary-color !important;
}

.vhd__datepicker__month-day--hovering {
  background-color: $hk-primary-color !important;
}

.vhd__datepicker__month-day-wrapper {
  .day {
    font-size: 1rem;
    font-weight: normal;
  }

  .price {
    top: unset;
    bottom: 0;
  }

}

@include media('<=tablet') {
  .minimumDurationUnvalidDay {
    background-color: #337ec2 !important;
    border-radius: 5px;

    :first-child {
      color: white;
    }
  }
  .vhd__datepicker__close-button {
    margin-top: -10px;
    margin-right: -5px;
  }
  .vhd__datepicker__month:last-of-type {
    margin-top: 4vh;
  }

}
</style>

<style lang="scss" scoped>
@import "../../../../assets/sass/config/variables";
@import "../../../../assets/sass/config/mixins";

.label {
  display: flex;
  justify-content: space-around;
  color: #b1b1b1;
  font-size: 11px;
  position: relative;
  z-index: 1;
  height: 0;
  top: 2px;
}

.checkIn-label {
  position: relative;
  left: -20px;
}

.checkOut-label {
  position: relative;
  left: -17px;
}

.entire {
  position: relative;
  background: white;
  width: 60vw;
  height: 97vh;
  z-index: 2;
  left: -11px;
  top: -14px;
}

.color-active .datepicker__dummy-input--is-active {
  color: #4182e0;
}

.vhd__datepicker__header-mobile {

}
</style>
