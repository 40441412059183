<template>
  <div v-loading="loading">
     <div class="title m-b-10" v-if="!mobileView"> Enhance Your Stay</div>
      <component
        :is="!mobileView && selected_room_details.length > 1 && !enableMultiRoomBooking ? 'el-tabs' : 'div'"
        type="card"
        :class="{'is-active': activeRoomIndex}"
        v-model="activeRoomIndex"
      >
        <component
          :is="!mobileView && selected_room_details.length > 1 && !enableMultiRoomBooking ? 'el-tab-pane' : 'div'"
          :label="`Select For Room ${roomIndex +1}`"
          v-for="(room, roomIndex) in !mobileView ? (enableMultiRoomBooking ? [selected_room_details[0]] : selected_room_details) : [selected_room_details[activeRoomIndex]]"
          :key="roomIndex">
          <div class="addons-table" v-if="!mobileView" >
           
            <div class="addons-table" >
              <div class="wrapper">
                <div class="swipe-cards" v-if="addons.length > 3"
                >
                  <i @click="swipeCards('decrease')" class="el-icon-arrow-left"></i>
                </div>
                <div class="addons">
                  <div class="addons-card" v-for="(item, addonsIndex) in filteredAddons" :key="addonsIndex">
                    <div class="addons-img">
                      <img class="img" :src="item.icon"/>
                    </div>
                    <div class="addons-name">
                      <span>{{ item.name }}</span>
                    </div>
                    <div class="inputs">
                      <div class="charge-details">
                        <span class="label">Estimated Rate</span>
                        <span class="label bold">{{ item.default_amount | currency }}</span>
                      </div>
                      <div class="charge-details">
                        <span class="label">Quantity</span>
                        <el-input-number
                          size="small"
                          style="width: 60%; "
                          v-model="item.quantity"
                          :min="1" :max="99"
                          :disabled="isChosen(item, room)"
                          @change="saveAddonQuantity(item, room)"
                          @keypress.native="checkForDecimalNumbers($event, item, 99, 1)">

                        </el-input-number>
                      </div>
                    </div>
                    <div class="addons-btn">
                      <el-button
                        style="width: 90%"
                        size="small"
                        v-if="!isChosen(item, room)"
                        type="primary"
                        @click="chooseAddon(item, room)"
                      >Add to Room
                      </el-button>

                      <el-button
                        style="width: 90%"
                        size="small"
                        v-else
                        type="danger"
                        @click="removeAddon(item, room)"
                      >Remove
                      </el-button>
                    </div>
                  </div>
                </div>
                <div
                  class="swipe-cards" v-if="addons.length > 3">
                  <i @click="swipeCards('increase')" class="el-icon-arrow-right"></i>
                </div>
              </div>
            </div>
          
          </div>
          <div v-else class="p-15" >
            <div class="title m-b-10"> Enhance Your Stay</div>
              <el-select
                v-if="selected_room_details.length > 1"
                v-model="activeRoomIndex"
                class="w-100 rooms-drop-down m-b-10" 
                :placeholder="$t('lbl_select_room')">
                <el-option
                  v-for="(item, index) in selected_room_details"
                  :key="index"
                  :label="`${$t('Select for Room')} ${index + 1}`"
                  :value="index.toString()">
                </el-option>
              </el-select>
              <div class="addons-card-mobile bordered border-radius-5 m-b-10" v-for="(item, addonsIndex) in addons" :key="addonsIndex">
                  <div class="flexed ">
                      <img  :src="item.icon"/>
                      <div class="flexed-column justify-between">
                        <span>{{item.name}}</span>
                        <div class="flexed justify-between">
                          <span class="label">Estimated Rate</span>
                          <span class="label bold">{{ item.default_amount | currency }}</span>
                        </div>
                          <el-input-number @change="saveAddonQuantity(item, room)"  style="width: 100%;" v-model="item.quantity" :min="1" :max="99"
                          :disabled="isChosen(item, room)" @keypress.native="checkForDecimalNumbers($event, item, 99, 1)"> </el-input-number>
                      </div>
                  </div>
                  <div>
                    <el-button
                        v-if="!isChosen(item, room)"
                        type="primary"
                        @click="chooseAddon(item, room)"
                      >Add to Room
                      </el-button>

                      <el-button
                        v-else
                        type="danger"
                        @click="removeAddon(item, room)"
                      >Remove
                      </el-button>
                  </div>
                
              </div>
          </div>
      </component>
    </component>
  </div>
</template>

<script>
import CommonServices from "@/services/common.services";
import {mapActions, mapMutations, mapState} from "vuex";
import dayjs from "dayjs";
import deepClone from 'clone';
import {decimalNumbers} from '@/services/utilities.service';

export default {
  name: "SelectAddons",
  computed: {
    ...mapState({
      chosenAddon: state => state.chosenAddon,
      profile: state => state.profile,
      search_data: state => state.search_data,
      mobileView: state => state.mobileView,
      selected_room_details: state => state.selected_room_details,
      propertyDetails: state => state.property.details,
    }),
    filteredAddons() {
      if (this.addons) {
        return this.addons.slice(this.showAddonsStart, this.showAddonsEnd)
      }
      return []
    },
    getIndex(){
      return this.activeRoomIndex
    },
    enableMultiRoomBooking() {
      return this.propertyDetails.website_config?.enable_multi_room_booking 
    }
  },
  watch: {
    getIndex(newValue) {
       let room = this.selected_room_details[newValue]
       this.addons.forEach(addon => {
         if(room.chosenAddons?.find(e => e.id === addon.id)?.quantity > 0){
           addon.quantity = room.chosenAddons?.find(e => e.id === addon.id).quantity
         } else {
           let addonQuantity = this.addonsQuantity.find(a=>a.id=== addon.id && a.session_id === room.session_id)
           if(addonQuantity) addon.quantity = addonQuantity.quantity
           else addon.quantity = 1
         }
       });
    }
  },
  mounted() {
    this.getAddon()
    this.restricted_addons = this.profile?.restrictions?.booking_profile.restricted_addons;
  },
  data() {
    return {
      addons: [],
      showAddonsStart: 0,
      showAddonsEnd: 4,
      restricted_addons: [],
      btnLoading: false,
      loading: false,
      activeRoomIndex: '0',
      addonsQuantity:[],
      decimalNumbers: decimalNumbers
    }
  },
  methods: {
    ...mapMutations({
        SELECTED_ROOM_DETAILS: 'SELECTED_ROOM_DETAILS'
    }),
    saveAddonQuantity(item, room){
      this.addonsQuantity = this.addonsQuantity.filter(q=> q.id != item.id && q.session_id != room.session_id)
      this.addonsQuantity.push({id: item.id, session_id: room.session_id, quantity: item.quantity})
    },
    swipeCards(sign) {
      let maxLimit = this.addons.length
      if (sign === 'increase') {
        if (maxLimit >= this.showAddonsEnd + 1) {
          this.showAddonsStart++;
          this.showAddonsEnd++
        }
      } else {
        if (this.showAddonsStart >= 1) {
          this.showAddonsStart--
          this.showAddonsEnd--
        }
      }
    },
    getAddon() {
      this.loading = true
      let params = {
        category: 'MISC_CHARGE',
        enabled_for_addons: true,
      }
      CommonServices.getAddon(params)
        .then((res) => {
           let addons = res.data.flatMap(e => e.charge_types)
           addons = addons.filter(a=> this.restricted_addons.some(ra=>ra.id === a.id))
          if(addons?.length > 0){
             addons.map(addon => {
              addon.quantity = 1
              addon.selected = false
              addon.recurring = this.restricted_addons.find(r=>r.id === addon.id).recurring
            })
          }
         this.addons  = addons
        })
        .catch((err) => console.log(err))
        .finally(() => {
          this.loading = false
        })
    },

    isChosen(item, room) {
      let obj = room.chosenAddons?.find(e => e.id === item.id)
      if(obj && obj.quantity > 0){
        item.quantity = obj.quantity
        return true
      }
      return false
    },
    removeAddon(item, room) {
      let selectedRoomDetails = deepClone(this.selected_room_details)
      let activeRoomDetials = selectedRoomDetails.find(s=>s.session_id === room.session_id)
      if(!activeRoomDetials.chosenAddons) activeRoomDetials.chosenAddons = []
      if(activeRoomDetials.chosenAddons.filter(c=>c.id === item.id)) activeRoomDetials.chosenAddons = activeRoomDetials.chosenAddons.filter(c=>c.id != item.id)
      this.SELECTED_ROOM_DETAILS(selectedRoomDetails)
    },
    chooseAddon(item, room) {
      if(item.quantity < 1 || item.quantity > 99){
           this.$notify.error({  title: this.$t('lbl_error'),  message: 'Quantity needs to be between 1 and 99'  })
           return
      }
       
      let selectedRoomDetails = deepClone(this.selected_room_details)
      let payload = {
        amount: item.default_amount,
        quantity: item.quantity,
        date: item.date,
        id: item.id,
        room_no: room.room_no,
        session_id: room.session_id,
        recurring: item.recurring,
      }
      let activeRoomDetials = selectedRoomDetails.find(s=>s.session_id === room.session_id)
      if(!activeRoomDetials.chosenAddons) activeRoomDetials.chosenAddons = []
      if(activeRoomDetials.chosenAddons.filter(c=>c.id === item.id)) activeRoomDetials.chosenAddons = activeRoomDetials.chosenAddons.filter(c=>c.id != item.id)
      activeRoomDetials.chosenAddons.push(payload)
      this.SELECTED_ROOM_DETAILS(selectedRoomDetails)
    },
    async postAddon() {
      if (this.activeRoomIndex < this.selected_room_details.length - 1) {
          this.activeRoomIndex = (+this.activeRoomIndex + 1).toString()
          return false
      }

      for (const room of this.selected_room_details) {
        let checkIn = room.check_in_date
        let checkOut = room.check_out_date
        let initialDateRange = this.getDates(checkIn, checkOut)
        let payload = {
          reservation_addons: []
        }
        initialDateRange.forEach((date) => {
          if(room.chosenAddons?.length > 0){
            room.chosenAddons?.forEach((addon) => {
              if (addon.quantity > 0) {
                if (addon.recurring) {
                  for (let j = 0; j < addon.quantity; j++) {
                    payload.reservation_addons.push(
                      {
                        charge_type_id: addon.id,
                        quantity: 1,
                      });
                  }
                
                } else {
                  payload.reservation_addons.push({
                    charge_type_id: addon.id,
                    quantity: addon.quantity,
                    date: dayjs(date).format('YYYY-MM-DD')
                  })
                }
              } else {
                payload = {
                  reservation_addons: []
                };
              }
            })
          } else {
              payload = {
                reservation_addons: []
              };
          }
        })

       await  CommonServices.postAddon(payload, room.session_id)
          .then( async (res) => {
              
          })
          .catch(async (err) => {
            console.log(err)
            return false
          });

      }
      return (await this.getAllProfileTaxes())

    },
    async getAllProfileTaxes(){
      let selectedRoomDetails = deepClone(this.selected_room_details)
      for (const roomDetails of selectedRoomDetails) {
           await CommonServices.getProfileFolioTaxes(roomDetails.session_id)
            .then(({data}) => {
              roomDetails.profileTaxes = data
            }) .catch(async (err) => {
            console.log(err)
            return false
          });
      }
      this.SELECTED_ROOM_DETAILS(selectedRoomDetails)
      return true
    },
    getDates(startDate, stopDate) {
      stopDate = dayjs(stopDate)
      let dateArray = new Array();
      let currentDate = dayjs(startDate);
      while (currentDate < stopDate) {
        dateArray.push(currentDate);
        currentDate = dayjs(currentDate).add(1, 'days');
      }
      return dateArray;
    },
    checkForDecimalNumbers(event, item, max, min){
      let val = 1;
      if(event.path && event.path[0] && event.path[0].value) val = event.path[0].value
      this.decimalNumbers(event, val, max, min)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/config/mixins";
@import "@/assets/sass/config/variables";
.wrapper {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.swipe-cards {
  display: flex;
  align-items: center;
  font-size: 25px;
  font-weight: bold;

  i {
    padding: 0 15px;
    cursor: pointer;
  }
}

.addons {
  display: flex;
  // min-width: 95%;

  .addons-card {
    width: 260px;
    border: 1px solid #dadada;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
    display: flex;
    margin: 0 15px;
    flex-direction: column;
    justify-content: space-between;

    .addons-img {
      display: flex;
      justify-content: center;
      margin: 33px 0 0;

      .img {
        width: 200px;
        height: 180px;
        border-radius: 5px;
      }
    }

    .addons-name {
      margin: 33px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top: 1px solid #dadada;
      border-bottom: 1px solid #dadada;
      height: 45px;
      text-align: center;
    }

    .inputs {
      margin-bottom: 33px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100px;

      .charge-details {
        padding: 0 10px;
        margin: 0 0 13px 0;

        .label {
          margin: auto 0;
        }

        display: flex;
        justify-content: space-between;
      }
    }

    .input-control-right {
      :first-child {
        text-align: right;
      }
    }

    .addons-btn {
      margin-bottom: 33px;
      display: flex;
      justify-content: center;
    }
  }
}

.title {
    font-size: 16px;
    font-weight: bold;
    color: #4E4E4E;
  }

.addons-table {
  // margin: 15px;
  margin-top: 0 !important;

  

  .addons-table {
    padding: 10px;
    padding-top: 0;
    display: flex;
    border-radius: 5px;
  }
}

.addons-card-mobile{
  width:100%;
  padding: 10px;
  img {
    width: 130px;
    margin-right: 10px;
    border-radius: 5px;
  }
  .el-button{
    width: 100%;
    margin-top: 15px;
  }
}
// ::v-deep .el-input__inner{
//   text-align: right;
// }
// ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
//   margin-bottom: 0 !important;
// }


::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
  border: none !important;
}

::v-deep .el-tabs--card > .el-tabs__header {
  border-bottom: none;

  .el-tabs__item:first-child {
    border-left: 1px #dadada solid !important;
  }

  .el-tabs__item {
    margin-right: 5px;
    min-width: 200px;
    border-bottom: 1px #dadada solid !important;
    text-align: center;
  }
}

::v-deep .el-tabs__header {
  // margin: 0 !important;
  margin-bottom: 10px;

  .el-tabs__item {
    border: 1px solid #dadada;
    border-bottom: none;
    color: black;
    border-radius: 5px;

    &.is-active {
      background-color: $hk-primary-color;
      color: white;
    }
  }

  .el-tabs__item:first-child {
    border-left: 1px;
  }
}

</style>
