<template>
        <div v-loading="loading" >
            <div class="flexed align-center gap-10 m-b-10 pointer" style="max-width: 220px" @click="back()">
                <arrow-left-icon size="1.5x" class="custom-class"></arrow-left-icon>
                <span class="bold500">Search Reservation</span>
            </div> 
            
            <div class="search-reservation bordered flexed-wrap gap-10 border-radius-5 p-20 m-t-10">
                 <el-input placeholder="Enter Confirmation Number" v-model="searchModel.conf_num" :maxlength="50"
                    @keypress.native="isSpecialChar($event)"
                                @keydown.enter.prevent.native="!isSaveDisabled ? search : null" type="text"/>
                 <el-input placeholder="Enter Last Name" v-model="searchModel.last_name" :maxlength="50"
                                @keydown.enter.prevent.native="!isSaveDisabled ? search() : null"  type="text" />
                 <el-button type="primary" @click="search" :disabled="isSaveDisabled"> Search Reservation</el-button>

            </div>
             <div v-if="showNoReservationFound && !loading" class="m-t-20">
                <el-alert
                    :title="$t('lbl_no_reservation_found_message')"
                    type="info">
                </el-alert>
            </div>
        </div>
</template>

<script>
import ApiCalls from '@/services/api-calls.service.js'
import { mapMutations, mapState } from 'vuex'
import {  ArrowLeftIcon} from 'vue-feather-icons'
import {isSpecialChar} from "@/services/utilities.service";
    export default {
        name: 'SearchReservation',
        components: {
            ArrowLeftIcon,
        },
        computed: {
            ...mapState({
                propertyDetails: (state) => state.property?.details,
            }),
            isSaveDisabled(){
                return !this.searchModel.conf_num || !this.searchModel.last_name
            }
        },
        data() {
            return {
                searchModel: {
                    conf_num:null,
                    last_name:null,
                },
                loading: false,
                showNoReservationFound: false,
                isSpecialChar: isSpecialChar,
            }
        },
        methods: {
            ...mapMutations({ 
                CHANGE_STEP: 'CHANGE_STEP',
                RESET_STATE: 'RESET_STATE',
                SET_EDIT_STAY_DETAILS: 'SET_EDIT_STAY_DETAILS',
                SET_SEARCH_RESERVATION_RESULT: 'SET_SEARCH_RESERVATION_RESULT',
            }),
            async search() {
                this.showNoReservationFound = false;
                ///web/manage-booking?conf_num=6775AAA082&last_name=dasd&property_code=6775
                let params = {
                    conf_num: this.searchModel.conf_num,
                    last_name: this.searchModel.last_name,
                    property_code: this.propertyDetails.code
                }
                this.loading = true
                try {
                    let searchResult = (await ApiCalls.get('web/manage-booking', params, 'enterpriseUrl'))?.data
                    this.SET_SEARCH_RESERVATION_RESULT({...searchResult, conf_num: params.conf_num});
                    this.$router.push({name: 'search-reservation-result'}).catch(()=>{})
                   
                } catch (error) {
                    this.SET_SEARCH_RESERVATION_RESULT({});
                    this.handleError(error)
                }
                this.loading = false
            },
            handleError(error){
                console.log(error)
                this.showNoReservationFound = true
                // this.$notify.error({
                //       title: this.$t('lbl_error'),
                //       message: error?.response?.status === 400 ? 'No Reservation found' : error?.response?.data?.message
                //     })
            },
            back() {
                this.RESET_STATE()
                this.CHANGE_STEP('search');
                this.SET_EDIT_STAY_DETAILS(false)
                this.$router.push({name:'booking-search'}).catch(()=>{})
            },
        },
    }
</script>

<style lang="scss" scoped>
@import "~mixins";
    .search-reservation{
        padding: 20px;
        .el-button{
            flex: 1 !important;
        }
        .el-input {
            flex: 2 !important;
        }
    }
     @include media('<=tablet') {
        .search-reservation{
            border: none;
            padding: 2px !important;
            .el-button{
                    flex: auto !important;
            }
            .el-input {
                flex: auto !important;
            }
        }
    }
</style>