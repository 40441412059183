<template>
    <div class="img img-carousel">
          <template v-if="images">
            <el-carousel
              :arrow="images.length > 1 ? 'hover' : 'never'"
              :indicator-position="images.length > 1 ? '' : 'none'"
            ><el-carousel-item v-for="(item, index) in images" :key="index" class="list-card-pic">
                <img :src="item" alt="Cover Image"
                    @click.stop="showPopup = true"
                     width="100%" height="100%"/>
              </el-carousel-item>
            </el-carousel>
          </template>
          <div class="list-card-pic" v-else>
            <div class="no-img">
              <i class="el-icon-picture-outline no-img-icon"></i>
              <span>No Image Found</span>
            </div>
          </div>
          <modal v-if="showPopup"
                size="55%"
                size-close-icon="sm"
                @close="closeModal()">
            <div slot="modal-title" class="w-100">
                <div class="flexed justify-between align-center" style="height: 30px;">
                    <h4 class="m-5"> {{ room?.room_type_name }}</h4>
                    <el-button type="default" class="close-btn"
                    @click="closeModal()">
                    <i class="el-icon-close"></i>
                </el-button>
            </div>
            </div>
                <div slot="content" class="popup-content p-10">
                    <div class="flexed justify-center align-center" v-if="loading">
                        <loading></loading>
                    </div>
                    <div class="popup-carousel-container" v-else>
                        <el-carousel
                            :arrow="images.length > 1 ? 'hover' : 'never'"
                            :indicator-position="images.length > 1 ? '' : 'none'"
                        ><el-carousel-item v-for="(item, index) in images" :key="index" >
                            <img :src="item" alt="Cover Image"
                                width="100%" height="100%"/>
                        </el-carousel-item>
                        </el-carousel>
                    </div>
                    <div class="p-10">
                        <room-amenities :room="room" :amenitiesInfo="true"></room-amenities>
                    </div>
                    <div class="additinal_details p-10">
                        <h4 class="m-t-10 m-b-0">Additional Details</h4>
                        <div class="" v-html="renderMarkdown(additional_details)">
                        </div>
                    </div>

                </div>
                <div slot="modal-footer"></div>
        </modal>
    </div>
</template>

<script>
    import Modal from "@/components/Modal.vue";
    import RoomAmenities from "@/views/booking-v2/component/RoomAmenities.vue";
    import { marked } from 'marked'
    export default{
        name: 'ImageCarousel',
        props: ['images','room', 'showImgCarousel', 'additional_details'],
        components: {Modal, RoomAmenities},
        data(){
            return{
                showPopup: false,
                loading: false
            }
        },
        methods: {
            closeModal(){
                this.showPopup = false;
                this.$emit('updateShowImgCarousel', false);
            },
            renderMarkdown(markedText) {
                if (markedText) {
                    return marked(markedText, { sanitize: true })
                }
                return '';
            },
        },
        watch: {
            showImgCarousel: function(newValue, oldValue) {
                this.showPopup =  newValue
            }
        }

    }
</script>

<style scoped lang="scss">
@import "@/assets/sass/config/mixins";
@import "@/assets/sass/config/variables";

:deep(.additinal_details p) {
    margin-top: 5px;
}

:deep(.el-icon-close) {
    margin-left: 100%;
}

.img-carousel .modal-comp {
    background-color: rgba(0,0,0,0.4);
}

.img{
    margin: 10px 0;
    .close-btn{
        // position: fixed;
        // z-index: 50;
        // top: 15px;
        // right: 30px;
        // background: transparent;
        border: none;
        color: #000;
        margin-right: 10px;
        font-size: 1.5rem;
        // padding: 0;
        // min-width: 14px;
    }

    .list-card-pic {
      background-color: lightgray;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100% !important;
      border-radius: 5px;

      .no-img-icon {
        font-size: 35px;
        display: flex;
        justify-content: center;
        align-content: center;
        padding-bottom: 5px;
      }
    }
}
@include media('<=desktop') {
    .list-card-pic .no-img-icon{
        font-size: 24px;
    }
}
</style>
