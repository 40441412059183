<template>
    <div>
       <div class="payments-main flexed justify-between gap-40">
          <!-- <h3>Payments</h3> -->

          <el-radio v-model="paymentSource" label="creditCard"
                      class="radio-option card-border"
                      >
                      <!-- <img src="../../../../assets/credit-card.png"  width="14.5px" height="15px"> -->
                      Credit Card
            </el-radio>

          <payment-frame 
          class="payment"
          v-if="showPaymentFrame" 
            @sendData="handleSendData"
            ref="payment-frame"
          ></payment-frame>
          <div></div>
        </div>
        <div class="navigation">
          <button @click="previousStep()" >{{ $t('lbl_previous') }}</button>
          <!-- <el-button class="next" type="primary" :loading="btnLoading"
                      @click="nextStep">
            {{ $t('lbl_next') }}</el-button> -->
        </div>
    </div>
</template>

<script>
import apiCallsActivitiesService from "@/services/activities/api-calls-activities.service";
import paymentFrame from '@/views/activities/activity-steps/Payments/payment-frame'
import { mapState } from "vuex";
export default {
  name: "Payments",
  components:{
    paymentFrame
  },
  data() {
    return {
      loading: false,
      btnLoading: false,
      paymentSource: 'creditCard'
    }
  },
  computed: {
    ...mapState({
      startActivityMap: state => state.activities.startActivityMap,
      // priceDetails: state => state.activities.priceDetails,
      // activityDetails: state => state.activities.activityDetails,
      property: state => state.activities.propertyDetails,
      paymentTypes: state => state.activities.paymentTypes
    }),
    showPaymentFrame() {
      return this.paymentTypes && this.paymentTypes.some(payment => payment.code.endsWith('CC'))
    }

  },
  methods: {
    async previousStep() {
      this.$router.push({name: 'activity-guest-information'}).catch(() => {
      })
    },
    async handleSendData(payload){
      const payment_source = payload;
      console.log(payment_source)

      this.btnLoading = true
      try{
       const response =   await apiCallsActivitiesService.post(`${this.property.id}/web/new-activity-booking/${this.startActivityMap.id}/payment-method/${this.startActivityMap.session_id}`, payment_source, null, 'eventManagementApi')
       console.log(response.data);
        this.$router.push({name: 'activity-confirmation'}).catch(()=>{})
      }catch (error) {
        const errorMessage = error?.response?.data?.message || error?.data?.message || error?.message || error?.data
        this.$notify.error({ title: "Error", message: errorMessage, });
        this.$refs["payment-frame"]?.stopLoading()
        this.btnLoading = false
      }
    },
    // async nextStep() {
    //   this.btnLoading = true
    //   try{
    //    const response =   await apiCallsActivitiesService.post(`${this.property.id}/web/new-activity-booking/${this.startActivityMap.map.id}/payment-method/${this.startActivityMap.map.session_id}`, { payment_source: null }, null, 'eventManagementApi')
    //    console.log(response.data);
    //     this.$router.push({name: 'activity-confirmation'}).catch(()=>{})
    //   }catch (error) {
    //     const errorMessage = error?.response?.data?.message || error?.data?.message || error?.message || error?.data
    //     this.$notify.error({ title: "Error", message: errorMessage, });
    //     this.$refs["payment-frame"]?.stopLoading()
    //     this.btnLoading = false
    //   }
    // },
  },
 
}
</script>

<style lang="scss" scoped>
@import "~css_vars";
@import '~mixins';
.navigation button {
  border: 1px solid $lightgrey-2;
  border-radius: 5px;
}
.payments-main{
  margin: 30px 80px 30px;
  padding-bottom: 50px;

  .payment {
    margin: 20px 0px;
    margin-left: -200px;
  }
  .radio-option{
    padding: 12px 40px 12px 10px;
    margin-top: 20px;
    width: 250px;
    height: 44px;
    img{
      margin-right: 5px;
      margin-bottom: -2px;
    }
  }
}

@include media("<=tablet") {
  .payments-main{
    display: flex;
    flex-direction: column;
    margin: 10px;

    .payment {
        margin: 20px 0px;
        // margin-left: -200px;
       width: 100%;

    }
   }

}


</style>
