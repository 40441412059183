import Vue from 'vue';
import CommonServices from "@/services/common.services";


const GET_DISCOUNT = ({commit, rootState}, params) => {
  return CommonServices.getDiscount(params)
};

const getLocalizationKeys = () => {
  let locale = getCookie('hk_language') || 'en_US'
  
    let params = {
      app_id: 'HK_BOOKING_WEB',
      locale: locale
    };
  return CommonServices.getLocalizations(params)
}

export default {
  GET_DISCOUNT,
  getLocalizationKeys
}

const getCookie = (name) => {
  return document.cookie.split('; ').reduce((r, v) => {
    const parts = v.split('=')
    return parts[0] === name ? decodeURIComponent(parts[1]) : r
  }, '')
};

