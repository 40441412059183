<template>
  <div class="confirmation-main">
    <div class="flexed justify-center" v-if="loading">
      <loader></loader>
    </div>
    <div v-else>
<!--      <h3>Confirmation</h3>-->
      <div class="confirmation-container card-border">
        <div class="flexed justify-between m-b-20">
          <div class="event-status">
            <h4>Event has been booked successfully</h4>
            <h4>{{data.confirmation_no}}</h4>
          </div>
          <el-button type="primary"
                     @click="go_home"
          >Book a New Event</el-button>
        </div>

        <div class="flexed m-t-20">
          <div class="image">
            <div class="img" v-if="selectedEvent.image">
              <img :src="selectedEvent.image" height="232px" width="320px">
            </div>
            <div class="no-img" v-else>
              <i class="el-icon-picture-outline no-img-icon"></i>
              <span>No Image Found</span>
            </div>
          </div>
          <div class="m-l-30 space-data flex_1">
            <div class="m-t-0 bold">{{data.name}}</div>
            <div class="m-t-0 bold"> {{ data.guest.first_name + " " + data.guest.last_name }}</div>
            <div class="flexed justify-between"> <span><i class="el-icon-date"></i> {{ data.from | formatDate }} </span><span><i class="el-icon-user"></i> {{ data.sub_events[0].number_of_persons }}</span></div>
            <div><i class="el-icon-location-outline"></i> {{ data.sub_events[0].space.name }}</div>
            <div class="flexed justify-between"><p>Total Charges</p><p>{{ data.total_charges | currency(currency)}}</p></div>
            <div class="flexed justify-between"><p>Total Taxes</p><p>{{ data.total_taxes | currency(currency)}}</p></div>
            <div class="flexed justify-between bold"><p>Grand Total</p><p>{{ (data.total_charges + data.total_taxes) | currency(currency) }}</p></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eventApiCall from '../../../services/events/api-calls-events.service'
import {mapMutations, mapState} from "vuex";
import loader from '@/components/common/loading.vue';
import dayjs from "dayjs";
import CurrencyData from '@/assets/currencies/currencies';

export default {
  name: 'Confirmation',
  components:{
    loader
  },
  data() {
    return {
      loading: false,
      currencyData: CurrencyData,
      data: null
    }
  },
  async beforeMount() {
    await this.getData()
  },
  methods: {
    async previousStep() {
      this.$router.push({name: 'review-booking'}).catch(()=>{})
    },
    async getData(){
      this.loading = true
      try {
        let res = await eventApiCall.post(`${this.property.id}/web/new-event/${this.eventConfig.event_id}/confirm/${this.eventConfig.session_id}`, null, null, 'eventManagementApi')
        this.data = res.data
        this.loading = false
      }catch (e){}
    },
    go_home() {
      window.location.reload()
      this.set_error(false)
    },
    ...mapMutations({
      set_error: 'SET_ERROR'
    })
  },
  computed:{
    ...mapState({
      property: state => state.events.propertyDetails,
      eventConfig: state => state.events.eventConfig,
      selectedEvent: state => state.events.selectedEvent,
      eventTime: state => state.events.eventTime,
      currency: state => state.events.propertyDetails.currency,
      guest_data: state => state.events.guest_data
    }),
    getFullTime(){
      let startTime = dayjs(this.eventTime.start_time).format("hh:mm A")
      let endTime = dayjs(this.eventTime.end_time).format("hh:mm A")

      return startTime + " - " + endTime
    },
    getDate(){
      return dayjs(this.eventTime.start_time).format("MMM DD, YYYY")
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~css_vars';
@import "~mixins";

.confirmation-main{
  margin: 30px 80px 30px;
  padding-bottom: 50px;
  .confirmation-container{
    padding: 30px;
    max-width: 700px;
    margin: 50px auto 0;
    font-size: 14px;
    .event-status{
      h4 {
        margin: 0;
        padding:0;
      }
    }
    .space-data div{
      margin: 14px 0;
      p{
        margin: 0;
      }
    }
    h4{
      margin: 7px 0;
      font-size: 16px;
    }
    .img img{
      border-radius: 5px;
    }
  }
}
</style>
