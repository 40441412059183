<template>
    <div class="select-space-main">
      <div class="select-space-container" >
        <div v-if="mobileView" class="card-border flexed gap-20 p-10 mobile-date-time-picker justify-between">
          <div>
            <span>Date</span><br> {{date | formatDate}}
          </div>
          <div>
            <span>Time</span><br> {{ startTime + '-' + endTime}}
          </div>
          <div>
            <span>People</span><br>{{capacity}}
          </div>
          <div class="edit-icon"
               @click="show_modify_date_popup = true"
          ><i class="el-icon-edit"></i>
          </div>
          <modify-date-popup
            v-if="show_modify_date_popup"
            @close="show_modify_date_popup = false"
          ></modify-date-popup>
        </div>
        <div class="flexed align-center" style="column-gap: 23px; align-content: center" v-else>

          <div class="flexed-column">
            <label class="label">Event Types</label>
            <el-select
              style="width: 220px"
              placeholder="Select Event Type"
              v-model="event_type"
              @change="initialize()"
            ><el-option
              v-for="item in event_types_options"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option></el-select>
          </div>

          <template>
            <div class="flexed-column">
              <label class="label">Date</label>
              <el-date-picker
                v-model="date"
                type="date"
                placeholder="Date"
                :picker-options="datePickerOptions"
                value-format="yyyy-MM-dd"
                format="MMM dd, yyyy"
                @change="changeDateAndTime()"
              ></el-date-picker>
            </div>
          </template>

            <div class="flexed-column">
              <label class="label">Start Time</label>
              <el-time-select
                placeholder="Start time"
                v-model="startTime"
                :picker-options="{
                  start: '00:00',
                  step: '1:00',
                  end: '23:30'
                }"
                @change="changeDateAndTime()">
              </el-time-select>
            </div>

            <div class="flexed-column">
              <label class="label">End Time</label>
              <el-time-select
                placeholder="End time"
                v-model="endTime"
                :picker-options="{
                  start: '00:00',
                  step: '1:00',
                  end: '23:30',
                  minTime: startTime
                }"
                @change="changeDateAndTime()">
              </el-time-select>
            </div>

          <div class="flexed-column">
            <label class="label">Capacity</label>
            <el-input-number v-model="capacity"
                             :min="1" :max="999"
                             @keyup.native="onKeyUp"
                             @blur="capacityValidation()"
                             @change="onChange()"
            ></el-input-number>
          </div>
        </div>

        <EventList :spaces="availableEvents"
                   @bookEvent="bookEvent"
        ></EventList>
      </div>
    </div>
</template>

<script>
import apiCallsEventsService from "../../../../services/events/api-calls-events.service";
import {mapMutations, mapState} from "vuex";
import EventList from "./EventList";
import dayjs from "dayjs";
import {formatDate} from "../../../../services/utilities.service";
import ModifyDatePopup from "./modify-date-popup";

  export default {
    name: 'SelectSpace',
    components: {ModifyDatePopup, EventList},
    data() {
      return {
        availableEvents: null,
        min_date: '',
        date : null,
        startTime: "08:00",
        endTime: "09:00",
        capacity: 1,
        show_modify_date_popup: false,
        event_type: null,
        event_types_options: []
      }
    },
    mounted() {
      if(this.eventDetails) {
         this.date = formatDate(this.eventDetails.date, 'YYYY-MM-DD')
         this.startTime = this.eventDetails.startTime
         this.endTime = this.eventDetails.endTime
         this.capacity = this.eventDetails.capacity
      }
    },
    async beforeMount() {
      await this.getEventTypes();
      await this.initialize();
    },
    computed:{
      ...mapState({
        property: state => state.events.propertyDetails,
        eventConfig: state => state.events.eventConfig,
        eventTime: state => state.events.eventTime,
        mobileView: state => state.mobileView,
        eventDetails: state => state.events.eventDetails
      }),
      datePickerOptions() {
        let cbd = dayjs(formatDate(this.eventConfig.min_date, 'YYYY-MM-DD'))
        //cbd = cbd.subtract(2 * dayjs(cbd).get('hour'), 'hour')
        console.log({cbd})
        let lastAvailableDate = cbd.add(1, 'year')
        return {
          disabledDate(date) {
            return (date < cbd || date > lastAvailableDate);
          }
        }
      }
    },
    methods: {
      ...mapMutations({
        SET_EVENT_CONFIG: 'events/SET_EVENT_CONFIG',
        SET_SELECTED_EVENT: 'events/SET_SELECTED_EVENT',
        SET_EVENT_TIME: 'events/SET_EVENT_TIME',
        SET_EVENT_DETAILS: 'events/SET_EVENT_DETAILS'
      }),
      async getEventTypes(){
        try {
          const res = await apiCallsEventsService.getEvents(this.property.id);
          this.event_types_options = res.data;
          if (res?.data?.length > 0){
            this.event_type = res.data[0]?.id;
          }
        } catch (error) {
          console.log(error)
        }
      },
      async initialize(){
        await this.getEventConfig().then(() => {
          if(!this.eventDetails) {
            this.date = formatDate(this.eventConfig.min_date, 'YYYY-MM-DD')
          }
          this.getAvailableEvents();
        })
      },
      changeDateAndTime(){
        if(this.endTime <= this.startTime)
          this.endTime = (parseInt(this.startTime.substring(0,2))+1) + ":00"

        this.getAvailableEvents()
      },
      onKeyUp(val){
        val.target.blur()
        val.target.focus()
        this.changeEventType()
      },
      onChange(){
        this.changeEventType()
      },
      changeEventType(){
        let payload = JSON.parse(JSON.stringify(this.eventTime))
        payload.capacity = this.capacity
        this.SET_EVENT_TIME(payload)
      },
      async getEventConfig(){

        let eventPayload = {
          'event_type_id': this.event_type
        }
        let result = await apiCallsEventsService.post(`${this.property.id}/web/new-event`, eventPayload, {}, 'eventManagementApi')

        this.SET_EVENT_CONFIG(result.data)
      },
      async getAvailableEvents(){
        let startTime = this.date + "T" + this.startTime + ":00Z"
        let endTime = this.date + "T" + this.endTime + ":00Z"

        let timePayload = {
          start_time: startTime,
          end_time: endTime,
          capacity: this.capacity
        }
        this.SET_EVENT_TIME(timePayload)

        this.availableEvents = null
        let payload = {
          "name": "WebBooking",
          "from": startTime,
          "to": endTime
        }
        try{
          let result = await apiCallsEventsService.post(`${this.property.id}/web/new-event/${this.eventConfig.event_id}/basic-data/${this.eventConfig.session_id}`, payload, null, 'eventManagementApi')

          this.availableEvents = result?.data.client_visible_data.availability
        }catch(e){}
      },
      async bookEvent(space){
          let eventDetailsObj = {
            date: formatDate(this.date, 'YYYY-MM-DD'),
            startTime: this.startTime,
            endTime: this.endTime,
            capacity: this.capacity,
          }
          this.SET_EVENT_DETAILS(eventDetailsObj)
          this.SET_SELECTED_EVENT(space)
          await this.nextStep()
      },
      async nextStep() {
          this.$router.push({name: 'select-package'}).catch(()=>{})
      },
      capacityValidation(){
        if(!this.capacity){
          this.capacity = 1
          this.changeEventType()
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
@import "@/assets/sass/config/mixins.scss";
@import "~css_vars";
 .select-space-container{
   margin: 30px 450px 60px 80px;
   .label{
     font-size: 14px;
     font-weight: bold;
     color: #5a5a5a;
     margin-bottom: 7px;
   }
   .mobile-date-time-picker{
     font-size: 14px;
     span{
       color: $hk-grey;
       font-weight: bold;
     }
   }
 }
@include media("<=tablet"){
  .select-space-container {
    margin: 30px 25px;
    .edit-icon{
      align-self: center;
      border: 1px solid black;
      border-radius: 50%;
      width: 25px;
      i{
        margin-left: 4px;
      }
    }
  }
}
</style>
