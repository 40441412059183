<template>
  <div>
    <header id="default-header">
      <div class="home_logo" :class="{ 'has-img': business_logo }">
        <img :src="business_logo" :alt="`${hotel_name} Logo`"/>
        <div class="flexed-column justify-center hotel-name-address" >
          <div>{{ hotel_name }}</div>
          <address v-if="!mobileView" >{{ getAddress }}</address>
        </div>
      </div>
      <div v-if="mobileView" class="flexed centered-row">
        <div class="m-l-10 m-r-10">
          <menu-icon class="menu-icon" width="29" height="29"
                     @click="showMobileMenu ? showMobileMenu = false : showMobileMenu = true">>
          </menu-icon>
          <el-drawer
            title=""
            :visible="showMobileMenu"
            :before-close="closeMenu"
            direction="ttb"
            size="100%"
            :show-close="false">
            <div class="header">
              <div class="logo">
                <img :src="business_logo" :alt="`${hotel_name} Logo`"/>
                <div class="flexed-column justify-center hotel-name-address" >
                  <div class="m-l-10">{{ hotel_name }}</div>
                </div>
              </div>
              <div class="xIcon">
                <x-icon @click="closeMenu"></x-icon>
              </div>
            </div>
            <nav class="mobile-menu flexed-column m-t-20">
              <div class="subtitle">More Options</div>
              <!-- <div class="menus-item" v-if="URLQueryParams.url">
                <img src="@/assets/home.svg" >
                <div aria-label="Go to Home Tab" @click="go_home()">{{ $t("lbl_home") }}</div>
              </div> -->
              <div class="menus-item">
                <img  src="@/assets/hotel_details.svg" >
                <a aria-label="Hotel Details" href="#" @click="openHotelDetails($event)">Hotel Details</a>
              </div>
            </nav>
          </el-drawer>
        </div>
      </div>
      <div class="menu" v-else>
        <nav class="other-info">
          <!-- <div class="flexed" v-if="$route.name!='select-activity'">
            <img src="@/assets/home.svg" alt="" >
            <a aria-label="Go to Home Tab" href="#" @click="go_home()">{{ $t("lbl_home") }}</a>
          </div> -->
          <div class="flexed">
            <img src="@/assets/hotel_details.svg" alt="" >
            <a aria-label="Hotel Details" href="#" @click="openHotelDetails($event)">Hotel Details</a>
          </div>
          <!-- <div class="flexed align-center ">
            <div class="currency-symbol"> <div>{{getCurrentSymbol}}</div></div>
            <el-dropdown trigger="click" @command="setDefaultCurrency">
              <div class="el-dropdown-link pointer">
                <span>{{selectedCurrency}}<i class="el-icon-arrow-down el-icon--right"></i></span>
              </div>
              <el-dropdown-menu slot="dropdown">
                <div class="w-100 p-l-10 p-r-10 p-b-10 border-bottom" >
                  <el-input v-model="searchCurrency" size="medium" placeholder="Select Currency"></el-input>
                </div>
                <div class="el-dropdown-menu-items">
                  <el-dropdown-item v-for="(currency, index) in filteredCurrencyData" :key="index"
                                    :command="currency.code">{{currency.name + ' - ' + currency.code}}</el-dropdown-item>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
          </div> -->
        </nav>
      </div>
    </header>
    <hotel-details-popup v-if="showHotelDetails && !mobileView" @close="showHotelDetails = false"/>
    <hotel-details-drawer v-if="showHotelDetails && mobileView" @close="showHotelDetails = false"/>
  </div>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import CurrencyData from '@/assets/currencies/currencies';
import HotelDetailsDrawer from "./HotelDetailsDrawer.vue";
import HotelDetailsPopup from "./HotelDetailsPopup.vue";
import { LogOutIcon, SettingsIcon, ChevronDownIcon, ChevronUpIcon, MenuIcon, XIcon, SearchIcon, DollarSignIcon} from 'vue-feather-icons'

export default {
  components: {
    HotelDetailsPopup,
    HotelDetailsDrawer,
    MenuIcon,
    XIcon,
  },
  beforeMount() {
    // if (this.$route.query.url) {
    //   let base_url = decodeURIComponent(this.$route.query.url).split("#")[0];
    //   this.website_url = `${base_url}#`;
    // } else if (this.$route.query.tk) {
    //   let decrypted = atob(this.$route.query.tk);
    //   let userInfo = JSON.parse(decrypted);
    //   let base_url = userInfo.url.split("#")[0];
    //   this.website_url = `${base_url}#`;
    // }
  },
  mounted() {
    this.selectedCurrency = this.currency

    let element = document.getElementById("default-header");
    element.style.setProperty(
      "--hover-color",
      `${this.web_config.booking_color_id}`
    );
    this.URLQueryParams = this.$route.query

  },
  data() {
    return {
      mobile_nav: false,
      website_url: null,
      main_menus: [],
      submenus: [],
      active_menu: null,
      currencyOptions: [{id: 1, name: "Us Dollars"}],
      languageOptions: [{id: "en_us", name: "EN-US"}],
      homeModel: {currencyId: 1, languageId: "en_us"},
      showHotelDetails: false,
      showDropdown: false,
      URLQueryParams: '',
      showMobileMenu: false,
      currencyData: CurrencyData,
      selectedCurrency: 'USD',
      searchCurrency: ''
    };
  },
  computed: {
    ...mapState({
      hotel_id: (state) => state.activities.propertyDetails.id,
      hotel_name: (state) => state.activities.propertyDetails.name,
      business_logo: (state) => state.activities.propertyDetails.business_logo,
      phone_no: (state) => state.activities.propertyDetails.phone_no,
      email: (state) => state.activities.propertyDetails.email,
      address: (state) => state.activities.propertyDetails.address,
      city: (state) => state.activities.propertyDetails.city,
      state: (state) => state.activities.propertyDetails.state,
      country: (state) => state.activities.propertyDetails.country,
      zip_code: (state) => state.activities.propertyDetails.zip_code,
      currency: (state) => state.activities.propertyDetails.currency,
      web_config: state => state.activities.propertyDetails.website_config,
      mobileView: state => state.mobileView,
    }),
    filteredCurrencyData(){
      let result = this.currencyData
      if(this.searchCurrency)
        result = result.filter(r=>r.code.toUpperCase().includes(this.searchCurrency.toUpperCase()) || r.name.toUpperCase().includes(this.searchCurrency.toUpperCase()))
      return result
    },
    getCurrentSymbol(){
      return this.currencyData.find(c=>c.code === this.selectedCurrency)?.symbol
    },
    getAddress() {
      return this.address + ', ' + this.city + ', '  + this.state + ', '  + this.country + ', '  + this.zip_code
    },
  },
  methods: {
    setDefaultCurrency(code){
      this.SET_CURRENCY(code)
      this.selectedCurrency = code
    },
    closeMenu() {
      this.showMobileMenu = false
    },
    openHotelDetails(event) {
      event.preventDefault()
      this.showHotelDetails = true
    },

    ...mapMutations({
      SET_CURRENCY: "activities/SET_CURRENCY"
    }),

    go_home() {
      window.location.replace(this.URLQueryParams.url);
      this.SET_ERROR(false);
    },

  },
};
</script>

<style lang="scss" scoped>
@import "~mixins";
@import "~css_vars";

.menus-item {
  padding: 6px 0 6px 20px;
  z-index: 1001;
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  img{
    height: 25px;
  }
}

.xIcon {
  position: absolute;
  top: 19px;
  right: 19px;
}

.subtitle {
  padding: 20px 30px;
  font-weight: bold;
}

.logo {
  position: absolute;
  top: 0px;
  left: 15px;
  display: flex;
  flex-direction: row;
  font-weight: bold;
  font-size: 16px;
}



header {
  --hover-color: #fff;
}

header {
  background-color: white;
  border-bottom: #eaeaea solid 1px;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1px;
  color: black;
  font-size: 14px !important;
  padding: 0px 15px;

  .home_logo {
    display: flex;
    align-items: center;
    height: 100%;

    img {
      background-size: cover;
      height: 70%;
      max-width: 60px;
      cursor: default;
      margin: 0;
      margin-right: 10px;

      img {
        opacity: 0;
      }
    }

    .hotel-name-address {
      margin-left: 5px;

      div {
        font-size: 16px;
        font-weight: bold;
      }

      address {
        font-style: normal;
      }
    }
  }

  .el-select {
    width: 110px;
  }

  .menu {
    flex: 1;
    display: flex;
    align-self: stretch;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-end;

    .other-info {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-right: 15px;
      font-style: normal;
      gap: 20px;

      a, span {
        color: #000;
        font-weight: 600;
      }

      a {
        display: flex;
        align-items: center;
        text-decoration: none;
      }
      img {
        height: 25px;
      }
    }

    .mobile-menu {
      display: none;

      svg {
        stroke: white;
      }

      nav {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-right: 60px;

        a {
          text-transform: uppercase;
          padding: 10px 4px;
          margin-right: 1rem;
          color: black;
          font-weight: 600;
          cursor: pointer;
          text-decoration: none;

          &:last-child {
            margin-right: 0px;
          }

          &:hover {
            color: var(--hover-color);
          }
        }
      }
    }
  }
}

@include media("<=tablet") {
  header {
    height: 60px;

    img {
      width: 48px;
    }

    .home_logo {
      h1 {
      }
    }

    .menu {
      .other-info {
        display: none;
      }

      .mobile-menu {
        display: block;
        position: absolute;
        top: 22px;
        right: 80px;
        z-index: 100;
      }

      nav#menuNav {
        display: none !important;
        margin-top: 0;
        height: 100vh;
      }

      nav#menuNav.mobile-active {
        display: flex !important;
        overflow-y: auto;
        flex-wrap: nowrap;

        div,
        a {
          &:hover {
            color: black;
          }
        }

        #close-mobile-menu {
          display: block;
          position: absolute;
          top: 10px;
          left: 10px;
          cursor: pointer;
        }
      }

      nav {
        position: fixed;
        box-sizing: border-box;
        flex-direction: column;
        text-align: center;
        height: 100%;
        width: 100%;
        padding: 3rem;
        background-color: white;
        z-index: 99999;
        grid-column: full;
        top: 0;
        left: 0;
        bottom: 0;

        a {
          color: #888888;
          border-bottom: #c1c1c1;
          margin: 0;
        }
      }
    }
  }
}

.name {
  position: relative;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 10px;

  .user-name {
    color: #000;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .user-icon {
      margin-right: 5px;
    }

    .caret-icon {
      margin-left: 5px;
    }
  }

  .dropdown {
    position: absolute;
    border: 1px solid #eaeaea;
    border-radius: 0 0 4px 4px;
    top: 40px;
    color: black;
    right: 0;
    background-color: #fff;
    z-index: 99;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 160px;

    div {
      padding: 10px;
      width: 100%;
      display: flex;
      align-items: center;

      .custom-class {
        padding: 0 5px;
      }

      &:hover {
        background: $hk-primary-color;
        color: white;
      }
    }
  }
}

.el-dropdown-menu-items {
  overflow: scroll; //
  max-height: 400px; //
  overflow-x: hidden;
}
.el-dropdown-menu {
  overflow: hidden;
}
.currency-symbol{
  font-size:20px;
  font-weight:240;
  margin-right: 5px;
  vertical-align: middle;
  margin-bottom: 1px;
}
</style>
