<template>
   
    <div class="summary-container m-b-10 m-t-20 p-20 card-border">
        <div class="flexed justify-center align-center" v-if="loading">
            <loading></loading>
        </div>
      <div v-else>
        <h4 class="m-t-0">Activity Details</h4>
        <div class="flexed justify-between remove-margin"><p>Date</p><p>{{ activityDetails.selectedDate | formatDate}}</p></div>
        <div class="flexed justify-between remove-margin"><p>Capacity Left</p><p>{{ priceDetails.capacity_left > 0 ? priceDetails.capacity_left : 0  }}</p></div>
        <div class="flexed justify-between remove-margin"><p>Time</p><p><span>{{ activityDetails.from_time | formatTime }}</span> - <span>{{ activityDetails.to_time | formatTime}}</span>  </p></div>
        <h4 class="m-t-20 m-b-0">Select Number Of Guests</h4>
        <div class="flexed justify-between align-center">
        
          <p>Number of Adults</p>
          <el-input-number v-model="nrAdults"
                               :min="1"
                               :max="maxAdultsCapacity"
                               @keyup.native="onKeyUp"
                               @change="onChange()"
              ></el-input-number>
        </div>
        <div class="flexed justify-between align-center">
          <p>Number of Children</p>
          <el-input-number v-model="nrChildren"
                               :min="0" 
                               :max="maxChildrenCapacity"
                               @keyup.native="onKeyUp"
                               @change="onChange()"
              ></el-input-number>
        </div>
        

        <div class="rate-details p-t-20">
          <h4 class="m-b-0" >Rate Details</h4>
          <div class="charges-data">
            <div class="flexed justify-between base-price"><p>Base Price</p>
              <p>{{ priceDetails.base_price | currency(currency) }}</p>
            </div>
            <div class="flexed m-b-5">
              <span class="covers">Covers {{ priceDetails.base_price_adult_count }} adult and {{ priceDetails.base_price_children_count }} children</span>
            </div>
            <div class="flexed m-b-5">
              <span class="covers">Additional Adult Price {{ priceDetails.additional_price_per_adult | currency(currency) }}</span>
            </div>
            <div class="flexed m-b-5">
              <span class="covers">Additional Child Price {{ priceDetails.additional_price_per_child | currency(currency) }}</span>
            </div>
            <div class="flexed justify-between bold"><p>Total Amount</p><p>{{ total | currency(currency)}}</p></div>
            <div class="flexed justify-between"><p>Total Taxes</p><p>{{priceDetails.total_taxes | currency(currency)}}</p></div>
            <div class="flexed justify-between bold grand-total"><p>Grand Total</p><p>{{ priceDetails.grand_total | currency(currency)}}</p></div>
          </div>
          <!-- <el-button type="primary" class="p-20 btn-proceed-booking">Proceed with Booking</el-button> -->
        </div>
      </div>
    </div>
</template>


<script>
  import loading from '@/components/common/loading.vue';
  import { mapMutations, mapState } from 'vuex';
  import apiCall from '@/services/activities/api-calls-activities.service'
  import CurrencyData from '@/assets/currencies/currencies';
export default {
    name: 'SummaryDetails',
    components: {loading},
    data() {
      return {
        loading: false,
        currencyData: CurrencyData,
        nrAdults: 1,
        nrChildren:0
      }
    },
    computed: {
      ...mapState({
        currency: state => state.activities.propertyDetails.currency,
        activityDetails: state => state.activities.activityDetails,
        priceDetails: state => state.activities?.priceDetails,
        property: state => state.activities.propertyDetails,
      }),
      maxAdultsCapacity() {
        return this.priceDetails?.capacity_left + this.nrAdults
      },
      maxChildrenCapacity() {
        return this.priceDetails?.capacity_left + this.nrChildren
      },
      disableIncrease() {
        return  this.priceDetails?.capacity_left === 0
      },
      total() {
        return this.priceDetails.total_charge_adult + this.priceDetails.total_charge_children + this.priceDetails.base_price
      },
      // totalAdditionalAdultsPrice() {
      //   return this.priceDetails.base_price_adult_count * this.priceDetails.additional_price_per_adult
      // },
      // totalAdditionalChildrenPrice() {
      //   return this.priceDetails.base_price_children_count * this.priceDetails.additional_price_per_child
      // }
    },
    methods: {
      ...mapMutations({
        SET_PRICE_DETAILS: 'activities/SET_PRICE_DETAILS'
      }),
      onChange(){
        const payload = {
          adults: this.nrAdults,
          children: this.nrChildren
        }
        this.getPriceDetails(payload)
      },
      async getPriceDetails(payload) {
        this.loading = true;
        try{
          let response = await apiCall.get(`${this.property.id}/web/activity-instances/${this.activityDetails.id}/calculate-price`,
           payload, 'eventManagementApi')
      
          
          console.log(response.data);
          this.SET_PRICE_DETAILS(response.data)

        }
        catch(error){
          console.log(error)
        }
        this.loading = false;
      },
    },
    beforeMount(){
      const payload = {
          adults: this.nrAdults,
          children: this.nrChildren
        }
      this.getPriceDetails(payload);
      console.log(this.priceDetails)
    }
}
</script>


<style lang="scss" scoped>
@import "~css_vars";
@import '~mixins';

.grid-container {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 10px;

  .grid-item {
    background-color: #ccc;
  }

  .grid-item-1 {
    grid-row: 1 / 3;
  }
}

.text-container {
  .points {
    margin-left: 24px;
    list-style: disc;
  }
}

.remove-margin p{
  margin: 4px 0;
}

.btn-proceed-booking {
  width: 100%;
  font-weight: bold;
}

.charges-data p {
  margin: 10px 0;
}

.summary-container{
  width: 35%;
}

.img-size {
  max-width: 100%;
}
  .large-image {
    grid-row: 1 / 3;
    width: 100%;
  }

  .covers {
    font-size: 14px;
    color: #adb5bd;
  }

  .aditional {
    margin-top: 10px;
  }

  .aditional p{
    margin: 0;
  }

  .select-time {
    margin: 0;
  }

  .base-price p{
    margin-top: 10px;
    margin-bottom: 0px;
  }

  .additinal-calculations {
    font-size: 14px;
    color: #adb5bd;
  }

  @include media("<=tablet") {
    .summary-container{
      width: 100%;
    }

    .el-input-number {
      width: 130px;
    }

    .grand-total {
      padding-bottom: 20px;
    }
  }

</style>