<template>
  <modal cross-btn="true"
         has-header-border="true"
         size-close-icon="sm"
         @close="close(null)">
    <div slot="modal-title" style="font-size: 16px; padding: 8px">
      <span>Select Room Unit</span>
    </div>
    <div slot="content" class="popup-content">
      <div class="flexed justify-center align-center" v-if="loading">
        <loading></loading>
      </div>
      <div class="container" v-else-if="sortedRoomUnits.length > 0">
        <div class="room_boxes_list">
          <div class="room_box bordered border-radius-5"
               v-for="room in sortedRoomUnits"
               :key="room.room_id"
               @click="close({room_id: room.room_id, room_unit_number: room.number})"
          >
            <span>{{ room.number.length < 4 ? room.number : room.number.substr(0,3) + ".."}}</span>
          </div>
        </div>
      </div>
      <div v-else class="flexed centered">
        No rooms found
      </div>
    </div>
    <div slot="modal-footer" class="modal-footer">
      <el-button type="primary" :loading="loading" @click="close({room_id: null, room_unit_number: null})">
        <span>Skip</span>
      </el-button>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
import ApiCalls from '@/services/api-calls.service.js'
import {mapState} from "vuex";
import Loading from "@/components/common/loading.vue";
import {formatDate} from "@/services/utilities.service";


export default {
  name: "BookRoomUnit",
  components: {Loading, Modal},
  props:{
    selectedRoomPayload: Object
  },
  data(){
    return{
      loading: false,
      room_type_data: {}
    }
  },
  computed:{
    ...mapState({
      property: state => state.property.details,
      search_data: state => state.search_data
    }),
    sortedRoomUnits(){
      if(!this.room_type_data?.rooms?.length > 0)
        return []
      return this.room_type_data?.rooms?.sort((a, b) => a.number-b.number)
    }
  },
  methods:{
    async getData(){
      let checkInDate = this.search_data.rooms[this.selectedRoomPayload.activeTab].checkIn.toString()
      let checkOutDate = this.search_data.rooms[this.selectedRoomPayload.activeTab].checkOut.toString()
      let url = `${this.property.id}/suggested_rooms/v2`
      let payload = {
        room_type_ids: [this.selectedRoomPayload.room.room_type_id], //id of selected room type
        start_date: formatDate(checkInDate, "YYYY-MM-DD"),
        end_date: formatDate(checkOutDate, "YYYY-MM-DD")
      }

      this.loading = true
      try{
        let res = await ApiCalls.post(url, payload, {}, 'propertyUrl')
        this.room_type_data = res.data[0] // api returns an array, but it will contain only one object
        this.loading = false
      }catch (e){
        console.log(e)
      }
    },
    close(payload){
      this.$emit("close", payload)
    },
  },
  async beforeMount() {
    await this.getData()
  }
}
</script>

<style scoped lang="scss">
.popup-content{
  min-height: 200px;
  padding: 30px 0 20px 5px;
  .room_boxes_list{
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    .room_box{
      width: 70px;
      height: 70px;
      text-align: center;
      padding: 22px 0;
      color: #4E4E4E;
      background-color: whitesmoke;
    }
    .room_box:hover{
      cursor: pointer;
      box-shadow: rgba(0, 0, 0, 0.10) 0 1px 4px;
      background-color: #f6f6f6
    }
  }
}
.modal-footer{
  padding: 0 5px 15px 0;

}
</style>
