<template>
     <div class="content hotel-modal-info">
    <div class="hotel-container" v-if="mainscreen === true">
      <div class="left">
          <!-- <div class="image" v-if="carousel_images && carousel_images.length > 0"> -->
          <div class="image" v-if="carousel_images">
            <el-carousel arrow="always" trigger="click" :autoplay="false">
                <!-- <el-carousel-item v-for="(item,index) in carousel_images" :key="index">
                <img :src="item.url" alt=""/>
              </el-carousel-item> -->
              <el-carousel-item>
                <img :src="carousel_images" alt=""/>
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="hotel-title">
            <img :src="propertyDetails.business_logo" height="40px" alt="logo" class="logo">
            <div class="name">{{ propertyDetails.name }}</div>
          </div>
          <div class="property-address">
            <div>
              <i class="el-icon-location"></i> {{ propertyDetails.address }}, {{ propertyDetails.city }},
              {{ propertyDetails.state }},
              {{ propertyDetails.country }}, {{ propertyDetails.zip_code }}
            </div>
            <div class="property-contact">
              <div style="padding-right: 10px"><i class="el-icon-message"></i> {{ propertyDetails.email }}</div>
              <div style="padding-right: 10px"><i class="el-icon-phone phone-icon"></i>{{ propertyDetails.phone_no }}</div>
            </div>
          </div>
          <div class="description">
            {{
              propertyDetails.additional_details
            }}
            <!-- {{
              hotelDetails.additional_details.length > 250 ? hotelDetails.additional_details.substring(0, 250) + ' ...' : hotelDetails.additional_details
            }} -->
          </div>
          <!-- <div class="amenities">
            <h3 class="title">Amenities</h3>
            <div class="items">
              <div class="item" v-for="(item,key) in amenities" :key="key">
                <i class="el-icon-picture-outline" v-if="item.icon === null" style="font-size:25px"></i>
                <img v-else :src="item.icon" height="25px" :alt="item.name">
                <div class="name">
                  {{ item.name }}
                </div>
              </div>
            </div>

          </div> -->
        </div>
        <div class="right">
          <h3>Services and Policies</h3> 
          <el-collapse class="hotel-details" v-model="activeName" accordion>
            <el-collapse-item :key="item.id" v-for="item in newDetails" :name=item.code>
              <template slot="title">
                <svg class="title-icon">
                  <use xlink:href="@/assets/policy_icon.svg#policyIcon"></use>
                </svg>
                <!-- <img src="@/assets/policy_icon.svg"  height="25px" class="title-icon"> -->
                <!-- <img v-else :src="item.icon" alt="Housekeeping Service Icon" height="22px"
                     class="title-icon"> -->
                {{ item.title }}
              </template>
              <div>
                {{ item.content }}
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <!-- <el-button class="btn-places" type="primary" > View Places Nearby</el-button> -->

      </div>
      <!-- <PlacesNearby
        :hotelDetails="hotelDetails"
        v-if="placesnearby === true"
        @back="back()">
      </PlacesNearby> -->
    </div>
</template>

<script>
import { mapState } from 'vuex';
// import PlacesNearby from '../hotel-results/places_nearby'
export default {
//   props: ['hotelDetails', 'property_content'],
  data() {
    return {
      activeName: null,
      carousel_images: '',
      newDetails: '',
      placesnearby: false,
      mainscreen: true,
    }
  },
  beforeMount() {
    // if (this.hotelDetails.images && this.hotelDetails.cover_photo) {
    //   this.carousel_images = JSON.parse(JSON.stringify(this.propertyDetails.images))
    //   this.carousel_images.unshift({url: this.propertyDetails.cover_photo})
    // }
    this.carousel_images = this.propertyDetails.cover_photo
    this.newDetails = []
    this.newDetails.push({code:'pet_policy', title:'Pet', content:this.propertyDetails.pet_policy})
    this.newDetails.push({code:'guarantee_policy', title:'Guarantee', content:this.propertyDetails.guarantee_policy})
    this.newDetails.push({code:'refund_policy', title:'Refund', content:this.propertyDetails.refund_policy})
    this.newDetails.push({code:'cancellation_policy', title:'Cancellation', content:this.propertyDetails.cancellation_policy})
  },
  computed: {
     ...mapState({
      mobileView: state => state.mobileView,
      propertyDetails: state => state.property.details
    }),
    amenities() {
      return []//this.hotelDetails.amenities.filter(amenity => amenity.active)
    }
  },
  methods: {
    nextstep() {
      this.mainscreen = false
      this.placesnearby = true
    },

    back() {
      this.mainscreen = true
      this.placesnearby = false
    }
  },
}
</script>


<style scoped lang="scss">
    @import '~css_vars';
    @import '~mixins';

.hotel-modal-info .property-address , .hotel-modal-info .description{
  text-align: left !important;
}

.btn-places {
  position: absolute;
  right: 22px;
  bottom: 0;
}
::v-deep .el-carousel__container{
      max-height: 230px;
  }

.content {
  // position: relative;
  // height: 75vh;
  overflow: auto;

  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 1.1em;
    z-index: 99;
  }
  
  .hotel-container {
    display: flex;
    // height: 72vh;
    flex-wrap: wrap;
    gap: 10px;
    .left {
      // width: 50%;
      flex:1;

      .image {
        img {
          max-width: 100%;
          width: 100%;
          max-height: 330px;
        }
      }

      .hotel-title {
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        margin-top: 10px;
        // padding: 10px 20px;

        .logo {
          margin: 0px;
          margin-right: 10px;
        }
      }

      .title-logo {
        display: flex;
        align-items: center;
      }

      .property-address {
        padding-top: 10px;
        color: #444;
        font-size: 13px;
        height: 50px;

        .property-contact {
          margin-top: 5px;
          display: flex;
        }
      }

      .description {
        padding: 0 20px;
        color: #444;
        font-size: 13px;
        height: 50px;
        overflow-y: auto;
      }

      .amenities {
        .title {
          margin: 0;
          font-weight: 500 !important;
        }

        .items {
          display: flex;
          max-width: inherit;
          overflow-x: scroll;
          font-size: .5em;
          padding: 0 20px;
          margin-top: 5px;

          .item {
            margin-right: 10px;
            min-width: 80px;
            text-align: center;

            img {
              width: 26px;
            }

            .name {
              word-break: break-word;
              hyphens: auto;
              margin-top: 5px;
              font-size: 13px;
            }
          }
        }
      }
    }

    .right {
      // width: 50%;

      flex:1;
      padding: 0 20px;
      // height: calc(100% - 10px);
      height: 100%;
      overflow: auto;
      h3{
        margin-top: 0;
        padding-top: 0;
        font-size: 16px;
        font-weight: 500;
      }

      svg.title-icon{
        height: 21px;
        width:21px;
        margin-right: 10px;
        fill: $hk-primary-color !important;
      }
    }
  }
}
@include media('<=tablet') {
  .content{
    height: auto !important;
    .right, .left {
      height: auto;
      flex: none !important;
      width: 100%;
      padding: 0 !important;
  }
  .right{
      margin-top: 10px;
  }
  .description{
      height: auto !important;
  }
  ::v-deep .el-carousel__container{
      max-height: 190px;
  }
  }
  
}

</style>
