<template>
  <div class="flexed justify-center align-center" v-if="loading">
    <loading></loading>
  </div>
  <div v-else class="activity-details-main">
    <div class="activity-details-container">
      <h3>{{ activityDetails.name }}</h3>
      <div  class="grid-container" v-if="!mobileView">
        <img v-for="(image, index) in activityDetails.images" :key="index"
         :src="image.image_url" class="grid-item img-size" :class="{ 'large-image': index === 0 }" />
      </div>
      <div v-if="mobileView">
        <el-carousel height="180px">
          <el-carousel-item v-for="(image, index) in activityDetails.images" :key="index">
            <img :src="image.image_url" class="img-size" alt="">
          </el-carousel-item>
        </el-carousel>
      </div>
    
      <div class="text-container">
        <h4 class="overview">Overview</h4>
        <!-- <p>Primary Description {{ activityDetails.primary_description }}</p>
        <p>Description from template{{ activityDetails.description }}</p> -->

        <div v-if="activityDetails.primary_description == null || activityDetails.primary_description == '' " v-html="convertHtmlToText(activityDetails.description)" style="white-space: pre-line;"></div>
        <div v-else
         v-html="convertHtmlToText(activityDetails.primary_description)" style="white-space: pre-line;">
        </div>
      </div>

    </div>

    <Summary></Summary>

    <div class="navigation">
      <button @click="previousStep()" >{{ $t('lbl_previous') }}</button>
      <el-button class="next" type="primary" :loading="loading"
                  @click="nextStep()">
        {{ $t('lbl_next') }}</el-button>
    </div>
  </div>
</template>

<script>
  import loading from '@/components/common/loading.vue';
  import { mapMutations, mapState } from 'vuex';
 import Summary from '../../components/Summary.vue';
 import apiCall from '@/services/activities/api-calls-activities.service'
 import htmlToFormattedText from "html-to-formatted-text";
 const  {convert} = require('html-to-text');

  export default {
    name: 'ActivityDetails',
    components: {
      loading,
      Summary
    },
    data() {
      return {
        loading: false,
      }
    },
    computed: {
      ...mapState({
        property: state => state.activities.propertyDetails,
        activityDetails: state => state.activities.activityDetails,
        priceDetails: state => state.activities?.priceDetails,
        mobileView: state => state.mobileView
      })
    },
    methods: {
      ...mapMutations({
        SET_START_ACTIVITY_MAP: 'activities/SET_START_ACTIVITY_MAP'
      }),
      convertHtmlToText(html) {

        const options = {
          // wordwrap: 13, // Specify the line width for word wrapping
          baseElements: { selectors: ['body'] },
          itemPrefix: ''
        }

        const text = convert(html, options);
        // Extract the list items
          const listItems = text.split('\n');

          // Format the list items with bullet points and newlines
          const formattedText = listItems.map(item => `${item}`).join('\n');
          console.log(formattedText)
        return formattedText;
      },
      async startActivity(){
        try{
          this.loading = true;

          let params = {
           adults: this.priceDetails.adults,
           children: this.priceDetails.children
          }

          let payload = {
            activity_instance_id: this.activityDetails.id
          }

          const response = await apiCall.post(`${this.property.id}/web/new-activity-booking/`,payload, params ,'eventManagementApi')
          console.log(response.data)
          this.SET_START_ACTIVITY_MAP(response.data);

          this.loading = false;
        }
        catch(error) {
          console.log(error)
        }


      },
      async previousStep() {
        this.$router.push({name: 'select-activity'}).catch(() => {
        })
      },
      async nextStep() {
        this.startActivity();
        this.$router.push({name: 'activity-guest-information'}).catch(() => {})
      }
    },
  }
</script>

<style lang="scss" scoped>
@import "~css_vars";
@import '~mixins';

.activity-details-main{
  margin: 30px 80px 30px;
  display: flex;
  gap: 50px;
  padding-bottom: 50px;
  .activity-details-container{
    width: 65%;
  }
}

.navigation button {
  border: 1px solid $lightgrey-2;
  border-radius: 5px;
}



.grid-container {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;

  .grid-item {
    background-color: #ccc;
  }

  .grid-item-1 {
    grid-row: 1 / 3;
  }
}



// .text-container {
//   .points {
//     margin-left: 24px;
//     list-style: disc;
//   }
// }

.btn-proceed-booking {
  width: 100%;
  font-weight: bold;
}

.charges-data p {
  margin: 10px 0;
}

.img-size {
  max-width: 100%;
  height: 100%;
}
  .large-image {
    grid-row: 1 / 3;
    width: 100%;
  }

  .covers {
    font-size: 14px;
    color: #adb5bd;
  }

  @include media("<=tablet") { 
    .activity-details-main{
      margin: 10px;
      display: flex;
      flex-direction: column;
      gap: 0px;
      padding-bottom: 50px;
      .activity-details-container{
        width: 100%;
      }

      .overview {
        margin: 0;
        margin-top: 5px;
      }

      .img-size {
        width: 100%;
        object-fit: cover;
      }
    }
  }
</style>
