<template>
    <div class="policy-box m-b-15" >
      <span class="label-no-height">Cancellation Policy - {{ room.rate.name }}</span>
      <div class="text-grey  m-t-10 ">{{getCancelationPolicy(room.room_type_id, room.rate.id)}}</div>
    </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'CancellationPolicy',
  props: ['room'],
  data() {
    return {
    }
  },
  computed: {
    ...mapState({
      rwa_data: state => state.rwa_data
    }),
    
  },
  methods: {
    selectedRateDetails(room_type_id,rateId){
      return this.rwa_data.rate_details.find(rd=>rd.room_type_id === room_type_id).rate_codes.find(r=> r.id === rateId)
    },
    getCancelationPolicy(room_type_id,rateId){
      let cancPolicy =  this.rwa_data.cancellation_policies?.find(c=>c.id === this.selectedRateDetails(room_type_id,rateId).cancellation_policy_id)?.description
      return cancPolicy
    },
   
  },
}
</script>

<style lang="scss" scoped>
.policy-box {
  font-size: 14px;
    border: solid #dadada 1px;
    padding: 10px;
    border-radius: 5px;
}
</style>
