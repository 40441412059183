<template>
    <div class="search-reservation-result" v-loading="loading">
        <div class="flexed align-center gap-10 m-b-10 pointer" style="max-width: 190px" @click="goTo('search-reservation')">
            <arrow-left-icon size="1.5x" class="custom-class"></arrow-left-icon>
            <span class="bold500">Reservation Details</span>
        </div>
        <div class="flexed-wrap-between align-center bakground-grey bordered border-radius-5 p-20 m-b-10">
           <div>{{$t('lbl_confirmation_number')}}</div>
           <span class="grey">{{searchReservationResult.conf_num}}</span>
           <el-button type="danger" v-if="!mobileView && reservationDetails.status === 'HOLD'"  @click="goToCancelReservation('cancel-reservation')">Cancel Reservation</el-button>
        </div>
        <div class="bordered border-radius-5 p-20 guest-details m-b-10">
            <span class="flexed justify-between align-center">
                <span class="title">Guest Details</span>
                <el-button type="primary" :size="mobileView ? 'mini': 'default'"  @click="goTo('guest-details')" v-if="reservationDetails.status === 'HOLD'">Edit Details</el-button></span>
            <div class="result-fields">
                <span >{{$t('lbl_name')}}</span><div >{{primaryGuest.first_name + ' ' + primaryGuest.last_name}}</div>
            </div>
            <div class="result-fields">
                <span >{{$t('lbl_status')}}</span><div >{{reservationDetails.status === 'HOLD' ? 'CONFIRMED' : reservationDetails.status}}</div>
            </div>
            <div class="result-fields">
                <span >{{$t('lbl_email')}}</span><div >{{primaryGuest.email ? primaryGuest.email : 'NA'}}</div>
            </div>
            <div class="result-fields">
                <span>{{$t('lbl_phone')}}</span><div >{{primaryGuest.phone ? primaryGuest.phone : 'NA'}}</div>
            </div>
            <div class="result-fields">
                <span >{{$t('lbl_address')}}</span>
                 <div >{{(primaryGuest.street ? primaryGuest.street + ', ':'') + (primaryGuest.city ? primaryGuest.city + ', ': '') + primaryGuest.state + ' ' + primaryGuest.country + ' ' + primaryGuest.zip_code}}</div>
            </div>
        </div>
        <div class="bordered border-radius-5 p-20 stay-details m-b-10">
            <span class="flexed justify-between align-center">
                <span class="title">Stay Details</span>
                <el-button type="primary" v-if="reservationDetails.status === 'HOLD' && check_stay_modification_allowed"
                           :size="mobileView ? 'mini': 'default'"  @click="tryEditStayDetails()"
                           :loading="editStayDetailsLoading"
                >Edit Details
                </el-button>
            </span>
            <div class="result-fields">
                <span >{{$t('lbl_check_in_date')}}</span><div >{{reservationDetails.check_in_date | formatDate}}</div>
            </div>
            <div class="result-fields">
                <span >{{$t('lbl_check_out_date')}}</span><div >{{reservationDetails.check_out_date | formatDate }}</div>
            </div>
            <div class="result-fields">
                <span >{{$t('lbl_nights')}}</span><div >{{reservationDetails.no_of_nights}}</div>
            </div>
            <div class="result-fields">
                <span >{{$t('lbl_adults')}} / {{$t('lbl_children')}}</span>
                <div >
                    <svg class="adult-icon">
                        <use xlink:href="@/assets/adult_icon.svg#adultIcon"></use>
                    </svg>
                    {{reservationDetails.adults + ' / '}}
                    <svg class="child-icon">
                        <use xlink:href="@/assets/child-02.svg#childIcon"></use>
                    </svg>
                    {{reservationDetails.children}}
                </div>
            </div>
            <div class="result-fields">
                <span >{{$t('lbl_room')}}</span> <div >{{roomType.name}}</div>
            </div>
            <div class="result-fields">
                <span >Total for Stay</span> <div >{{reservationDetails.total_for_stay | currency(property_currency) }}</div>
            </div>
        </div>
        <div class="bordered border-radius-5 p-20 payment-methods m-b-10" v-if="check_guaranteed_type">
            <span class="flexed justify-between align-center"><span class="title">Payment Methods</span>
            <el-button type="primary"  v-if="reservationDetails.status === 'HOLD'  && checkIfGuaranteeTypeCodeCC" :size="mobileView ? 'mini': 'default'" @click="goTo('add-payment')">Change Payment Method</el-button></span>

                <div class="payment-source gap-10 flexed align-center" v-for="(paymentSource, index) in paymentSources" :key="index">
                    <img class="m-r-10" :src="getImg(paymentSource)" height="20px" alt=""/>
                    {{"XXXX XXXX XXXX "+paymentSource.custom_field_1.slice(paymentSource.custom_field_1.length-4, paymentSource.custom_field_1.length)}}
                    <span class="badge" v-if="paymentSource.primary">{{'PRIMARY'}}</span>
                    <!-- <credit-card-icon size="1.5x" class="custom-class"></credit-card-icon>
                        xxxx xxxx xxxx 4242-->
                </div>
        </div>
       <el-button type="danger" class="w-100" v-if="mobileView && reservationDetails.status === 'HOLD'"  @click="goTo('cancel-reservation')">Cancel Reservation</el-button>
    </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'
import dayjs from 'dayjs';
import { ArrowLeftIcon} from 'vue-feather-icons'
import deepClone from 'clone'
import ApiCalls from '@/services/api-calls.service.js'
    export default {
        name: 'SearchReservationResult',
        components: {
             ArrowLeftIcon,
        },
        data() {
            return {
                loading: false,
                check_guaranteed_type: true,
                editStayDetailsLoading: false,
                card_types:  [{
                            "name": "AMEX",
                            "image_url": "https://hk-public-images.s3.us-west-2.amazonaws.com/card-icons/AmericanExpress2.png"
                        }, {
                            "name": "DINERS",
                            "image_url": "https://hk-public-images.s3.us-west-2.amazonaws.com/card-icons/Diners2.png"
                        }, {
                            "name": "DISCOVER",
                            "image_url": "https://hk-public-images.s3.us-west-2.amazonaws.com/card-icons/Discover2.png"
                        }, {
                            "name": "INTERAC",
                            "image_url": "https://hk-public-images.s3-us-west-2.amazonaws.com/card-icons/interac.png"
                        }, {
                            "name": "JCB",
                            "image_url": "https://hk-public-images.s3.us-west-2.amazonaws.com/card-icons/JCB2.png"
                        }, {
                            "name": "MASTER",
                            "image_url": "https://hk-public-images.s3.us-west-2.amazonaws.com/card-icons/Master2.png"
                        }, {
                            "name": "OTHER",
                            "image_url": "https://hk-public-images.s3-us-west-2.amazonaws.com/card-icons/other.png"
                        }, {
                            "name": "VISA",
                            "image_url": "https://hk-public-images.s3.us-west-2.amazonaws.com/card-icons/Visa2.png"
                        }]
            }
        },
        computed: {
            ...mapState({
                searchReservationResult:  state => state.searchReservationResult,
                mobileView: state => state.mobileView,
                propertyDetails: (state) => state.property?.details,
                paymentSources: state => state.searchReservationResult?.reservation_details?.payment_sources || [],
                bookingProfile: state => state.profile,
                keyFromUrl: state => state.keyFromUrl,
                editStayDetails: state => state.editStayDetails,
                search_data: state => state.search_data,
                property_currency: state => state.property.property_currency
            }),
            primaryGuest(){
                return this.searchReservationResult?.reservation_details?.primary_guest || {}
            },
            reservationDetails(){
                return this.searchReservationResult?.reservation_details || {}
            },
            getNights(){
                return dayjs(this.reservationDetails.check_out_date).utc().diff(dayjs(this.reservationDetails.check_in_date).utc(), 'days')
            },
            roomType(){
                return this.reservationDetails?.room_type || {}
            },
            check_stay_modification_allowed(){
              return this.searchReservationResult.stay_modification_allowed
            },
            checkIfGuaranteeTypeCodeCC(){
                return this.bookingProfile?.restrictions.booking_profile?.booking_profiles_guarantee_types?.some(b => b.code == 'CC')
            }
        },
        async beforeMount () {
          if(this.searchReservationResult.reservation_details.guaranteed_type == 'LDB'){
            this.check_guaranteed_type = false;
          }
          //  this.loading = true
            //     try {
            //         this.card_types = (await ApiCalls.get(`properties/${this.propertyDetails.id}/payment-card-types`,{}, 'baseCoreUrl'))?.data

            //     } catch (error) {
            //         console.log(error)
            //         // this.handleError(error)
            //     }
            // this.loading = false
        },
        methods: {
            ...mapMutations({
                CHANGE_STEP: 'CHANGE_STEP',
                RESET_STATE: 'RESET_STATE',
                SET_EDIT_STAY_DETAILS: 'SET_EDIT_STAY_DETAILS'
            }),
            ...mapActions({
              editStayDetailsAction: 'editStayDetails'
            }),
            async goToCancelReservation(name){
                //web/cancel-booking/consent?request_id=9b678df3-ef09-4146-9831-fa00bbb790a0
                  this.loading = true
                try {
                    this.card_types =  (await ApiCalls.get('web/cancel-booking/consent', {'request_id': this.searchReservationResult.request_token}, 'enterpriseUrl'))
                     this.goTo(name)
                } catch (error) {
                    console.log(error)
                     this.$notify.error({
                      title: this.$t('lbl_error'),
                      message: error?.response?.data?.message
                    })
                }
                this.loading = false

            },
            goTo(name){
                if(name === 'booking-select-rooms'){
                    // let queryParams = deepClone(this.$route.query)
                    // queryParams.skip_search=true
                    this.RESET_STATE()
                    this.CHANGE_STEP('rooms');
                    this.SET_EDIT_STAY_DETAILS(true)
                    // this.$router.push({name: name, query: queryParams}).catch(()=>{})
                } //else this.$router.push({name: name}).catch(()=>{})
                this.$router.push({name: name}).catch(()=>{})
            },
            getImg(paymentSource){
                return this.card_types.find(c => c.name === paymentSource.custom_field_2)?.image_url
            },
            tryEditStayDetails(){
                this.editStayDetailsLoading = true
                this.editStayDetailsAction()
                  .then(() => {this.goTo('booking-select-rooms')
                  })
                  .catch((error) => {
                    this.$notify.error({
                      title: this.$t('lbl_error'),
                      message: error?.response?.data?.message
                    })
                    this.editStayDetailsLoading = false
                  })
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '~css_vars';
    @import "~mixins";
    .search-reservation-result{
        .guest-details, .stay-details, .payment-methods{
            padding-top: 10px !important;
            font-weight: 300;
            font-size: 14px;
            background: $hk-background-grey;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 10px 20px;
            .el-button{
                justify-self: right;
            }
            .title{
               font-size: 16px;
               font-weight: 400;
            }
            .result-fields{
                display: flex;
                flex-wrap: wrap;
                span{
                    flex:1;
                }
                div{
                    flex:1.5;
                    color: $hk-grey;
                }
                svg.adult-icon, svg.child-icon {
                    --color: red;
                    height: 15px !important;
                    width:15px;
                    margin-right: 3px;
                    margin-bottom: -2px;
                    fill: $hk-primary-color !important;
                }
            }
        }
        .guest-details, .payment-methods, .stay-details{
             span:first-child{
                grid-column-start: 1;
                grid-column-end: -1;
            }
        }
        .payment-methods{
            .payment-source{
                grid-column-start: 1;
                grid-column-end: -1;
            }
        }
        .grey{
            color: $hk-grey;
        }
    }
    @include media('<=tablet') {
        .search-reservation-result{
            .guest-details, .stay-details, .payment-methods{
                .result-fields{
                    flex-direction: column;
                }
            }
            .guest-details{
                div:last-child{
                    grid-column-start: 1;
                    grid-column-end: -1;
                }
            }
        }
    }
    .badge{
      border: 0.8px solid $hk-primary-color;
      color: $hk-primary-color;
      padding: 1px 5px;
      font-size: 10px;
      border-radius: 10px;
    }
</style>
