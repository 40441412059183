<template>
  <modal cross-btn="true" size-close-icon="md" has-header-border="true"
         has-footer-border="true" @close="close" size="100%">
    <div slot="modal-title" style="font-size: 16px; padding: 8px">
      <span>Modify Date & Time</span>
    </div>
    <div slot="content" class="popup-content">

    </div>
    <div slot="modal-footer" class="modal-footer">
      <el-button type="primary">
        <custom-loader class="centered" :height="'4vh'" v-if="loading" color="white" />
        <span v-else>{{ $t('lbl_confirm') }}</span>
      </el-button>
    </div>
  </modal>
</template>

<script>
import modal from "@/components/Modal";
export default {
  name: "modify-date-popup",
  components: {modal},
  data(){
    return{
      loading: false
    }
  },
  methods:{
    close(){
      this.$emit('close')
    }
  }
}
</script>

<style scoped>
.popup-content {
  padding: 10px;
}
modal{
  width: 100vw;
}
</style>
