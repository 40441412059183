<template>
    <!-- <modal :hideHeader="true" :hideFooter="true" size="80%" :setBodyStyle="{ margin: 0 }"> -->
    <normal-popup width="75%" >
       <div slot="header" class="flexed justify-between">
         <span>Hotel Details</span>
            <span class="close pointer" @click="$emit('close')"><i class="el-icon-close"></i></span>
       </div>
     
          <hotel-details />
    </normal-popup>
  </template>
  
  <script>
  import NormalPopup from '../../../components/NormalPopup.vue';
  import HotelDetails from './HotelDetails.vue'
  
  export default {
      name: 'HotelDetailsPopup',
      components:{NormalPopup, HotelDetails}
  }
  </script>
  
  <style lang="scss" scoped>
  
  </style>