<template>
  <div class="summary-container m-b-10 m-t-20 card-border">

    <div class="image">
      <div class="img" v-if="selectedEvent.image">
        <img :src="selectedEvent.image" height="225px" width="100%">
      </div>
      <div class="no-img" v-else>
        <i class="el-icon-picture-outline no-img-icon"></i>
        <span>No Image Found</span>
      </div>
    </div>

    <div class="summary-text">
      <h3>Summary</h3>

      <div class="event-data flexed">
        <p><i class="el-icon-location-outline"></i> {{ selectedEvent.space_name }}</p><br>
        <p> <i class="el-icon-date"></i> {{ getDate | formatDate}}</p>
        <p><i class="el-icon-time"></i> {{ getFullTime }}</p>
        <p><i class="el-icon-user"></i> {{ eventTime.capacity}}</p>
      </div>
      <div class="charges-data">
        <div class="flexed justify-between"><p>Space Charges</p><p>{{ summary_details.total_space_charges | currency(currency) }}</p></div>
        <div class="flexed justify-between"><p>Package Charges</p><p>{{ summary_details.total_package_charges | currency(currency)}}</p></div>
        <div class="flexed justify-between"><p>Total Service Charges</p><p>{{ summary_details.total_service_charges | currency(currency)}}</p></div>
        <div class="flexed justify-between"><p>Total Taxes</p><p>{{ summary_details.total_tax | currency(currency)}}</p></div>
        <div class="flexed justify-between bold"><p>Grand Total</p><p>{{ (summary_details.total_space_charges + summary_details.total_package_charges + summary_details.total_service_charges + summary_details.total_tax) | currency(currency) }}</p></div>
      </div>

    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import dayjs from "dayjs";
import CurrencyData from '@/assets/currencies/currencies';

export default {
  name: "Summary",
  data(){
    return{
      currencyData: CurrencyData
    }
  },
  computed:{
    ...mapState({
      selectedEvent: state => state.events.selectedEvent,
      eventTime: state => state.events.eventTime,
      currency: state => state.events.propertyDetails.currency,
      summary_details: state => state.events.summary_details
    }),
    getFullTime(){
      let startTime = dayjs(this.eventTime.start_time).utc().format("hh:mm A")
      let endTime = dayjs(this.eventTime.end_time).utc().format("hh:mm A")

      return startTime + " - " + endTime
    },
    getDate(){
      return dayjs(this.eventTime.start_time).format("MMM DD, YYYY")
    },
  },
}
</script>

<style scoped lang="scss">
@import "~css_vars";
@import '~mixins';

.summary-container{
  max-width: 400px;
  max-height: 565px;
  .no-img{
    height: 150px;
    width: 100%;
    padding: 20px;
  }
  .img img{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .summary-text{
    padding: 15px 25px;
    font-size: 14px;
    h3, p{
      margin: 0;
    }
    h3{
      font-size: 16px;
    }
    .event-data{
      flex-wrap: wrap;
      padding: 10px 0;
      gap: 15px 50px
    }
    .charges-data{
      p{
        margin-top: 10px;
      }
      :last-child{
        margin-top: 5px;
        font-size: 16px;
      }

    }
  }
}
</style>
