<template>
  <div :class="{'no-scroll': failed === true}" class="booking-engine">
    <div class="loader loading_whole" style="height: 100vh"
         v-if="!conditionForRenderingMet">
      <div class="modified-loader">
        <loading></loading>
      </div>
    </div>
    <div v-else>
      <div v-if="bookingPageLayout">
        <default-header/>
      </div>
      <div>
        <wizard :steps="steps" :current-step="current_step" :wizard-loading="wizard_loading">
          <router-view ref='childComponent' class="w-100" v-if="$route.name === 'booking-search' || $route.name === 'confirm-payment-method' || $route.name === 'booking-confirmation'
                                                || $route.name  === 'booking-select-addons'"
                                                @injectSorjenScript="injectSorjenScript" :key="'childComponentKey' + childComponentKey" ></router-view>
          <div class="main-content w-100" v-if="$route.name === 'booking-select-rooms'
                                                || $route.name  === 'booking-guest-information'
                                                || $route.name  === 'booking-payments'  ||
                                                $route.name === 'booking-points-redemption'">
            <div :class="mobileView ? 'w-100': 'content'">
              <reservation-info v-if="editStayDetails && current_step && current_step.name === 'Select Rooms' && (!mobileView || (mobileView && showReservationDetails))"
                      @close="showReservationDetails=false"/>
              <reservation-info-mobile v-if="editStayDetails && mobileView && current_step && current_step.name === 'Select Rooms'" @showReserDetails="showReservationDetails=true"/>

              <router-view ref='childComponent' class="w-100" @roomChanged="roomChanged"
                                             @injectSorjenScript="injectSorjenScript"    @updateShowNextButtonOnPayment="updateShowNextButtonOnPayment" :nextStep="nextStep"></router-view>
            </div>
            <div class="summary" v-if="!mobileView">
              <summary-section
                :key="rightContentKey"
                v-if="selected_room_details.length > 0  && current_step.key === 'rooms' || current_step.key === 'information' || current_step.key === 'payment' || $route.name  === 'booking-select-addons'"/>
            </div>
          </div>
        </wizard>
      </div>
      <div class="navigation"
           v-if="(current_step.key === 'search' && $route.query.package_type) || (current_step.key != 'search' && current_step.key != 'confirmation')">
        <button @click="previousStep()" v-show="current_step.index > 0 && (!editStayDetails || current_step.name != 'Select Rooms')">{{ $t('lbl_previous') }}</button>
        <button @click="gotoReservationDetails()" v-if="editStayDetails && current_step.name === 'Select Rooms'">{{ $t('lbl_cancel') }}</button>
        <el-button class="next" type="primary" :loading="wizard_loading || loading"
                   v-if="(current_step.key === 'search' && $route.query.package_type) ||
                   ((current_step.index > 0 && selected_room_details.length > 0 && (this.selected_room_details.length === this.search_data.rooms.length || enableMultiRoomBooking))
                       && current_step.key != 'search' && current_step.key != 'payment' && current_step.key != 'confirmation')
                      || (current_step.key === 'payment' && getGuarantyRequiredToBook)"
                   @click="nextStep()">
          {{ current_step.key === 'search' && $route.query.package_type ? $t('lbl_search') : $t('lbl_next') }}
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import loading from '@/components/common/loading.vue';

import DefaultHeader from "../../components/headers/default-header";
import Wizard from "./component/wizard";
import SummarySection from './summary-section/SummaryDetails.vue';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'

import ReservationInfo from './select/ReservationInfo.vue';
import ReservationInfoMobile from './select/ReservationInfoMobile.vue';
import deepClone from 'clone'
/* eslint-disable no-undef */
export default {
  name: "booking-engine",
  components: {
    Wizard,
    DefaultHeader,
    loading,
    SummarySection,
    ReservationInfo,
    ReservationInfoMobile
  },
  data() {
    return {
      initial_data_loaded: false,
      finish_url: null,
      property_details_for_background: {
        url: null,
        property_name: null,
      },
      discountOrPackageFailed: false,
      rightContentKey: 10000,
      loadingUser: false,
      showReservationDetails: false,
      showNextButtonOnPayment: false,
      loading: false,
      paymentTimeout: null
    }
  },
  computed: {
    ...mapState({
      property: state => state.property.details,
      minimum_date: state => state.configs.minimum_date,
      failed: state => state.api_fail,
      webUrl: () => {
        let url = this.property.website;
        return url && url.slice(0, 3) === 'www' ? 'http://' + url : url;
      },
      current_step: state => state.current_step || {},
      steps: state => state.steps,
      wizard_loading: state => state.loading,
      rooms_requested: state => state.stay_details.rooms_requested,
      access_status: state => state.access_data,
      profile: state => state.profile,
      mobileView: state => state.mobileView,
      search_data: state => state.search_data,
      rate_content: state => state.rate_content,
      booking_data: state => state.booking_data,
      selected_room_details: state => state.selected_room_details,
      logged_in_user_details: state => state.logged_in_user_details,
      editStayDetails: state => state.editStayDetails,
      confirmed_rooms_data: state => state.confirmed_rooms_data,
      childComponentKey: state => state.childComponentKey,
      mop: state => state.mop,
      enable_book_with_points : state => state.enable_book_with_points,
      show_payment_frame: state => state.show_payment_frame
    }),
    conditionForRenderingMet() {
      return this.minimum_date;
    },
    bookingPageLayout() {
      if (this.access_status) {
        return 'VERTICAL'
      } else {
        return this.property &&
          this.property.website_config &&
          this.property.website_config.booking_page_layout;
      }
    },

    current_step_name() {
      return this.current_step ? this.current_step.key : ''
    },
    getGuarantyRequiredToBook() {
      return !this.profile?.restrictions?.booking_profile?.guarantee_required_to_book ||  this.mop == 'COMPANY'  // this.showNextButtonOnPayment //|| !this.profile?.restrictions?.booking_profile?.guarantee_required_to_book
        || this.profile?.restrictions?.booking_profile?.guarantee_required_to_book && !this.show_payment_frame
    },
    enableMultiRoomBooking() {
      return this.property.website_config?.enable_multi_room_booking
    }
  },
  watch: {
    current_step_name(newValue, oldValue) {
       window.dataLayer = window.dataLayer || [];
      let eventName = '';
      if(this.current_step.index > 0){
         if(oldValue ==='rooms')  this.injectSorjenScript('SEARCH')
        if(oldValue !='confirmation' && newValue !='confirmation' && this.findNextStep().key === 'confirmation')  this.injectSorjenScript('SHOPPING_CART')
      }
      switch (newValue) {
        case 'search':
          eventName = 'search'
          break;
        case 'selectRooms':
        case 'rooms':
          eventName = 'selectRooms'
          break;
        case 'summary':
          eventName = 'priceSummary'
          break;
        case 'guestInformation':
        case 'information':
          eventName = 'guestInformation'
          break;
        case 'payment':
          eventName = 'payment'
          break;
        default:
          break;
      }
      if (window.dataLayer  && eventName) {
        if (eventName === 'priceSummary') {
          try {
            let reservationInfo = this.stay_details
            this.selected_room_details.forEach((room_details, index) => {
              // eslint-disable-next-line no-undef
              if (window.dataLayer) {
                let payload = {
                  event: eventName,
                  roomName: room_details.room.name,
                  roomCode: room_details.room.code,
                  rateCode: room_details.room.selected_rate.code,
                  rateName: room_details.room.selected_rate.name,
                  arrivalDate: reservationInfo.from,
                  departureDate: reservationInfo.to,
                  guestQuantity: room_details.number_of_adults + room_details.number_of_children,
                  adultQuantity: room_details.number_of_adults,
                  childQuantity: room_details.number_of_children,
                  daysToArrival: dayjs(reservationInfo.from).utc().diff(dayjs().utc(), 'days'),
                  roomQuantity: 1,
                  nightsQuantity: dayjs(reservationInfo.to).utc().diff(dayjs(reservationInfo.from).utc(), 'days'),
                }
                //console.log('reservation_info_payload',payload)
                window.dataLayer.push(payload);
              }
            });
          } catch (error) {
            throw error
          }
        } else {
          try {
            window.dataLayer.push({event: eventName});

          } catch (error) {
            throw error
          }

        }
      }
    }
  },
  async beforeMount() {
    dayjs.extend(utc)

    this.CHANGE_STEP('search')
    // this.setWizardSteps({stepName: 'rooms'})

  },
  methods: {
    updateShowNextButtonOnPayment(event){
      this.showNextButtonOnPayment = event
    },
    roomChanged() {
      this.rightContentKey++
    },
    ...mapActions({
      createReservation: 'createReservation',
      resetStateAndGotoResrvation: 'resetStateAndGotoResrvation',
    }),
    ...mapMutations({
      CHANGE_STEP: 'CHANGE_STEP',

      SET_BOOKING_DATA: 'SET_BOOKING_DATA',

      WIZARD_LOADING: 'WIZARD_LOADING',
      SELECTED_ROOM_DETAILS: 'SELECTED_ROOM_DETAILS',
      SET_KEY_FROM_URL: 'SET_KEY_FROM_URL',
      SET_TOKEN_FROM_URL: 'SET_TOKEN_FROM_URL'

    }),



    commitReservationDetails() {
      let data = [];
      this.booking_data.sessions.forEach(item => {
        let guest_data = {
          session_id: item.session_id,
          guest_data: item.guest_data,
          stay_remarks: item.stay_remarks,
          remarks: item.remarks,
          guarantee_type: {},
          guarantee_details: {},
        }

        guest_data.loyalty_guest_id = this.logged_in_user_details?.account_number || null,
        guest_data.loyalty_guest_number = this.logged_in_user_details?.account_number || null,
        guest_data.loyalty_program_id = this.logged_in_user_details?.loyalty_program_id || null

        data.push(guest_data)
      })
      let final_data = {
        sessions: data
      }
      this.SET_BOOKING_DATA(final_data);
      this.createReservation(true)
    },
    async nextStep() {
      if (this.current_step.key === 'search') {
        this.$refs.childComponent.search()
      } else if (this.current_step.key === 'information' && this.$refs.childComponent) {
        await this.$refs.childComponent.validateGuestForm();
        if (this.$refs.childComponent.formValidation) {
          await this.CHANGE_STEP(this.findNextStep().key);
          // if (this.profile?.restrictions?.booking_profile?.restricted_guarantee_types?.find(item => item.code === "FPCC" || item.code === "ELCC")) {
          //   await this.CHANGE_STEP(this.findNextStep().key);
          // } else {
          //   this.commitReservationDetails()
          // }
        }
      } else if (this.current_step_name === 'payment') {
        this.$refs.childComponent.validateChildren()
      }
      else if (this.current_step_name === 'addons') {
        this.WIZARD_LOADING(true);
        if((await this.$refs.childComponent.postAddon()) === true) {
          await this.CHANGE_STEP('information');
           setTimeout(() => { this.WIZARD_LOADING(false);}, 200);
        } else {
          this.WIZARD_LOADING(false);
        }
      } else if (this.current_step_name=== 'rooms'){
        if(this.enable_book_with_points)
          await this.CHANGE_STEP('pointsRedemption');
        else
          await this.CHANGE_STEP('information');
      }

        else if(this.current_step_name=== 'pointsRedemption' && this.enable_book_with_points) {
          try{
            this.WIZARD_LOADING(true);
            let response = await this.$refs.childComponent.save()
            if(response) {
               await this.CHANGE_STEP(this.findNextStep().key);
            }
            this.WIZARD_LOADING(false);
          }
          catch(error) {
            console.log(error)
          }
        }


      else {
        await this.CHANGE_STEP(this.findNextStep().key);
      }
    },
    async previousStep() {
      this.WIZARD_LOADING(false);
      if(this.current_step_name === 'addons'){
         let selecteRoomDetails = deepClone(this.selected_room_details)
          selecteRoomDetails.forEach(room => {
            room.chosenAddons = []
          });
        this.SELECTED_ROOM_DETAILS(selecteRoomDetails)
      }
      if(this.current_step_name === 'information' && this.enable_book_with_points)
        await this.CHANGE_STEP('pointsRedemption')
      else if(this.current_step_name === 'information' && !this.enable_book_with_points){
        this.steps['addons'] ? await this.CHANGE_STEP('addons') : await this.CHANGE_STEP('rooms')
      }
      else if(this.current_step_name === 'pointsRedemption' && this.enable_book_with_points){
          await this.CHANGE_STEP('rooms')
      }
      else
        await this.CHANGE_STEP(this.findPreviousStep().key)
    },
    findPreviousStep() {
      let indexOfStepToSubtract = 1;
      if(this.selected_room_details.some(s=>s.package_id) && this.steps && this.steps['addons'] && this.current_step.key === 'information')
        indexOfStepToSubtract = 2
      let arrayOfSteps = Object.entries(this.steps).map(s => s)
      let previousStep = arrayOfSteps.find(s => s[1].index === (this.current_step.index - indexOfStepToSubtract))[1]
      return previousStep;
    },
    findNextStep() {
      let indexOfStepToAdd = 1;
      if(this.selected_room_details.some(s=>s.package_id) && this.steps && this.steps['addons'] && this.current_step.key === 'rooms'
       // || this.selected_room_details.some(s=>s.package_id) && this.steps && this.current_step.key === 'rooms' && this.enable_book_with_points
      )
        indexOfStepToAdd = 2
      let arrayOfSteps = Object.entries(this.steps).map(s => s)
      let nextStep = arrayOfSteps.find(s => s[1].index === (this.current_step.index + indexOfStepToAdd))[1]
      return nextStep;
    },
    gotoReservationDetails(){
      this.resetStateAndGotoResrvation('search-reservation-result')
    },
    injectSorjenScript(pt){
      if(!this.property?.website_config?.sojern_id)
        return
      let rooms = []
      if (pt === 'CONVERSION'){
        rooms = this.confirmed_rooms_data
      } else {
        rooms = this.selected_room_details
      }


      rooms.forEach((room,index) => {
        var params = {
            pt: pt, /* Page Type - CONVERSION or HOME_PAGE or PRODUCT or SEARCH or SHOPPING_CART or TRACKING */
            hd1: room.check_in_date.split('T')[0], /* Check In Date. Format yyyy-mm-dd. Ex: 2015-02-14 */
            hd2: room.check_out_date.split('T')[0], /* Check Out Date. Format yyyy-mm-dd. Ex: 2015-02-14 */
            hc1: this.property.city, /* Destination City */
            hs1: this.property.state, /* Destination State or Region */
            hpr: this.property.name, /* Hotel Property */
            hr: 1, /* Number of Rooms */
            hpid: this.property.website_config?.sojern_id, /* Property ID */
            hcu: this.property.currency, /* Purchase Currency */
            hp: this.grandTotal(room).toString(), /* Purchase Price */
            hconfno: room.confirmation_no || '', /* Confirmation Number */
            sha256_eml: "",
            session_id: room?.session_id  ? room?.session_id : this.profile?.sessions[index] 
          };
          /* Please do not modify the below code. */
          var cid = [];
          var paramsArr = [];
          var cidParams = [];
          var pl = document.createElement('script');
          var defaultParams = {"vid":"hot","et":"hc"};
          for(let key in defaultParams) { params[key] = defaultParams[key]; }
          for(let key in cidParams) { cid.push(params[cidParams[key]]); }
          params.cid = cid.join('|');
          for(let key in params) { paramsArr.push(key + '=' + encodeURIComponent(params[key])) }
          pl.type = 'text/javascript';
          pl.async = true;
          pl.src = 'https://beacon.sojern.com/pixel/cp/225?f_v=v6_js&p_v=1&' + paramsArr.join('&');
          (document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]).appendChild(pl);

      });

    },
     grandTotal(room_detail) {

       if(room_detail && room_detail.profileTaxes && room_detail.profileTaxes.charges){
        return room_detail.profileTaxes.charges.reduce((b, a) => (b || 0)  + (a.net_value_excl_taxes || 0) , 0)
       }else if(room_detail && room_detail.total_amount){
         let current_room = this.selected_room_details.filter(item => item.rate.name === room_detail.rate_code_name && item.room.name === room_detail.room_type_name )
         return current_room[0].profileTaxes.charges.reduce((b, a) => (b || 0)  + (a.net_value_excl_taxes || 0) , 0)
       }
       else return 0
    },
  },
}
</script>

<style lang="scss" scoped>
@import "src/assets/sass/config/mixins";
@import "src/assets/sass/config/variables";

.booking-engine {
  margin-bottom: 70px;
}

.main-content {
  display: flex;
  justify-content: space-between;
  gap: 10px;

  .content {
    width: 73%;
  }

  .summary {
    width: 27%
  }
}

#rooms-step {
  > div {
    width: 100%;
  }
}

.access-link {
  position: absolute;
  top: 0;
  right: 0;
  color: transparent;
  background-color: transparent;
  height: 0;
}

.loading_whole {
  height: 80vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modified-loader {
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  :last-child {
    font-size: 1.5rem;
    color: rgba(0, 0, 0, 0.8);
  }

  .blue-text {
    color: $blue;
  }
}

@include media('<=tablet') {
  .modified-loader {
    margin-bottom: unset;
  }
}
.navigation{
  z-index: 2000;
}


</style>
