<template>
  <div class="modal-comp" :class="position" @click.self="hideClickOut && closeModal()">
    <div class="modal" :style="{'width': size ? size : '50%'}">
      <header :style="{'border-bottom': hasHeaderBorder ? '1px solid #ccc' : ''}"
              class="modal-card-head"
              :class="{'blue-title': blueTitle}">
        <slot name="modal-title" class="modal-title">Modal title</slot>
        <el-button v-if="crossBtn" type="default" class="close"
                   :class="{'big' : sizeCloseIcon === 'lg', 'medium': sizeCloseIcon === 'md', 'small' : sizeCloseIcon === 'sm'}"
                   @click="closeModal()"><i class="el-icon-close"></i></el-button>
      </header>
      <section class="modal-card-body">
        <slot name="content">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Expedita eius
          maiores, itaque facilis necessitatibus repudiandae alias tempore. Dolorum, necessitatibus
          iure.
          Quibusdam distinctio laudantium, delectus quo eveniet fugiat? Atque, dolores, cumque?
        </slot>
      </section>
      <footer :style="{'border-top': hasFooterBorder ? '1px solid #ccc' : ''}"
              class="modal-card-foot">
        <slot name="modal-footer">
          <el-button type="danger" @click="closeModal()">Close</el-button>
          <el-button type="primary">Save</el-button>
        </slot>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: ['size', 'sizeCloseIcon', 'crossBtn', 'blueTitle', 'position', 'hideClickOut', 'hasHeaderBorder', 'hasFooterBorder'],
  data() {
    return {
      landed: false,
    };
  },
  mounted() {
    if (!this.closeBtn) {
      this.closeBtn = false;
    }
  },
  methods: {
    closeModalAfterClickOut(status) {
      if (this.landed) {
        this.landed = status;
        this.closeModal();
      }
      this.landed = status;
    },
    closeModal() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">

@import "~css_vars";
@import '~mixins';
@import "@/assets/sass/config/mixins";

.modal-comp {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(#000, .3);
  display: flex;
  align-items: center;
  justify-content: center;


  &.top-right {
    align-items: flex-start;
    justify-content: flex-end;
  }

  &.top-left {
    align-items: flex-start;
    justify-content: flex-start;
  }

  &.bottom-right {
    align-items: flex-end;
    justify-content: flex-end;
  }

  &.bottom-left {
    align-items: flex-end;
    justify-content: flex-start;
  }

  .modal {
    position: relative;
    background-color: white;
    border-radius: 4px;
    overflow: hidden;
    height: auto;
    max-height: 90vh;
    min-width: 30vw;
    display: flex;
    flex-direction: column;
    margin: 10px 20px;

    @include media("<=tablet") {
      height: 100%;
      max-height: 100%;
      width: 100%;
      min-height: 100%;
      margin: 0;
    }
  }

  .modal-card-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;

    &.blue-title {
      display: flex;
      align-items: center;
      background-color: #409eff;
      color: white;
    }
  }

  .blue-title > .close {
    color: white;
  }

  .modal-card-body {
    overflow: auto;
    margin: 0 20px;
    text-transform: initial;
    color: lighten(#000, 10);
    flex: 1;
  }

  .modal-card-foot {
    padding: 10px 20px;
    background-color: white;
    text-transform: initial;
    text-align: right;
  }

  .close {
    border: none;
    background: transparent;
    padding: 0;
  }

  .big {
    font-size: 25px;
  }

  .medium {
    font-size: 20px;
  }

  .small {
    font-size: 20px;
    text-align: end;
    margin-right: 3px;
  }
}

@media (max-width: 1024px) {
  .modal-comp {
    .modal {
      width: 450px;
    }
  }
}


</style>
