import { render, staticRenderFns } from "./points-filter.vue?vue&type=template&id=3be2d355&scoped=true&"
import script from "./points-filter.vue?vue&type=script&lang=js&"
export * from "./points-filter.vue?vue&type=script&lang=js&"
import style0 from "./points-filter.vue?vue&type=style&index=0&id=3be2d355&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3be2d355",
  null
  
)

export default component.exports