<template>
  <div class="company-code-payment-main m-20 card-border">
    <div class="m-t-10">
      <p class="label">Search Company By Code</p>
      <div class="flexed gap-10 ">
        <el-input placeholder="Enter Company Code" v-model="companyCode"></el-input>
        <el-button type="primary"
                   @click="findCompanyByCode"
        >Search</el-button>
      </div>
    </div>
    <div class="error" v-if="errorMsg">
      <p> {{ $t('lbl_search_company_error') + ': ' + errorMsg}} </p>
    </div>
    <div v-if="loading" class="flexed justify-center align-center">
      <loader></loader>
    </div>
    <div v-if="company.name && !loading" class="m-t-20">
      <p class="label">Select Company</p>
      <div  class="company card-border" @click="sendData()" :style="{ 'border-left': isSelected ? '5px solid #0fd142' : '',  'padding-left' : isSelected ? '15px':'19px'}">
        <div class="font-size-16 bold flexed justify-between"><p>{{ company.name }}</p>
<!--          <i class="el-icon-circle-check" style="color: green" v-if="isSelected"></i>-->
        </div>
        <p class="font-size-14 m-t-10">{{ this.company.address + ', ' + this.company.city + ', '  + this.company.state + ', '  + this.company.country + ', '  + this.company.postal }}</p>
      </div>
    </div>
  </div>

</template>

<script>
import apiCallsEventsService from "../../../../services/events/api-calls-events.service";
import eventApiCall from "../../../../services/events/api-calls-events.service";
import {mapMutations, mapState} from "vuex";
import loader from '@/components/common/loading.vue';

export default {
  name: "companyCodePayment",
  components:{
    loader
  },
  data(){
    return{
      companyCode: '',
      loading: false,
      company: {},
      errorMsg: '',
      isSelected: false
    }
  },
  methods: {
    async findCompanyByCode() {
      this.company = {}
      this.errorMsg = ''
      this.loading = true
      try {
        let result = await eventApiCall.get(`${this.property.id}/web/search-company?company_code=${this.companyCode}`, null,  'eventManagementApi')
        this.company = result.data
      } catch (error) {
        this.SET_ERROR(false)
        this.errorMsg =  error?.response?.data?.message || error?.data?.message || error?.message || error?.data
      }
      this.loading = false
    },
    sendData(){
      if(this.isSelected){
        this.isSelected = false
        this.$emit('sendData', {})
      }
      else {
        this.isSelected = true
        let payload = {
          "guarantee_type": "LDB",
          "payment_source": null,
          "company_id": this.company.code
        }
        this.$emit('sendData', payload)
      }
    },
    ...mapMutations({
      SET_ERROR: "SET_ERROR",
    })
  },
  computed:{
    ...mapState({
      property: state => state.events.propertyDetails
    })
  }
}
</script>

<style scoped lang="scss">
@import "~css_vars";
@import '~mixins';
.company-code-payment-main{
  width: 505px;
  padding: 20px;
  min-height: 530px;
  .label{
    margin: 0 2px 10px;
    font-size: 14px;
  }
  .company{
    padding: 15px;
    font-size: 14px;
    margin-top: 20px;
    p{
      margin: 0;
    } :hover{
        cursor: pointer;
      }
  }
  .error{
    color: $red;
  }
}

</style>
