<template>
  <div class="select-packages" v-loading="loading">
    <div class="packages m-t-10">
      <el-card class="box-card" v-for="(item,index) in filteredPackages" :key="index"
               :shadow="!mobileView ? 'always' : 'never'">

        <div class="package-image-box" :class="item.cover_photo ? '' : 'border-bottom'">
          <img :alt="`Image ${index + 1}`" v-if="item.cover_photo" :src="item.cover_photo" class="dot"
               style="height:100%; width: 100%; border-radius: 5px">
          <i slot="suffix" class="el-icon-picture-outline" v-if="!item.cover_photo"></i>
        </div>
        <div class="package-name-box">
          <div class="name-box">
            <label>{{ item.name.length > 17 ? item.name.substring(0, 17) + '..' : item.name }}</label>
          </div>
          <div class="dsc-box">
            <el-tooltip popper-class="popper-custom-class" :disabled="item.description && item.description.length < 150"
                        placement="bottom" effect="light" :content="item.description"
            >
                    <span>{{
                        item.description && item.description.length > 150 ? item.description.substring(0, 150) + '...' : item.description
                      }}</span>
            </el-tooltip>
          </div>
        </div>

        <div class="package-buttons">
          <el-button v-if="selectedPackage(item)" type="danger" plain size="medium" slot="activator"
                     @click="selectPackage(item)">Remove
          </el-button>
          <el-button v-else type="primary" plain size="medium" slot="activator" class="add" @click="selectPackage(item)">Select
          </el-button>
        </div>

      </el-card>
    </div>
  </div>
</template>

<script>
import CommonServices from '@/services/common.services'
import dayJs from "dayjs";
import {mapMutations, mapState} from "vuex";

export default {
  name: 'Packages',
  props: ['searchData', 'mobileView'],
  data() {
    return {
      loading: false,
      package_rates_applicable: [],
    }
  },
  beforeMount() {
    this.applicableRates(this.searchData)
    this.loading = false
  },
  computed: {
    ...mapState({
      applicablePackage: state => state.applicablePackage
    }),
    filteredPackages() {
      if(this.applicablePackage) {
        return this.package_rates_applicable?.filter(item => item?.code === this.applicablePackage?.code)
      }
      return this.package_rates_applicable
    }
  },
  methods: {
    ...mapMutations({
      SET_APPLICABLE_PACKAGE: 'SET_APPLICABLE_PACKAGE',
    }),
    async applicableRates(searchData) {
      let adults = searchData.rooms.reduce((b, a) => b + a.persons.adults, 0)
      let params = {
        check_in_date: dayJs(searchData.checkIn).format().split('T')[0],
        check_out_date: dayJs(searchData.checkOut).format().split('T')[0],
        number_of_persons: adults,
      }

      this.package_rates_applicable = (await CommonServices.getApplicableRates(params)).data;

      if (this.$route.query.package_type && this.$route.query.package_type !== "ALL") {
        let pack = this.package_rates_applicable.find(pack => pack.code === this.$route.query.package_type)
        this.SET_APPLICABLE_PACKAGE(pack)
      }
    },
    selectedPackage(item) {
      return this.applicablePackage && this.applicablePackage.code === item.code
    },
    selectPackage(item) {
      if (this.applicablePackage?.code === item.code) {
        this.SET_APPLICABLE_PACKAGE(null);
      } else {
        this.SET_APPLICABLE_PACKAGE(item);
      }
    },
  },
}
</script>


<style lang="scss" scoped>
@import "../../../assets/sass/config/variables";
@import "../../../assets/sass/config/mixins";

.select-package-title {
  font-size: 14px;
}

.packages {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  grid-gap: 20px 20px;

  .box-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-items: center;
    align-items: center;

    ::v-deep .el-card__body {
      width: 100%;
    }

    .package-image-box {
      width: 100%;
      height: 180px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .package-name-box {
      width: 100%;

      .name-box {
        text-align: center;
        margin-top: 5px;
        margin-bottom: 10px;
        font-weight: 700;
        font-size: 1rem;
      }

      .dsc-box {
        padding: 0 5px;
        font-size: 14px;
        min-height: 120px;
        width: 100%;
      }
    }

    .package-buttons {
      display: flex;
      justify-content: center;
      padding: 5px;

      .el-button {
        width: 100%;

        color: white !important;

        &.add {
          background-color: $hk-primary-color !important;

        }

        &:hover {
          background-color: $hk-primary-color;
        }
      }

    }
  }
}

@include media('>=tablet') {
  .select-packages {
    width: 70%;
    margin: 10px auto;
  }
}

@include media('<=1024px') {
  .select-packages {
    width: 100% !important;
  }
}

@include media('<=tablet') {
  .select-packages {
    width: 100%;
  }
  .select-package-title {
    margin-top: 20px;
    margin-left: 20px !important;
  }
  .packages {
    grid-template-columns: 1fr;

    .el-card {
      border: none;
    }

  }
}
</style>
<style lang="scss">
.el-tooltip__popper {
  max-width: 280px !important;
}
</style>
