<template>
    <div>
        <!-- Price desc -->
        <div v-if="ifRateCodeParamIsGoogle">
            {{ rate.price_summary.total_amount_after_tax | currency }}
        </div>
        <div v-else-if="rate.price_summary.total_points && enable_book_with_points"
            class="flexed align-center justify-end" :class="{ 'justify-end': mobileView }">
            <div class="price sub-title">{{ rate.price_summary.total_points | formatBigNumber
            }} Points</div>
        </div>
        <div v-else-if="rate.price_summary.strike_price || rate.price_summary.avg_amount_after_discount"
            class="flexed align-center" :class="{ 'justify-end': mobileView }">
            <div class="discount-price  m-r-5" :class="{ 'flex_1': !mobileView }">
                {{ (rate.price_summary.avg_base_amount_before_tax ?
                    rate.price_summary.avg_base_amount_before_tax :
                    rate.price_summary.avg_amount_before_tax +
                    (rate.price_summary.avg_inclusive_tax_amount || 0)) | currency }}
            </div>
            <div class="price sub-title">{{ (rate.price_summary.avg_amount_after_discount ?
                rate.price_summary.avg_amount_after_discount :
                rate.price_summary.avg_amount_before_tax +
                (rate.price_summary.avg_inclusive_tax_amount || 0)) | currency }}</div>
        </div>
        <div v-else :class="['price', 'sub-title', { 'flexed-end': !priceInfo }, 'w-100']">
            <!--                  {{ rate.price_summary.avg_amount_before_tax | currency }}-->
            {{ (rate.price_summary.conversion_avg_amount_after_tax_and_fee || rate.price_summary.avg_amount_after_tax_and_fee ) | currency }}

        </div>
        <template v-if="ifRateCodeParamIsGoogle">
            <div class="price-desc">Including Taxes and Fees</div>
        </template>
        <template v-else-if="!(rate.price_summary.total_points && enable_book_with_points)">
            <div v-if="mobileView" class="flexed justify-end " style="font-size: 12px;">
                <span class="price-desc" style="margin-right: 5px;">{{ $t('lbl_per_night_avg')  }} </span>
                <span class="price-desc"
                    v-if="rate.price_summary.avg_inclusive_tax_amount === 0 || rate.price_summary.avg_inclusive_tax_amount === null || !rate.price_summary.avg_inclusive_tax_amount">Excluding Taxes
                    & Fees</span>
                <span v-else class="price-desc">Including Taxes and Fees</span>
            </div>
            <div :class="{ 'price-info': priceInfo, 'price-desc-box': !priceInfo }" v-else>
                <div class="price-desc">{{ $t('lbl_per_night_avg')  }}</div>
                <div class="price-desc"
                    v-if="rate.price_summary.avg_inclusive_tax_amount === 0 || rate.price_summary.avg_inclusive_tax_amount === null || !rate.price_summary.avg_inclusive_tax_amount">Excluding Taxes
                    & Fees</div>
                <div v-else class="price-desc">Including Taxes and Fees</div>

            </div>
        </template>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'PriceInformation',
    props: {
        rate: Object,
        priceInfo: Boolean
    },
    computed: {
        ...mapState({
            mobileView: state => state.mobileView
        }),
        ifRateCodeParamIsGoogle() {
            return this.$route.query?.rate_code === 'GOOGLE'
        },
    }

}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/config/mixins";
@import "@/assets/sass/config/variables";



//   New Screen css
.lowest-rate {
    width: 35%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}


.price-desc-box {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-size: 12px;
}


.price-info  {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}


//   -----
:deep(ul) {
    margin-left: 30px !important;
    list-style: disc !important;
}

.preferred-tag {
    background-color: $hk-blue;
    border-radius: 10px;
    color: #fff;
    display: inline-block;
    font-size: 11px;
    font-weight: normal;
    height: 18px;
    line-height: 18px;
    padding: 0 6px;
    text-align: center;
    white-space: nowrap;
    width: fit-content;
}

.room-desc {
    font-size: 14px;
    width: 40%;

    // text-align: justify;
    // border: solid #dadada 1px;
    // border-radius: 5px;
    // padding: 10px;
    .show-more {
        color: $hk-primary-color;
    }

}
.discount-price {
    font-size: 10px;
    // margin-right: 10px;
    text-decoration: line-through red;
}

// Style > = desktop

@include media('>=desktop') {

    .wrapper {
        display: flex;
        flex-direction: column;
        font-size: 14px;

        .room-wrapper {
            border: solid #dadada 1px;
            border-radius: 5px;
            padding: 16px;
            margin-top: 10px;
            // display: flex;
        }

        //   Room box
        .room {
            width: 100%;
            display: flex;
            gap: 20px;

            .carousel-container {
                width: 25%;
                height: 100%;

                .img {
                    margin: 0px;
                }
            }

            .desc {
                padding: 0 2px;
                width: 90%;
                text-align: justify;
                overflow: auto;
                max-height: 250px;
            }
        }


        //   Rates Box
        .available-rates {
            flex: 1;

            .rates-wrapper {
                border: 1px solid #dadada;
                border-radius: 5px;
                margin: 10px 0;

                .rates {
                    margin: 10px 0;

                    .rate {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0 20px;

                        .rates-desc {
                            align-self: start;
                            width: 70%;

                            .description {
                                padding: 0 2px;
                                width: 90%;
                                text-align: justify;
                                overflow: auto;
                                max-height: 250px;
                                font-size: 14px;
                                font-weight: 400 !important;
                                font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, 'Lato', Arial, sans-serif;
                            }

                            .price {
                                font-size: 18px;
                            }

                        }

                        .discount-price {
                            font-size: 10px;
                            margin-right: 10px;
                            text-decoration: line-through red;
                        }

                        .base-amount-before-tax {
                            font-size: 11px;
                            margin-right: 10px;
                            text-decoration: line-through red;
                        }

                        .desc {
                            text-align: end;

                            .price {
                                font-size: 16px;
                            }

                            .book-btn {
                                margin-top: 20px;
                                min-width: 148px;

                                .or-divider {
                                    display: flex;
                                    justify-content: space-around;
                                    font-size: 12px;
                                    margin-top: 5px;
                                    margin-bottom: 5px;
                                }
                            }

                            .price-desc {
                                font-size: 12px;
                                font-weight: 400;
                                text-align: end;

                            }

                        }
                    }

                    .divider {
                        margin: 10px;
                    }

                    &:not(:last-child) {
                        .divider {
                            border-bottom: 1px #dadada solid;
                        }
                    }
                }
            }
        }
    }
}

.title {
    font-size: 16px;
    font-weight: bold;
    color: #4E4E4E;
}

.sub-title {
    font-size: 16px;
    font-weight: bold;
    color: #4E4E4E;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}


//   Responsivity
@include media('<=desktop') {
    .room-desc {
        width: 100%;
    }

    .title {
        margin: 0px 0;
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: bold;
        color: #4E4E4E;
    }

    .wrapper {
        flex-direction: column;
        font-size: 14px;
        padding: 0 25px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        margin-bottom: 20px;

        .room-wrapper {
            border: solid #dadada 1px;
            border-radius: 5px;
            padding: 16px;
            margin-top: 15px;
            display: flex;
            flex-direction: column;
            margin: 15px 25px;
        }

        .room {
            .desc {
                text-align: justify;
            }
        }

        .available-rates {
            .title {
                margin-top: 10px;
            }

            .rates-wrapper {
                .rates {
                    .rate {
                        margin: 5px 0;
                        padding: 10px;
                        border: 1px solid #ddd;
                        border-radius: 4px;
                        // background-color: #fcfcfc;

                        .rates-desc {
                            .price {
                                font-size: 16px;
                            }
                        }

                        .discount-price {
                            font-size: 10px;
                            margin-right: 10px;
                            text-decoration: line-through red;
                        }

                        .base-amount-before-tax {
                            font-size: 11px;
                            margin-right: 10px;
                            text-decoration: line-through red;
                        }

                        .desc {
                            margin: 10px 0;

                            .price {
                                font-size: 16px;
                            }

                            .book-btn {
                                margin-top: 20px;
                            }
                        }
                    }

                    .divider {
                        margin: 15px 0px;
                    }

                    &:not(:last-child) {
                        .divider {
                            // border-bottom: 1px #dadada solid;
                        }
                    }
                }
            }
        }
    }

    .mobile-btn {
        height: 50px !important;
    }

}</style>