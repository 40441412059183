<template>
  <div class="policy-box" v-if="getGuaranteeId(room.room_type_id, room.rate.id)">
    <span class="label-no-height">{{'Guarantee Policy - ' + getGarantyName(room.room_type_id, room.rate.id)}}</span>
    <div class="text-grey m-t-10 ">{{ propertyDetails.guarantee_policy }}</div>
  </div>
</template>

<script>
import {mapState} from 'vuex'

export default {
  name: 'PolicyBox',
  props: ['room', 'roomIndex'],
  computed: {
    ...mapState({
      propertyDetails: state => state.property.details,
      rwa_data: state => state.rwa_data
    }),
  },
  methods: {

    selectedRateDetails(room_type_id,rateId){
      return this.rwa_data.rate_details.find(rd=>rd.room_type_id === room_type_id).rate_codes.find(r=> r.id === rateId)
    },
    getGuaranteeId(room_type_id,rateId){
      return this.selectedRateDetails(room_type_id,rateId).guarantee_type_ids ?  
            this.selectedRateDetails(room_type_id,rateId).guarantee_type_ids[0] : []
    },
    getGarantyName(room_type_id,rateId){
      let guaranteeName =  this.rwa_data.guarantee_types?.find(c=>c.id === this.getGuaranteeId(room_type_id,rateId))?.name
      return guaranteeName
    }//guarantee_type_ids
  },
}
</script>

<style lang="scss" scoped>
.policy-box {
  font-size: 14px;
    border: solid #dadada 1px;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
}
</style>
