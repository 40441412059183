<template>
  <div>
    <header id="default-header">
      <div class="home_logo" :class="{ 'has-img': business_logo }">
        <img :src="business_logo" :alt="`${hotel_name} Logo`"/>
        <div class="flexed-column justify-center hotel-name-address" >
          <div>{{ hotel_name }}</div>
          <address v-if="!mobileView" >{{ getAddress }}</address>
        </div>
      </div>
      <div v-if="mobileView" class="flexed centered-row">
        <template v-if="enableLoyaltyFlow">
          <div v-if="logged_in_user_details && logged_in_user_details.user_display_name" class="name"
               @click="showDropdown=!showDropdown"
               @mouseleave="showDropdown = false">
            <div class="user-name">
              <img style="width: 30px; height: 23px;"
                   src="../../assets/user-mobile.png" alt="">
            </div>
            <div class="dropdown" v-if="showDropdown">
              <div @click="reDirect('manageProfile')">
                <SettingsIcon class="custom-class"></SettingsIcon>
                <span>Manage Profile</span>
              </div>
              <div @click="reDirect('logOut')">
                <LogOutIcon class="custom-class"></LogOutIcon>
                <span>Log Out</span>
              </div>
            </div>
          </div>
          <div v-else>
            <el-button type="primary" @click="reDirect('login')">Login</el-button>
          </div>
        </template>
        <div class="m-l-10 m-r-10">
          <menu-icon class="menu-icon" width="29" height="29"
                     @click="showMobileMenu ? showMobileMenu = false : showMobileMenu = true">>
          </menu-icon>
          <el-drawer
            title=""
            :visible="showMobileMenu"
            :before-close="closeMenu"
            direction="ttb"
            size="100%"
            :show-close="false">
            <div class="header">
              <div class="logo">
                <img :src="business_logo" :alt="`${hotel_name} Logo`"/>
                <div class="flexed-column justify-center hotel-name-address" >
                  <div class="m-l-10">{{ hotel_name }}</div>
                </div>
              </div>
              <div class="xIcon">
                <x-icon @click="closeMenu"></x-icon>
              </div>
            </div>
            <nav class="mobile-menu flexed-column justify-center m-t-20">
              <!-- <div class="subtitle">More Options</div> -->
              <div class="menus-item" v-if="URLQueryParams.url">
                <!-- <img src="../../assets/home.svg" > -->
                <div aria-label="Go to Home Tab" @click="go_home()">{{ $t("lbl_home") }}</div>
              </div>
              <div class="menus-item">
                <!-- <img  src="../../assets/hotel_details.svg" > -->
                <div aria-label="Hotel Details" @click="openHotelDetails($event)">Hotel Details</div>
              </div>
              <div class=" menus-item pointer" v-if="!$route.name.includes('search-reservation') && hotel.website_config.enable_search_reservation" @click="gotoSearchReservation()">
                <!-- <img src="../../assets/search.svg" > -->
                <div  >Search Reservation</div>
              </div>
              <div class="menus-item">
                <div class="flexed align-center ">
                  <div class="currency-symbol"> <div>{{getCurrentSymbol}}</div></div>
                  <el-dropdown trigger="click" @command="setDefaultCurrency">
                    <div class="el-dropdown-link pointer">
                      <span class="selected-currency">{{selectedCurrency}}<i class="el-icon-arrow-down el-icon--right"></i></span>
                    </div>
                      <el-dropdown-menu  class="dropdown-menu"  slot="dropdown">
                        <div class="w-100 p-l-10 p-r-10 p-b-10 border-bottom" >
                          <el-input v-model="searchCurrency" size="medium" placeholder="Select Currency"></el-input>
                        </div>
                        <div class="el-dropdown-menu-items">
                          <el-dropdown-item v-for="(currency, index) in filteredCurrencyData" :key="index"
                          :command="currency.code">{{currency.name + ' - ' + currency.code}}</el-dropdown-item>
                        </div>
                      </el-dropdown-menu>

                  </el-dropdown>
                </div>
              </div>
              <!-- <div class="menus-item">
                <div class="flexed align-center " v-if="enable_multi_language_support">
                  <div class="currency-symbol"> <div></div></div>
                  <el-dropdown trigger="click" @command="setDefaultLanguage">
                    <div class="el-dropdown-link pointer">
                      <span>{{ getCurrentLanguage }}<i class="el-icon-arrow-down el-icon--right"></i></span>
                    </div>
                    <el-dropdown-menu slot="dropdown">
                      <div class="w-100 p-l-10 p-r-10 p-b-10 border-bottom" >
                        <el-input v-model="searchLanguage" size="medium" placeholder="Select Language"></el-input>
                      </div>
                      <div class="el-dropdown-menu-items">
                        <el-dropdown-item v-for="(language, index) in filteredLanguageData" :key="index"
                              :command="language.code">{{language.countryName}}</el-dropdown-item>
                      </div>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div> -->
            </nav>
          </el-drawer>
        </div>
      </div>
      <div class="menu" v-else>
        <nav class="other-info">
          <div class="flexed align-center " v-if="!$route.name.includes('search-reservation')  && hotel.website_config.enable_search_reservation" @click="gotoSearchReservation()">
              <img src="../../assets/search.svg" >
              <a class="pointer"  >Search Reservation</a>
          </div>
          <div class="flexed" v-if="URLQueryParams.url">
            <img src="../../assets/home.svg" alt="" >
            <a aria-label="Go to Home Tab" href="#" @click="go_home()">{{ $t("lbl_home") }}</a>
          </div>
          <div class="flexed">
            <img src="../../assets/hotel_details.svg" alt="" >
            <a aria-label="Hotel Details" href="#" @click="openHotelDetails($event)">Hotel Details</a>
          </div>

          <template>

            <div v-if="!loading && !navigatorExists" class="flexed align-center " >
              <div class="currency-symbol"> <div>{{getCurrentSymbol}}</div></div>
               <el-dropdown trigger="click" @command="setDefaultCurrency">
                <div class="el-dropdown-link pointer">
                  <span>{{selectedCurrency}}<i class="el-icon-arrow-down el-icon--right"></i></span>
                </div>
                <el-dropdown-menu slot="dropdown">
                  <div class="w-100 p-l-10 p-r-10 p-b-10 border-bottom" >
                    <el-input v-model="searchCurrency" size="medium" placeholder="Select Currency"></el-input>
                  </div>
                  <div class="el-dropdown-menu-items">
                    <el-dropdown-item v-for="(currency, index) in filteredCurrencyData" :key="index"
                          :command="currency.code">{{currency.name + ' - ' + currency.code}}</el-dropdown-item>
                  </div>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
            <div v-else>
              <!-- <div v-loading="loading" element-loading-spinner="el-icon-loading"></div> -->
              <div class="custom-loader">
                <div class="dot-container">
                  <div class="dot"></div>
                  <div class="dot"></div>
                  <div class="dot"></div>
                </div>
              </div>
            </div>

          </template>


          <template v-if="enableLoyaltyFlow">
            <div v-if="logged_in_user_details && logged_in_user_details.user_display_name" class="name"
                 @click="showDropdown=!showDropdown"
                 @mouseleave="showDropdown = false">
              <div class="user-name">
                <img src="../../assets/Profile.svg" alt="" style="padding: 0 2px; margin-right: 4px">
                <span style=" font-style: normal;font-weight: 600">{{ logged_in_user_details.user_display_name }}</span>
                <ChevronDownIcon v-if="showDropdown == false" class="caret-icon"></ChevronDownIcon>
                <ChevronUpIcon v-else class="caret-icon"></ChevronUpIcon>
              </div>
              <div class="dropdown" v-if="showDropdown">
                <div @click="reDirect('manageProfile')">
                  <SettingsIcon class="custom-class"></SettingsIcon>
                  <span>Manage Profile</span>
                </div>
                <div @click="reDirect('logOut')">
                  <LogOutIcon class="custom-class"></LogOutIcon>
                  <span>Log Out</span>
                </div>
              </div>
            </div>
            <div v-else>
              <el-button type="primary" @click="reDirect('login')">Login</el-button>
            </div>
          </template>
        </nav>
      </div>
    </header>
    <hotel-details-popup v-if="showHotelDetails && !mobileView" @close="showHotelDetails = false"/>
    <hotel-details-drawer v-if="showHotelDetails && mobileView" @close="showHotelDetails = false"/>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import HotelDetailsDrawer from './HotelDetailsDrawer.vue';
import HotelDetailsPopup from './HotelDetailsPopup.vue';
import { LogOutIcon, SettingsIcon, ChevronDownIcon, ChevronUpIcon, MenuIcon, XIcon, SearchIcon, DollarSignIcon} from 'vue-feather-icons'
import deepClone from 'clone'
import CurrencyData from '@/assets/currencies/currencies';
import CountryCurrencies from "@/assets/country-currencies/country_currencies.json"
import loading from '@/components/common/loading.vue';

export default {
  components: {
    HotelDetailsPopup,
    HotelDetailsDrawer,
    LogOutIcon,
    SettingsIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    MenuIcon,
    XIcon,
    SearchIcon,
    DollarSignIcon,
    loading
  },
   beforeMount() {
     if(navigator.geolocation) {
      this.navigatorExists = true;
      this.SET_DISABLE_SEARCH_BUTTON(true)
      navigator.geolocation.getCurrentPosition(this.successCallback, this.errorCallback)
    }else {
      console.error("Geolocation is not supported by this browser");
        this.selectedCurrency = this.hotel.currency
    }


    if (this.$route.query.url) {
      let base_url = decodeURIComponent(this.$route.query.url).split("#")[0];
      this.website_url = `${base_url}#`;
    } else if (this.$route.query.tk) {
      let decrypted = atob(this.$route.query.tk);
      let userInfo = JSON.parse(decrypted);
      let base_url = userInfo.url.split("#")[0];
      this.website_url = `${base_url}#`;
    }
  },
  mounted() {
    let element = document.getElementById("default-header");
    element.style.setProperty(
      "--hover-color",
      `${this.web_config.booking_color_id}`
    );
    this.URLQueryParams = this.$route.query
  },
  data() {
    return {
      mobile_nav: false,
      website_url: null,
      main_menus: [],
      submenus: [],
      active_menu: null,
      showSubMenu: false,
      currencyOptions: [{id: 1, name: "Us Dollars"}],
      languageOptions: [{id: "en_us", name: "EN-US"}],
      homeModel: {currencyId: 1, languageId: "en_us"},
      showHotelDetails: false,
      showDropdown: false,
      URLQueryParams: '',
      showMobileMenu: false,
      currencyData: CurrencyData,
      selectedCurrency: 'USD',
      searchCurrency: '',
      loading: false,
      navigatorExists: false
    };
  },
  computed: {
    ...mapState({
      hotel: (state) => state.property.details,
      hotel_id: (state) => state.property.details.id,
      hotel_name: (state) => state.property.details.name,
      phone_no: (state) => state.property.details.phone_no,
      email: (state) => state.property.details.email,
      address: (state) => state.property.details.address,
      business_logo: (state) => state.property.details.business_logo,
      mobileView: state => state.mobileView,
      logged_in_user_details: state => state.logged_in_user_details,
      disableSearchButton: state => state.disableSearchButton,
      current_step: state => state.current_step || {},
    }),
    filteredCurrencyData(){
      let result = this.currencyData
      if(this.searchCurrency)
        result = result.filter(r=>r.code.toUpperCase().includes(this.searchCurrency.toUpperCase()) || r.name.toUpperCase().includes(this.searchCurrency.toUpperCase()))
      return result
    },
    getCurrentSymbol(){
      return this.currencyData.find(c=>c.code === this.selectedCurrency)?.symbol
    },
    getCurrentNameAndCode(){
      return this.currencyData.find(c=>c.code === this.selectedCurrency)?.name + ' - ' + this.selectedCurrency
    },
    web_config() {
      return !!this.hotel && this.hotel.website_config;
    },
    show_menus() {
      return !this.web_config.hide_menus_on_booking_engine;
    },
    getAddress() {
      return this.hotel?.address + ', ' + this.hotel?.city + ', ' + this.hotel?.state + ', ' + this.hotel?.country + ', ' + this.hotel?.zip_code
    },
    enableLoyaltyFlow() {
      return this.hotel?.website_config?.enable_loyalty_flow
    }
  },
  methods: {
    async successCallback(position) {
      // this.loading = true
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      await this.reverseGeocode(latitude,longitude)
      // this.loading = false
    },
    errorCallback(error) {
      console.error("Error getting geolocation:", error.message);
      this.SET_DISABLE_SEARCH_BUTTON(false)
      this.loading = false
      this.navigatorExists = false
      this.selectedCurrency = this.hotel.currency || 'USD';
    },
    async reverseGeocode(latitude, longitude) {
      const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyCZXHB4jp1bELmPjHXIUDvXLKxUliitp-s`;

      try {
        this.loading = true
        const response = await fetch(apiUrl);
        const data = await response.json();


        let country;
        let countryCode;
        for (const result of data.results) {
          for (const component of result.address_components) {
            if (component.types.includes('country')) {
              country =  component.long_name;
              countryCode = component.short_name;
              // return;
            }
          }
        }
        const userCountry =  CountryCurrencies.find(item => item.country === country?.toUpperCase())
        this.SET_DISABLE_SEARCH_BUTTON(false)
        if((this.current_step?.name === 'Select Dates' && this.disableSearchButton === false) || this.$route.query.skip_search) {
          this.selectedCurrency = userCountry?.code || this.hotel.currency || 'USD'
          this.hotel.currency =  userCountry?.code || this.hotel.currency || 'USD'
          // this.hotel.country = countryCode || this.hotel.country
          this.SET_COUNTRY_CODE(countryCode)
        }
        else {
          this.selectedCurrency = this.hotel.currency || 'USD'
          this.hotel.currency = this.hotel.currency || 'USD'
        }
        this.loading = false
        this.navigatorExists = false
      } catch (error) {
        console.error("Error in reverse geocoding:", error);
        this.SET_DISABLE_SEARCH_BUTTON(false)
        this.loading = false
      }
  },
    setDefaultCurrency(code){
      let hotelData = deepClone(this.hotel)
      this.selectedCurrency = code
      hotelData.currency = code
      this.SET_HOTEL_DETAILS(hotelData);
      this.RESET_STATE()
      if(this.$route.name === 'booking-search') this.SET_CHILD_COMPONENT_KEY()
      else if(this.$route.name.startsWith('booking-')) this.CHANGE_STEP('search');
    },
    gotoSearchReservation(){
      this.resetStateAndGotoResrvation('search-reservation-wrapper')
    },
    closeMenu() {
      this.showMobileMenu = false
    },
    openHotelDetails(event) {
      event.preventDefault()
      this.showHotelDetails = true
    },
    ...mapMutations({
      SET_ERROR: "SET_ERROR",
      CHANGE_STEP: 'CHANGE_STEP',
      SET_HOTEL_DETAILS: 'property/SET_HOTEL_DETAILS',
      SET_CHILD_COMPONENT_KEY: 'SET_CHILD_COMPONENT_KEY',
      SET_DISABLE_SEARCH_BUTTON:'SET_DISABLE_SEARCH_BUTTON',
      RESET_STATE: 'RESET_STATE',
      SET_COUNTRY_CODE: 'SET_COUNTRY_CODE'
    }),
    ...mapActions({
      resetStateAndGotoResrvation:'resetStateAndGotoResrvation'
    }),
    sanitize(url) {
      return url.replace(/ /g, "%20");
    },
    go_home() {
      window.location.replace(this.URLQueryParams.url);
      this.SET_ERROR(false);
    },
    reDirect(param) {
      console.log(this.logged_in_user_details);
      if (param === 'logOut') {
        this.logged_in_user_details?.logout_url && window.location.replace(this.logged_in_user_details.logout_url);
      }
      if (param === 'login') {
        this.logged_in_user_details?.login_url && window.location.replace(this.logged_in_user_details.login_url);
      }
      if (param === 'manageProfile') {
        this.logged_in_user_details?.profile_management_url && window.location.replace(this.logged_in_user_details.profile_management_url)
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~mixins";
@import "~css_vars";

.menus-item {
  padding: 24px 24px;
  z-index: 1001;
  // border-radius: 4px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  margin: 0 20px;
  // height: 40px;
  img{
    height: 25px;
  }

  div {
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 500;
  }

  .selected-currency {
    color: #000;
  }

}

.subtitle {
  padding: 20px 30px;
  font-weight: bold;
}

.logo {
  position: absolute;
  top: 0px;
  left: 15px;
  display: flex;
  flex-direction: row;
  font-weight: bold;
  font-size: 16px;
}


.xIcon {
  position: absolute;
  top: 19px;
  right: 19px;
}

header {
  --hover-color: #fff;
}

header {
  background-color: white;
  border-bottom: #eaeaea solid 1px;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1px;
  color: black;
  font-size: 14px !important;
  padding: 0px 15px;

  .home_logo {
    display: flex;
    align-items: center;
    height: 100%;

    img {
      background-size: cover;
      height: 70%;
      max-width: 60px;
      cursor: default;
      margin: 0;
      margin-right: 10px;

      img {
        opacity: 0;
      }
    }

    .hotel-name-address {
      margin-left: 5px;

      div {
        font-size: 16px;
        font-weight: bold;
      }

      address {
        font-style: normal;
      }
    }
  }

  .el-select {
    width: 110px;
  }

  .menu {
    flex: 1;
    display: flex;
    align-self: stretch;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-end;

    .other-info {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-right: 15px;
      font-style: normal;
      gap: 20px;

      a, span {
         color: #000;
        font-weight: 600;
      }

      a {
        display: flex;
        align-items: center;
        text-decoration: none;
      }
      img {
          height: 25px;
        }
    }

    .mobile-menu {
      display: none;

      svg {
        stroke: white;
      }

      nav {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-right: 60px;

        a {
          text-transform: uppercase;
          padding: 10px 4px;
          margin-right: 1rem;
          color: black;
          font-weight: 600;
          cursor: pointer;
          text-decoration: none;

          &:last-child {
            margin-right: 0px;
          }

          &:hover {
            color: var(--hover-color);
          }
        }
      }
    }
  }
}

@include media("<=tablet") {
  header {
    height: 60px;

    img {
      width: 48px;
    }

    .home_logo {
      h1 {
      }
    }

    .menu {
      .other-info {
        display: none;
      }

      .mobile-menu {
        display: block;
        position: absolute;
        top: 22px;
        right: 80px;
        z-index: 100;
      }

      nav#menuNav {
        display: none !important;
        margin-top: 0;
        height: 100vh;
      }

      nav#menuNav.mobile-active {
        display: flex !important;
        overflow-y: auto;
        flex-wrap: nowrap;

        div,
        a {
          &:hover {
            color: black;
          }
        }

        #close-mobile-menu {
          display: block;
          position: absolute;
          top: 10px;
          left: 10px;
          cursor: pointer;
        }
      }

      nav {
        position: fixed;
        box-sizing: border-box;
        flex-direction: column;
        text-align: center;
        height: 100%;
        width: 100%;
        padding: 3rem;
        background-color: white;
        z-index: 99999;
        grid-column: full;
        top: 0;
        left: 0;
        bottom: 0;

        a {
          color: #888888;
          border-bottom: #c1c1c1;
          margin: 0;
        }
      }
    }
  }

  .el-dropdown-menu {
    // margin-top: -20px;
    width: 100%;
    left: 0px !important;
  }
}

.name {
  position: relative;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 10px;

  .user-name {
    color: #000;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .user-icon {
      margin-right: 5px;
    }

    .caret-icon {
      margin-left: 5px;
    }
  }

  .dropdown {
    position: absolute;
    border: 1px solid #eaeaea;
    border-radius: 0 0 4px 4px;
    top: 40px;
    color: black;
    right: 0;
    background-color: #fff;
    z-index: 99;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 160px;

    div {
      padding: 10px;
      width: 100%;
      display: flex;
      align-items: center;

      .custom-class {
        padding: 0 5px;
      }

      &:hover {
        background: $hk-primary-color;
        color: white;
      }
    }
  }
}

.el-dropdown-menu-items {
    overflow: scroll; //
    max-height: 400px; //
    overflow-x: hidden;
}
.el-dropdown-menu {
    overflow: hidden;
  }
.currency-symbol{
  font-size:20px;
  font-weight:240;
  margin-right: 5px;
  vertical-align: middle;
  margin-bottom: 1px;
}

//Loader

.custom-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* Adjust as needed */
}

.dot-container {
  display: flex;
}

.dot {
  width: 4px;
  height: 4px;
  background-color: #000;
  border-radius: 50%;
  margin: 0 2px;
  animation: pulse 1s infinite alternate;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.5);
  }
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

</style>
