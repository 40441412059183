<template>
  <div id="booking-wizard">
    <div class="wizard-header">
      <slot name="wizard-header"></slot>
    </div>
    <div aria-hidden="true" class="active-hardware" v-if="currentStep.name !== null && !mobileView">
      <ul id="tab_steps">
        <template v-for="(step, key) in steps">
          <li v-if="step.name" :key="key" class="wizard-item"
              :class="{'active': step.name === currentStep.name,
                                 'visited': step.visited === true,
                                 'completed': step.visited === true && step.index <= currentStep.index }">

            <span>{{ step.name }}</span>
          </li>
        </template>
      </ul>
    </div>
    <div class="tab-content">
      <div class="loader" v-if="wizardLoading">
        <loading></loading>
      </div>
      <div v-show="!wizardLoading" class="flexed justify-center">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
import {LoaderIcon} from 'vue-feather-icons';
import {mapState, mapMutations} from 'vuex';
import CommonUtils from '@/utils/index.js'

export default {
  props: ['steps', 'currentStep', 'wizardLoading'],
  beforeMount() {
    this.steps.search.label = this.$i18n.t('lbl_search')
    this.steps.rooms.label = this.$i18n.t('lbl_rooms')
    // this.steps.summary.label = this.$i18n.t('lbl_summary')
    this.steps.information.label = this.$i18n.t('lbl_information')
    this.steps.confirmation.label = this.$i18n.t('lbl_confirmation')

    if (this.steps.hasOwnProperty('payment')) {
      this.steps.payment.label = this.$i18n.t('lbl_payment')
    }
  },
  data: () => ({}),
  mounted() {
    let color_code = this.hotel.website_config.booking_color_id

    if (color_code) {
      let headerFooterColor = CommonUtils.hexToRgb(color_code)
      this.SET_BOOKING_COLOR(headerFooterColor)
    } else {
      let headerFooterColor = CommonUtils.hexToRgb('#19a7f3')
      this.SET_BOOKING_COLOR(headerFooterColor)
    }
  },
  computed: {
    ...mapState({
      booking_color: state => state.booking_color,
      profile: state => state.profile,
      mobileView: state => state.mobileView
    }),
    fromWebsite() {
      return this.$route.query.hasOwnProperty('tk')
    },
    hotel() {
      return this.$store.state.property.details;
    },
    webUrl() {
      if (this.hotel.website.slice(0, 3) === 'www') {
        return 'http://' + this.hotel.website;
      }
      return this.hotel.website;
    }
  },
  methods: {
    ...mapMutations({
      SET_BOOKING_COLOR: 'SET_BOOKING_COLOR'
    }),
    showStep(step_name) {
      if (step_name !== 'payment') {
        return true
      } else {
        return this.profile && this.profile.restrictions.booking_profile.restricted_guarantee_types.length > 0
      }
    }
  },
  components: {
    loading: LoaderIcon
  },
};

</script>
<style lang="scss">
@import "~css_vars";
@import '~mixins';

#booking-wizard {
  overflow-x: hidden;
}

.active-hardware {
  -webkit-transform: translateZ(0);
}

.logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  img {
    margin-top: 0.8rem;
    margin-bottom: .5rem;
    width: 70px;
  }

  h4 {
    margin: 0 0 0.8rem 0;
  }
}

ul#tab_steps {
  display: flex;
  list-style: none;
  background-color: whitesmoke;
  padding: 0;
  margin: 0;

  .wizard-item {
    flex: 1;
    text-transform: uppercase;
    text-align: center;
    padding: .7rem 0;
    z-index: 1;
    font-size: .8rem;
    transition: transform .2s ease .1s;
    color: $lightgrey;
  }
}

.tab-content {
  .loader {

  }

  min-height: 80vh;
  padding: 2rem 3rem 5rem 3rem;
  display: flex;
  justify-content: center;
  background-color: white;

  > div {
    flex: 1;
  }
}

.navigation {
  position: fixed;
  width: 100vw;
  padding: .7rem 2rem;
  bottom: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  background-color: white; //border-top: solid 1px #f4f4f4;
  box-shadow: 0px -3px 5px -5px black;
  z-index: 30;

  button {
    touch-action: manipulation;
    text-transform: uppercase;
    border: none;
    min-width: 140px;
    padding: 12px 30px;
    border-radius: 3px;
    position: relative;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    will-change: box-shadow, transform;
    transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1), background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);

    &.next {
      background-color: $blue;
      margin-left: auto;
      color: white;
      box-shadow: 0 2px 2px 0 rgba($blue, 0.14), 0 3px 1px -2px rgba($blue, 0.2), 0 1px 5px 0 rgba($blue, 0.12);

      &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
      }
    }
  }
}

.navigation > button:focus {
  box-shadow: 0 0 0 2px #008ad4 !important;
}

@include media('>tablet') {
  #tab_steps {
    transform: none !important;
  }
}

@include media('<=tablet') {
  ul#tab_steps {
    transition: transform .3s ease-out .1s;

    li {
      display: flex;
      justify-content: center;
      width: 100vw;
      min-width: 100vw;

      &.active {
        position: relative;
        display: flex;
        box-shadow: 0 16px 26px -10px rgba($hk-primary-color, 0.16), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba($hk-primary-color, 0.03) !important;
      }

      span {
        height: 20px;
      }
    }

    select,
    textarea,
    input {
      font-size: 16px !important;
    }
  }
  .navigation {
    padding: 1rem .8rem 1.4rem .8rem;
  }
  .tab-content {
    // padding: 1.5rem 1rem 8rem 1rem;
    padding: 0;
  }
}

ul#tab_steps li.active {
  background-color: $hk-primary-color;
  color: #fff;
  box-shadow: 0 16px 26px -10px $hk-primary-color, 0 4px 25px 0 rgba(0, 0, 0, .12), 0 8px 10px -5px $hk-primary-color !important;
  transform: scale(1);
  border-radius: 2px;
}

@include media('<=phone') {
  .logo {
    margin: 15px 0px 0px 0px;
    padding: 0px;
    font-size: 70%;

    img {
      width: 40px;
    }
  }
}

a.logo {
  text-decoration: none;
  color: inherit;
}

.hotelName {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */

  .active-hardware {
    margin-top: 130px;
  }

}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */

  .active-hardware {
    margin-top: 130px;
  }

}

@include media('<=tablet') {
  .active-hardware {
    #tab-steps {
      transform: translateX(0) !important;
    }
  }
  #tab_steps {
    .wizard-item {
      &:not(.active) {
        display: none !important;
      }
    }
  }
}

</style>
