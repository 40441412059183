<template>
    <div class="packages-main">
      <div class="flexed justify-center align-center" v-if="loading">
        <loading></loading>
      </div>
    <div v-else>
      <h3>Select Packages </h3>
      <div class="packages-container">
        <PackageItem v-for="item in packages"
                     :package_data="item"
                     :key="item.id"
                     @selectPackage="addSelectedPackage"
                     @removePackage="removeSelectedPackage"
                     :data="pckgData(item.id)"
        ></PackageItem>
      </div>
      <div class="navigation">
        <button @click="previousStep()" >{{ $t('lbl_previous') }}</button>
        <el-button class="next" type="primary" :loading="buttonLoading"
                   @click="nextStep()">
          {{ $t('lbl_next') }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapMutations ,mapState} from "vuex";
  import PackageItem from "./PackageItem";
  import {generateUUID} from "@/services/utilities.service";
  import apiCallsEventsService from "../../../../services/events/api-calls-events.service";
  import loading from '@/components/common/loading.vue';
  import dayjs from "dayjs";

  export default {
    name: 'SelectPackage',
    components: {PackageItem, loading},
    data() {
      return {
        loading: false,
        buttonLoading: false,
        packages: null,
        sub_event_id: null
      }
    },
    async beforeMount() {
      this.loading = true
      let uniqueId = generateUUID()
      let payload = {
        "canRemove": true,
        "sub_events": [{
          "end_date": dayjs(this.eventTime.end_time).toISOString(),
          "date": dayjs(this.eventTime.start_time).format("YYYY-MM-DD"),
          "details": {
            "instructions": [],
            "event_setup_type_id": null,
            "addons": [],
            "sub_event_id": uniqueId,
            "name": "WebBooking",
            "selected_space_price_plan_id": this.selectedEvent?.space?.calendars?.[0]?.price_plans?.[this.selectedEvent.calendar_index]?.id,
            "space_arrangement_id": this.selectedEvent.event_setup_type_id || null,
            "number_of_persons": this.eventTime.capacity,
            "remarks": null
          },
          "id": uniqueId,
          "space_id": this.selectedEvent.space.space_id,
          "start_date": dayjs(this.eventTime.start_time).toISOString()
        }]
      };


      try{
        let res = await apiCallsEventsService.post(`${this.propertyDetails.id}/web/new-event/${this.eventConfig.event_id}/sub-events-setup/${this.eventConfig.session_id}`, payload, {cache:false}, 'eventManagementApi')

        this.packages = res.data.client_visible_data.packages?.[0].packages

        this.sub_event_id = res.data.client_visible_data.packages?.[0].sub_event_id
        this.loading = false
      }catch (e){}

    },
    methods: {
      ...mapMutations({
        ADD_SELECTED_PACKAGE: "events/ADD_SELECTED_PACKAGE",
        SET_SELECTED_PACKAGES: "events/SET_SELECTED_PACKAGES",
        SET_SUMMARY_DETAILS: 'events/SET_SUMMARY_DETAILS'
      }),
      async previousStep() {
        this.$router.push({name: 'select-space'}).catch(() => {
        })
      },
      async nextStep() {
        this.buttonLoading = true
        let payload = []

        if(this.selected_packages.length > 0)
          this.selected_packages.forEach((selectedPackage) => {
            let newItem = {
              "id": generateUUID(), // UUID
              "package_id": selectedPackage.package_data.id, //id of selected package
              "sub_event_id": this.sub_event_id, // from response packages[0].sub_event_id
              "package_price_plan_id": selectedPackage.package_data.calendars?.[0].price_plan_id,// selected package.calendars[0].price_plan_id
              "number_of_persons": this.eventTime.capacity, //From Step 1
              "remarks": null,
              "quantity": selectedPackage.capacity   // Stepper input
            }
            payload.push(newItem)
          })

        try{

         let result = await apiCallsEventsService.post(`${this.propertyDetails.id}/web/new-event/${this.eventConfig.event_id}/package-selection/${this.eventConfig.session_id}`, payload, {cache:false}, 'eventManagementApi')
          // console.log(result.data)
          this.SET_SUMMARY_DETAILS(result.data.client_visible_data)

          //POSTMAN
          // let url = 'https://43ead1ba-d6f0-4f28-bf08-496190b0d09d.mock.pstmn.io/package-selection'
          // await apiCallsEventsService.postTest(url, payload)

          this.$router.push({name: 'guest-information'}).catch(() => {})
        }catch (e){
          this.loading = false
          this.$notify.error({
            title: this.$t('lbl_error'),
            message: 'Something went wrong'
          })
        }
      },
      addSelectedPackage(item){
        this.ADD_SELECTED_PACKAGE(item)
      },
      removeSelectedPackage(id){
        let pckgs = this.selected_packages.filter((pckg)=>pckg.package_data.id != id)
        this.SET_SELECTED_PACKAGES(pckgs)
      },
      pckgData(id){
        let pckg = this.selected_packages.find((pckg) => pckg.package_data.id === id)
          return pckg
      }
    },
    computed:{
      ...mapState({
        propertyDetails: state => state.events.propertyDetails,
        eventConfig: state => state.events.eventConfig,
        selectedEvent : state => state.events.selectedEvent,
        eventTime: state => state.events.eventTime,
        selected_packages: state => state.events.selected_packages
      }),
    }
  }
</script>

<style lang="scss" scoped>
@import "~css_vars";
@import '~mixins';
@import "@/assets/sass/config/mixins.scss";

.info{
  p{
    color: #4E4E4E;
    display: inline;
  }
}
.packages-main{
  margin: 30px 250px 60px 80px;
  padding-bottom: 40px;
  .packages-container{
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
  }
}

.navigation button{
   border: 1px solid $lightgrey-2;
}

@include media("<=tablet"){
  .packages-main {
    margin: calc(50vw - 149px);
  }
}
</style>
