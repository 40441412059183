<template>
  <div class="filters-wrapper">
    <div class="flexed filters-display">
      <div v-if="!mobileView" class="flexed px-10 pointer unselectable" style="font-weight: 400"
           @click="onDisplayByChange(false)">
        <div class="triangle-sort flexed-column" style="height: 20px;">
          <TriangleIcon v-if="displayBy === 'byRates'"/>
          <TriangleIcon fill v-else/>
          <TriangleIcon v-if="displayBy === 'byRooms'" style="transform: rotate(180deg)"/>
          <TriangleIcon v-else fill style="transform: rotate(180deg)"/>
        </div>
        Display:
        <div class="px-10 filter" style="width: 85px;">{{ displayByObject.find(d => d.id === displayBy).name }}</div>
      </div>
      <div class="flexed px-10 pointer unselectable" style="font-weight: 400" @click="priceSortFilter(false)">
        <div class="triangle-sort flexed-column pointer unselectable" style="height: 20px;"
        >
          <TriangleIcon v-if="sortByPrice === 'asc'"/>
          <TriangleIcon fill v-else/>
          <TriangleIcon v-if="sortByPrice === 'desc'" style="transform: rotate(180deg)"/>
          <TriangleIcon v-else fill style="transform: rotate(180deg)"/>
        </div>
        Price:
        <div class="px-10 filter">{{ sortByPriceObject.find(s => s.id === sortByPrice).name }}</div>
      </div>
      <div class="pointer px-10 flexed align-center"
           style="font-weight: 400"
           @click="showFilters = !showFilters">
              Show Filters<el-badge class="mark" :value="countAppliedFilters" />
        <ChevronDownIcon/>
      </div>
    </div>
    <Filters
      v-if="showFilters"
      :show-filters="showFilters"
      @closeFilters="closeFilters"
      @onDisplayByChange="onDisplayByChange"
      @applyFilters="showFilters = false"
      :roomIndex="roomIndex"
    />
  </div>
</template>

<script>
import {ChevronDownIcon, TriangleIcon} from 'vue-feather-icons'
import {mapMutations, mapState} from "vuex";
import Filters from "./Filters";
import deepClone from 'clone'

export default {
  name: "SortByFilters",
  props: ['roomIndex'],
  components: {
    Filters,
    ChevronDownIcon,
    TriangleIcon
  },
  computed: {
    ...mapState({
      mobileView: state => state.mobileView,
      allFilters: state => state.allFilters,
    }),
    countAppliedFilters() {
      return (this.allFilters[this.roomIndex]?.bedType?.length || 0) + (this.allFilters[this.roomIndex]?.roomTypes?.length || 0) + (this.allFilters[this.roomIndex]?.features?.length || 0)
      + ((this.allFilters[this.roomIndex]?.bedCount > 1) ? 1 : 0) + (this.allFilters[this.roomIndex]?.accessible || 0) + (this.allFilters[this.roomIndex]?.smoking || 0)
    }
  },
  data() {
    return {
      displayBy: 'byRooms',
      rateType: '',
      showFilters: false,
      displayByObject: [{id: 'byRooms', name: 'By Rooms'}, {id: 'byRates', name: 'By Rates'}],
      sortByPriceObject: [{id: 'asc', name: 'Low to High'}, {id: 'desc', name: 'High to Low'}],
      sortByPrice: 'asc',
    }
  },
  methods: {
    ...mapMutations({
      SET_DISPLAY_SORT_FILTERS: 'SET_DISPLAY_SORT_FILTERS',
      SET_ALL_FILTERS: 'SET_ALL_FILTERS',
    }),
    onDisplayByChange(value) {
      if (!value) {
        this.displayBy = this.displayBy === 'byRates' ? 'byRooms' : 'byRates'
      } else {
        this.displayBy = value;
      }
      let allFilters = this.initializeAllFiltersObject()
      allFilters[this.roomIndex].displayBy = this.displayBy
      this.SET_ALL_FILTERS(allFilters)
    },
    initializeAllFiltersObject() {
      let allFilters = deepClone(this.allFilters)
      if (!allFilters[this.roomIndex]) {
        allFilters[this.roomIndex] = {}
      }
      return allFilters
    },
    priceSortFilter(value) {
      if (!value) {
        this.sortByPrice = this.sortByPrice === 'asc' ? 'desc' : 'asc'
      } else {
        this.sortByPrice = value
      }
      let allFilters = this.initializeAllFiltersObject()
      allFilters[this.roomIndex].sortByPrice = this.sortByPrice
      this.SET_ALL_FILTERS(allFilters)
    },
    closeFilters() {
      this.showFilters = false
      this.displayBy = 'byRooms'
      this.rateType = ''
    },
  },
  beforeMount() {
    if (this.allFilters[this.roomIndex]?.displayBy)
      this.displayBy = this.allFilters[this.roomIndex]?.displayBy

  },
}
</script>

<style scoped lang="scss">
@import "../../../../../assets/sass/config/mixins";
@import "../../../../../assets/sass/config/variables";
::v-deep .el-badge__content{
  height: auto;
}
@include media('>=tablet') {
  .filters-wrapper {
    .filters-display {
      border: solid 1px #dadada;
      border-radius: 5px;
      padding: 12px;
      margin-top: 10px;
      background-color: whitesmoke;
      font-weight: 400;
      font-size: 14px;
      justify-content: end;
      align-items: center;
      .filter {
        font-weight: 300;
      }
    }
  }

  .all-filters {
    border: solid 1px #efeded;
    border-radius: 5px;
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    font-weight: 400;
    font-size: 16px;
    align-items: center;

    .filter-box {
      padding: 10px;
      background-color: whitesmoke;
      width: 49%;
    }

  }

  .vertical-divider {
    height: unset !important;
  }

  .divider {
    margin: 15px 0;
  }
}

@include media('<=tablet') {
  ::v-deep .el-radio__label {
    font-size: 16px;
  }
  .filters-wrapper {
    .filters-display {
      border: solid 1px #efeded;
      border-radius: 5px;
      margin: 15px 25px 0;
      padding: 7px;
      background-color: whitesmoke;
      font-weight: 400;
      font-size: 14px;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: space-around;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      height: 55px !important;

      .filter {
        font-weight: 300;

      }
    }
  }
}
</style>
