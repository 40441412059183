<template>
  <div class="rates-main-wrapper" :key="'rates-main-wrapper' + mobileView">
    <div class="rates-wrapper m-b-20" v-for="(rate, rateIndex) in getDistinctRateCodes" :key="rateIndex">
      <div class="flexed" >
        <span class="flex_1 title" >
           {{ rate.display_code ? rate.display_code : rate.name}}
           <el-popover  v-if="rate.additional_details"  placement="top-start"  width="500" :trigger="mobileView ? 'click' : 'hover'" :close-delay="100" >
            <div class="popover-message">
              <div v-if="rate.package_description" style="margin-bottom: 20px;" v-html="renderMarkdown(rate.package_description)" ></div>
              <div v-html="renderMarkdown(rate.additional_details)"></div>
            </div>
            <span slot="reference">
              <i class="el-icon-info" ></i>
            </span>
          </el-popover>

        <div v-if="applicablePackage && applicablePackage.id === rate.package_id" class="preferred-tag">Preferred Package</div>
        <!-- <div v-if="group_rate_plan && group_rate_plan === rate.id" class="m-l-10 preferred-tag">{{$t("lbl_preferred_group_rate_plan")}}</div> -->
        </span>
        <span class="flex_2 title" v-if="!mobileView">Available Rooms</span>

      </div>
      <div class=" title m-t-10" v-if="mobileView">Available Rooms</div>
      <div class="rates-room-wrapper">
        <div class="m-b-10 border-bottom p-b-10 wrapper-item"
             v-for="(room, rIndex) in getRoomsByRate(rate)" :key="rIndex">
          <div class="carousel-container">
            <image-carousel :images="roomImages(room)" class="flex_1"/>
          </div>
          <div class=" justify-between flex_2"
               :class="[mobileView ? 'flexed-wrap' : 'flexed',mobileView ? 'gap-10' : 'gap-40']">
            <div class="flexed-column font-size-14" :style="{width: !mobileView ? '80%' : '100%'}">
              <div class="sub-title flexed-wrap gap-10 align-center" v-if="!mobileView"> {{ room.room_type_name }}
                <span v-if="preferredRoom(room)"
                      class="preferred-tag">Selected Room</span>
              </div>
              <room-amenities class="amenities-mobile" :room="room"></room-amenities>
            <div class="desc m-b-10" v-html="description(room)">
             
              </div>
              <div class="m-t-10" v-if="packageChargeTypes.length > 0">
                <div class="flexed justify-between" v-for="(item, index) in packageChargeTypes" :key="index">
                  <div>{{ item.name }}</div>
                  <div>{{ packageCharges(item.charges) | currency }}</div>
                </div>
              </div>
            </div>
            <div class="flexed-column" :class="mobileView ? 'w-100' : ''">

              <div class="flexed justify-between m-t-20">
                <span class="sub-title" v-if="mobileView"> {{ room.room_type_name }}  </span>
                <div v-if="ifRateCodeParamIsGoogle" class="flexed-column justify-end text-right" style="width: 145px;" :class="{'justify-end': mobileView}">
                  <div class="price sub-title">{{ getRatePriceSummary(rate, room).total_amount_after_tax | currency  }}</div>
                  <div class="small-font">Including Taxes and Fees</div>
                </div>
                <div v-else-if="getRatePriceSummary(rate, room).total_points && enable_book_with_points" class="flexed-column justify-end text-right" style="width: 135px;" :class="{'justify-end': mobileView}">
                  <div class="price sub-title">
                    {{ getRatePriceSummary(rate, room).total_points | formatBigNumber}} Points
                  </div>
                </div>
                <div v-else class="flexed-column" style="width: 135px;">
                  <div v-if="discount_applied" class="flexed align-center text-right"
                       :class="{'justify-end': mobileView}">
                    <div class="discount-price  m-r-5" :class="{'flex_1': !mobileView}">
                      {{ (getRatePriceSummary(rate, room).avg_base_amount_before_tax ? getRatePriceSummary(rate, room).avg_base_amount_before_tax : getRatePriceSummary(rate, room).avg_amount_before_tax + (getRatePriceSummary(rate, room).avg_inclusive_tax_amount || 0)) | currency }}
                    </div>
                    <div class="price sub-title">
                      {{ (getRatePriceSummary(rate, room).avg_amount_after_discount ? getRatePriceSummary(rate, room).avg_amount_after_discount : getRatePriceSummary(rate, room).avg_amount_before_tax + (getRatePriceSummary(rate, room).avg_inclusive_tax_amount || 0)) | currency }}
                    </div>
                  </div>
                  <div v-else class="sub-title flexed-end w-100 align-center">
<!--                    {{  getRatePriceSummary(rate, room).avg_amount_before_tax | currency }}-->
                    {{ (getRatePriceSummary(rate, room).avg_amount_before_tax + (getRatePriceSummary(rate, room).avg_inclusive_tax_amount || 0)) | currency}}
                  </div>
                  <div class="small-font text-right">{{ $t('lbl_per_night_avg')  }}</div>
                  <div class="small-font" v-if="getRatePriceSummary(rate, room).avg_inclusive_tax_amount === 0 || getRatePriceSummary(rate, room).avg_inclusive_tax_amount === null || !getRatePriceSummary(rate, room).avg_inclusive_tax_amount">Excluding Taxes & Fees</div>
                  <div v-else class="small-font" style="text-align: right">Including Taxes and Fees</div>
                </div>
              </div>
              <div class="m-t-20" style="min-width: 148px">
                <el-button
                  v-if="showRemoveBtn(room, rate, rIndex, rateIndex)  && !bookingInProgress"
                  type="danger"
                  plain
                  :style="mobileBtn"
                  style="width: 100%"
                  @click="removeRoom(room, rate, rIndex, rateIndex)"
                >Remove
                </el-button>
                <el-button
                  v-else
                  type="primary"
                  :style="mobileBtn"
                  style="width: 100%"
                  @click="bookRoom(room, rate, rIndex, rateIndex, 'book_room')"
                  :disabled="bookingInProgress"
                  :loading="selectedData && selectedData.roomIndex === rIndex && selectedData.rateIndex === rateIndex && bookingInProgress && !booking_room_unit"
                >Book Room
                </el-button>
                <template v-if="enable_room_unit_booking">
                  <div class="or-divider">
                    <span>OR</span>
                  </div>
                  <el-button
                    v-if="showRemoveBtn(room, rate, rIndex, rateIndex)  && !bookingInProgress"
                    type="danger"
                    plain
                    :style="mobileBtn"
                    style="width: 100%"
                    @click="removeRoom(room, rate, rIndex, rateIndex)"
                  >Remove
                  </el-button>
                  <el-button
                    v-else
                    type="primary"
                    :style="mobileBtn"
                    style="width: 100%"
                    @click="bookRoom(room, rate, rIndex, rateIndex, 'book_room_unit')"
                    :disabled="bookingInProgress"
                    :loading="selectedData && selectedData.roomIndex === rIndex && selectedData.rateIndex === rateIndex && bookingInProgress && booking_room_unit"
                  >Book Room Unit
                  </el-button>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <BookRoomUnit v-if="showRoomUnitModal" @close="closeRoomUnitModal" :selectedRoomPayload="selectedRoomPayload"/>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import { InfoIcon } from "vue-feather-icons";
import CommonUtils from "@/utils";
import BookRoomUnit from "./BookRoomUnit.vue";
import ImageCarousel from '../component/ImageCarousel.vue';
import RoomAmenities from "@/views/booking-v2/component/RoomAmenities.vue";
import {marked} from 'marked'

export default {
  name: "Rates",
  props: ["getFilteredRooms", "roomTypes", "packageChargeTypes", "roomIndex", 'selectedData', 'bookingInProgress'],
  components: { InfoIcon, BookRoomUnit, ImageCarousel, RoomAmenities},
  data() {
    return {
      rooms: [],
      descMaxLength: 202,
      showRoomUnitModal: false,
      selectedRoomPayload: {},
      booking_room_unit: false
    }
  },
  computed: {
    ...mapState({
      mobileView: state => state.mobileView,
      sessions: state => state.profile.sessions,
      discount_applied: state => state.discount_applied,
      selected_room_details: state => state.selected_room_details,
      rate_codes: state => state.rate_codes,
      allFilters: state => state.allFilters,
      applicablePackage: state => state.applicablePackage,
      rwa_data: (state) => state.rwa_data,
      enable_book_with_points: state => state.enable_book_with_points,
      enable_room_unit_booking: state => state.profile.restrictions.booking_profile.allow_room_unit_booking
    }),
    ifRateCodeParamIsGoogle(){
      return this.$route.query?.rate_code === 'GOOGLE'
    },
    group_rate_plan() {
      return this.rwa_data?.group_block?.group_rate_plan;
    },
    mobileBtn() {
      return this.mobileView ? {'height': '50px'} : ''
    },
    getDistinctRateCodes(){
      let result = []
      this.getFilteredRooms.forEach(rooms => {
        rooms?.rate_codes?.forEach(rateCode => {
          if(rateCode.hasOwnProperty('package_id')){
            if(!result.some(r=>r.package_id === rateCode.package_id && r.id === rateCode.id)){
              result.push(rateCode)
              console.log({rateCode})

            }
          } else{
            if(!result.some(r=>r.id === rateCode.id && !r.hasOwnProperty('package_id'))){
              if(this.getRateCode(rateCode.id)) result.push({...this.getRateCode(rateCode.id), type:'rateCode'})
              console.log(this.getRateCode(rateCode.id))

            }
          }
        });
      });
      this.getRateCode(result)
      return result
    }
  },
  beforeMount() {
  },
  methods: {
    closePopover(id) {
      // console.log(document.getElementsByClassName("el-popper"))
      //   var divsToHide = document.getElementsByClassName("el-popper"); //divsToHide is an array
      // for(var i = 0; i < divsToHide.length; i++){
      //   console.log(divsToHide[i].style.display)
      //   //  if (divsToHide[i].style.display != 'none') divsToHide[i].style.display = "none";
      // }
      // console.log(document.getElementById(id))
    },
    getRateCode(id) {
      if(this.rate_codes[this.roomIndex]?.find(rt => rt.id == id)) return this.rate_codes[this.roomIndex]?.find(rt => rt.id == id)
      else  return null
    },
    getRatePriceSummary(rate, room) {
      if(rate.hasOwnProperty('package_id'))
        return room.rate_codes.find(rt => rt.package_id === rate.package_id && rt.id == rate.id).price_summary
      else
        return room.rate_codes.find(rt => rt.id === rate.id && !rt.package_id).price_summary
    },
    description(room) {
      let description = `${this.roomDetails(room)?.description}<br/><br/>${this.roomDetails(room)?.additional_details}`
      return  description
    },
    showRemoveBtn(room, rate) {
      let currentRoom = this.selected_room_details.find(room => room.room_no === this.roomIndex)
      // return currentRoom?.room_type_id === room.room_type_id && currentRoom?.rate_code_id === rate.id
      return currentRoom?.hasOwnProperty('package_id') ?
        currentRoom?.room_type_id === room.room_type_id && currentRoom?.rate_code_id === rate.id && currentRoom.package_id === rate?.package_id :
        currentRoom?.room_type_id === room.room_type_id &&
        currentRoom?.rate_code_id === rate.id &&
        !rate.hasOwnProperty('package_id')
    },
    preferredRoom(room) {
      if (this.$route.query.hasOwnProperty('rn_code')) {
        return this.$route.query.rn_code === this.roomDetails(room).code
      }
    },
    removeRoom(room) {
      this.$emit('removeRoom', room)
    },
    bookRoom(room, rate, roomIndex, rateIndex, source) {
      if(source === "book_room_unit" && this.enable_room_unit_booking) {
        this.booking_room_unit = true // required for the loader

        this.selectedRoomPayload = {room, rate, activeTab: this.roomIndex, rateIndex}
        this.showRoomUnitModal = true
      }
      else {
        this.booking_room_unit = false // required for the loader
        this.goNext({room, rate, roomIndex, rateIndex})
      }
    },
    closeRoomUnitModal(payload){
      // if there was any room selected, its id will come as payload, otherwise we just close the modal
      if(payload){
        this.showRoomUnitModal = false
        this.selectedRoomPayload.room.room_id = payload.room_id
        this.selectedRoomPayload.room.room_unit_number = payload.room_unit_number
        this.goNext(this.selectedRoomPayload)
      }

      this.showRoomUnitModal = false
      this.selectedRoomPayload = {}
    },
    goNext({room, rate, roomIndex, rateIndex, room_id}){
      let findRoomRate = {}
      if (rate.package_id)
        findRoomRate =  room.rate_codes.find(rc => rc.id === rate.id && rc.package_id === rate.package_id)
      else
        findRoomRate = room.rate_codes.find(rc => rc.id === rate.id && !rc.package_id)
      let args = {room, rate: findRoomRate, roomIndex, rateIndex}
      if(this.getRatePriceSummary(rate, room).total_points && this.enable_book_with_points) {
        let points = this.getRatePriceSummary(rate, room).total_points
        args = {room, rate: findRoomRate, roomIndex, rateIndex, points}
      }
      if(room_id){
        args.room_id = room_id
      }
      this.$emit('bookRoom', args)
    },
    packageCharges(charges) {
      return charges.reduce((b, a) => b + a.net_value_excl_taxes, 0);
    },
    roomDetails(item) {
      // return this.roomTypes[this.roomIndex].find(room => room.id === item.room_type_id)
      if (item.hasOwnProperty('package_id')) {
        return item
      }
      return this.roomTypes[this.roomIndex]?.find(room => room.id === item.room_type_id)
    },
    roomImages(room){
      let room_details = this.roomDetails(room)
      if(!room_details)
        return
      let arr =  [room_details.cover_photo, ...room_details.images.map(i => i.url)]
      return arr
    },
    getRoomsByRate(rate) {
      let result = []
      if (rate.package_id)
        result =  this.getFilteredRooms.filter(r => r.rate_codes.some(rc => rc.id === rate.id && rc.package_id === rate.package_id))
      else
        result = this.getFilteredRooms.filter(r => r.rate_codes.some(rc => rc.id === rate.id && !rc.package_id))

      let sortByPrice = this.allFilters[this.roomIndex]?.sortByPrice
      return CommonUtils.sortFunction(result, sortByPrice, "highest_rate");
      // return result
    },
    renderMarkdown(markedText) {
      if (markedText) {
        return marked(markedText, {sanitize: true})
      }
      return '';
    },

  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/config/mixins";
@import "@/assets/sass/config/variables";

:deep(ul) {
  margin-left: 10px !important;
  list-style: disc !important;
}

.discount-price {
  font-size: 10px;
  margin-right: 10px;
  text-decoration: line-through red;
}
.wrapper-item{
  display: flex;
}
.or-divider{
  display: flex;
  justify-content: space-around;
  font-size: 12px;
  margin-bottom: 5px;
  margin-top: 5px;
}
.desc {
  padding: 0 2px;
  // width: 90%;
  // text-align: justify;
  overflow: auto;
  max-height: 150px;
  font-size: 14px;
  max-width: 400px;
  overflow-y: auto;
  overflow-wrap: break-word;
}

.amenities-mobile {
    margin-top: 0px;
}
.rates-main-wrapper {
  border: solid #dadada 1px;
  border-radius: 5px;
  padding: 16px;
  margin-top: 10px;
  padding: 10px;


  .rates-wrapper {
    .rates-room-wrapper {
      border: solid #dadada 1px;
      border-radius: 5px;
      padding: 16px;
      margin-top: 10px;
    }

    .title {
      font-size: 16px;
      font-weight: bold;
      color: #4E4E4E;
      .el-icon-info{
        font-size: 20px !important;
      }
    }

    .sub-title {
      font-size: 14px;
      font-weight: bold;
      color: #4E4E4E;
    }

    .small-font {
      font-size: 12px;
      font-weight: 400;
    }

    .carousel-container{
      width: 30%;
      height: 140px;
      margin-bottom: 20px;
    }
  }
}
@include media('<=desktop') {
  .discount-price {
    font-size: 10px;
    margin-right: 10px;
    text-decoration: line-through red;
  }

  .amenities-mobile {
    margin-top: 40px;
  }
  .wrapper-item{
    flex-direction: column;
  }

   .carousel-container{
      width: 100% !important;
      height: 140px;
      margin-bottom: 40px;
    }

    .desc {
      margin-bottom: 0px !important;

      max-height: 150px;
      margin-top: 10px;
    }

    .rates-main-wrapper {
      width: 78% !important;
      margin: 0 auto;
      margin-top: 10px;
    }

}
.preferred-tag {
  background-color: $hk-blue;
  border-radius: 10px;
  color: #fff;
  display: inline-block;
  font-size: 11px;
  font-weight: normal;
  height: 18px;
  line-height: 18px;
  padding: 0 6px;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}
.popover-message{
  word-break: break-word;
  white-space: normal;
  padding: 10px;
}
</style>
