<template>
    <div class="cancel-reservation" v-loading="loading">
        <div class="flexed-wrap-between align-center bakground-grey bordered border-radius-5 p-20 m-b-10">
           <div>Confirmation Number</div>
           <span class="grey">{{searchReservationResult.conf_num}}</span>
        </div>
         <div class="bakground-grey bordered border-radius-5 p-20 m-b-10">
           <div class="m-b-10">Cancel Reservation</div>
           <div class="flexed-column w-100 align-center small justify-center ">
               <!-- <div class="m-b-10"> <i class="el-icon-warning" ></i></div> -->
               <span class="text-grey m-b-20 m-t-20">{{$t('lbl_cancel_warning')}}</span>
                <div class="flexed-wrap-stratched gap-10 m-t-30 w-100 footer">
                    <div><el-button type="danger"  size="medium" @click="cancelReservation()">Cancel Reservation</el-button></div>
                    <div><el-button type="default"  size="medium" @click="goToReservation()">Go Back to Reservation Details</el-button></div>
                </div>
           </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import ApiCalls from '@/services/api-calls.service.js'

    export default {
        name: 'CancelReservation',
        data() {
            return {
                loading: false
            }
        },
        computed: {
            ...mapState({
                searchReservationResult:  state => state.searchReservationResult
            }),
        },
        methods: {
            async cancelReservation() {
                this.loading = true
                try {
                    (await ApiCalls.put('web/cancel-booking',{}, {'request_id': this.searchReservationResult.request_token}, 'enterpriseUrl'))
                    this.$notify.success({
                      title: this.$t('lbl_success'),
                      message: "Your Cancellation was Successful"
                    })
                    this.$router.push({name: 'search-reservation'}).catch(()=>{})
                   
                } catch (error) {
                    this.handleError(error)
                }
                this.loading = false

            },
            goToReservation(){
                this.$router.push({name: 'search-reservation-result'}).catch(()=>{})
            },
            handleError(error){
                console.log(error)
                this.$notify.error({
                      title: this.$t('lbl_error'),
                      message: error?.response?.data?.message
                    })
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import "~css_vars"; 
    @import "~mixins"; 
    .cancel-reservation{
        .el-icon-warning{
            font-size: 150px;
            color: white !important;
            background-color: #606266;
            border-radius: 50%;
        }
        .footer{
            div{
                flex:1;
                .el-button{
                    width: 100%;
                }
            }
        }
    }
     @include media("<=tablet"){
         .cancel-reseration{
            .footer{
                div{
                    flex:none;
                    width: 100%;
                }
            }
         }
    }
</style>