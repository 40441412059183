import axios from 'axios'
import store from '../store'
// let property_id = store.state.property.details.region.url

// const api_base_url = `${base_url}properties/${property_id}`
let roomIndex = 0;

export default {
  getLowestRateCalendar(params) {
    let base_url = store.state.property.details.region.url
    let property_id = store.state.property.details.id
    params.conversion_currency = store.state.property.details.currency
    let url = `${base_url}hk-crs/properties/${property_id}/calendar/lowest-rate-calendar`

    return axios.get(url, {params})
  },

  getRatesWithAvailability(params) {
    let base_url = store.state.property.details.region.url
    let property_id = store.state.property.details.id
    params.conversion_currency = store.state.property.details.currency
    if(navigator.geolocation && store.state.country_code) {
      params.country_code = store.state.country_code
    }



    if (
      store.state?.property?.details?.application_configuration?.enable_children_ages &&
      store.state?.search_data?.rooms[roomIndex]?.persons.children > 0 &&
      store.state?.search_data?.rooms[roomIndex]?.persons?.children_ages
  ) {
      params.children_ages = store.state?.search_data?.rooms[roomIndex]?.persons?.children_ages?.map(age => Number(age))?.join(',')
      roomIndex++
  }

    let url = `${base_url}hk-crs/properties/${property_id}/mini-rates-with-availability/v3`

    return axios.get(url, {params})

  },

  setRate(payload, session_id) {
    let base_url = store.state.property.details.region.url
    let property_id = store.state.property.details.id
    if (store.state.group_no && store.state.rwa_data.group_block && store.state.rwa_data.group_block.id) {
      payload.group_details = [{ "group_block_id":  store.state.rwa_data.group_block.id }]
    }
    // }payload.group_block_id = store.state.rwa_data.group_block.id

    let url = `${base_url}${process.env.VUE_APP_BASE_URL}${process.env.VUE_APP_ENTERPRISE_IDENTIFIER}/properties/${property_id}/booking-engine/new-booking/${session_id}/set-rate`

    return axios.post(url, payload)
  },

  getProfileFolioTaxes(session_id) {
    let base_url = store.state.property.details.region.url
    let property_id = store.state.property.details.id

    let params = {conversion_currency : store.state.property.details.currency}
    let url = `${base_url}${process.env.VUE_APP_BASE_URL}${process.env.VUE_APP_ENTERPRISE_IDENTIFIER}/properties/${property_id}/booking-engine/new-booking/${session_id}/calculate-folios-and-charges-and-taxes`

    return axios.get(url, {params})
  },

  addressRules(params) {
    let base_url = store.state.property.details.region.url
    let property_id = store.state.property.details.id

    let url = `${base_url}${process.env.VUE_APP_BASE_URL}properties/${property_id}/address-rules`

    return axios.get(url, {params})
  },

  getUserDetails(params) {
    let base_url = store.state.property.details.region.url
    let url = `${base_url}${process.env.VUE_APP_BASE_URL}website/users`

    return axios.get(url, {params})
  },

  getApplicableRates(params) {
    let base_url = store.state.property.details.region.url
    let property_id = store.state.property.details.id

    let url = `${base_url}${process.env.VUE_APP_BASE_URL}properties/${property_id}/package-rates/applicable`

    return axios.get(url, {params})
  },
  addGuest(batchId, payload) {
    let base_url = store.state.property.details.region.url
    let property_id = store.state.property.details.id
    const url = `${base_url}${process.env.VUE_APP_BASE_URL}${process.env.VUE_APP_ENTERPRISE_IDENTIFIER}/properties/${property_id}/booking-engine/new-batch-booking/${batchId}/add-guest`

    return axios.post(url, payload)
  },
  addSessions(session_id, payload){
    let base_url = store.state.property.details.region.url
    let property_id = store.state.property.details.id
    const url = `${base_url}${process.env.VUE_APP_BASE_URL}${process.env.VUE_APP_ENTERPRISE_IDENTIFIER}/properties/${property_id}/booking-engine/new-booking/${session_id}/add-guest`

    return axios.post(url, payload)
  },
  getDiscount(params) {
    let base_url = store.state.property.details.region.url
    let property_id = store.state.property.details.id
    const url = `${base_url}${process.env.VUE_APP_BASE_URL}properties/${property_id}/check-discounts`

    return axios.get(url, {params})
  },
  getLocalizations(params) {
    let base_url = process.env.VUE_APP_SERVER_ENV
    let url = `${base_url}${process.env.VUE_APP_BASE_URL}common/language`;

    return axios.get(url, {params})
  },
  getHotelDetails(property_id) {
    let params= {}
    if(store.state.keyFromUrl) params.key = store.state.keyFromUrl
    if(store.state.tokenFromUrl) params.token = store.state.tokenFromUrl
    let base_url = process.env.VUE_APP_SERVER_ENV
    let url =  `${base_url}${process.env.VUE_APP_BASE_URL}properties/${property_id}/property-website-info`

    return axios.get(url, {params})
  },
  // getAddonFR(params, property_id) {
  //   const url = `${process.env.VUE_APP_SERVER_ENV}/${process.env.VUE_APP_API_VERSION}/${process.env.VUE_APP_HOTELBRAND}/properties/${property_id}/charge-types/applicable/v2`
  //
  //   return axios.get(url, {cache: false, params: params});
  // },

  getAddon(params) {
    let base_url = process.env.VUE_APP_SERVER_ENV
    let property_id = store.state.property.details.id
    params.conversion_currency = store.state.property.details.currency

    let url =  `${base_url}${process.env.VUE_APP_BASE_URL}${process.env.VUE_APP_ENTERPRISE_IDENTIFIER}/properties/${property_id}/charge-types/applicable/v2`

    return axios.get(url, {cache: false, params: params})
  },

  postAddon(payload, session) {
    let base_url = process.env.VUE_APP_SERVER_ENV
    let property_id = store.state.property.details.id
    const url = `${base_url}${process.env.VUE_APP_BASE_URL}${process.env.VUE_APP_ENTERPRISE_IDENTIFIER}/properties/${property_id}/booking-engine/new-booking/${session}/include-addon`

    return axios.post(url, payload);
  },
  postBrandApi(payload) {
    let base_url = process.env.VUE_APP_SERVER_ENV
    const url = `${base_url}${process.env.VUE_APP_CORE_API}/crs/rates/lowest`
    return axios.post(url, payload);
  },
  getBrandCodes(params) {
    let base_url = process.env.VUE_APP_SERVER_ENV
    let url = `${base_url}${process.env.VUE_APP_BASE_URL}${process.env.VUE_APP_ENTERPRISE_IDENTIFIER}/brand-codes`;

    return axios.get(url, {params})
  },
  searchZipCode(params) {
    let base_url = process.env.VUE_APP_SERVER_ENV
    let property_id = store.state.property.details.id
    const url = `${base_url}${process.env.VUE_APP_BASE_URL}${process.env.VUE_APP_ENTERPRISE_IDENTIFIER}/properties/${property_id}/booking-engine/new-batch-booking/search-address`;

    return axios.get(url, { params });
  },
}
