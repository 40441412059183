import { render, staticRenderFns } from "./DisplayByRatesV2.vue?vue&type=template&id=35a838ce&scoped=true&"
import script from "./DisplayByRatesV2.vue?vue&type=script&lang=js&"
export * from "./DisplayByRatesV2.vue?vue&type=script&lang=js&"
import style0 from "./DisplayByRatesV2.vue?vue&type=style&index=0&id=35a838ce&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35a838ce",
  null
  
)

export default component.exports