<template>
  <div class="">
    <div class="payments-main flexed justify-between gap-40">
      <div style="max-width: 250px">
          <el-radio v-model="paymentSource" label="creditCard"
                    class="radio-option card-border"
                    v-if="eventConfig.event_booking_profile.enable_card_capture"
                    ><img src="../../../../assets/credit-card.png"  width="14.5px" height="15px">
                    Pay By Credit Card
          </el-radio>
          <el-radio v-model="paymentSource" label="companyCode"
                    class="radio-option card-border"
                    ><img src="../../../../assets/company.png" width="14.5px" height="15px">
                      Pay By Company
          </el-radio>
      </div>
      <div class="flex_1" >

          <payment-frame
            v-if="paymentSource == 'creditCard' && eventConfig.event_booking_profile.enable_card_capture"
            @sendData="nextStep"
            ref="payment-frame"
          ></payment-frame>

        <company-code-payment
          v-if="paymentSource == 'companyCode'"
          @sendData="setPayload"
        ></company-code-payment>
      </div>
      <Summary></Summary>
    </div>
    <div class="navigation">
      <button @click="previousStep()" >{{ $t('lbl_previous') }}</button>
      <el-button class="next" type="primary"
                 :loading="btnLoading"
                 @click="nextStep(paymentPayload)">
        {{ $t('lbl_next') }}</el-button>
    </div>
  </div>
</template>

<script>
import Summary from '../../components/Summary.vue'
import PaymentFrame from "./payment-frame";
import apiCallsEventsService from "../../../../services/events/api-calls-events.service";
import {mapState} from "vuex";
import CompanyCodePayment from "./companyCodePayment";
import eventApiCall from "../../../../services/events/api-calls-events.service";


export default {
  name: 'Payments',
  components:{
    CompanyCodePayment,
    Summary, PaymentFrame
  },
  data() {
    return {
      paymentSource: '',
      paymentPayload: {},
      btnLoading: false
    }
  },
  methods: {
    setPayload(payload){
      this.paymentPayload = payload
    },
    async previousStep() {
      this.$router.push({name: 'guest-information'}).catch(()=>{})
    },
    async nextStep(payload) {
      this.btnLoading = true
      try{
        await eventApiCall.post(`${this.propertyDetails.id}/web/new-event/${this.eventConfig.event_id}/guarantee-info/${this.eventConfig.session_id}`, payload, null, 'eventManagementApi')
        this.$router.push({name: 'confirmation'}).catch(()=>{})
      }catch (e) {
        const errorMessage = error?.response?.data?.message || error?.data?.message || error?.message || error?.data
        this.$notify.error({ title: "Error", message: errorMessage, });
        this.$refs["payment-frame"]?.stopLoading()
        this.btnLoading = false
      }
    },
  },
  beforeMount() {
    this.paymentSource = this.eventConfig.event_booking_profile.enable_card_capture ? 'creditCard' : 'companyCode'
  },
  computed:{
    ...mapState({
      propertyDetails: state => state.events.propertyDetails,
      eventConfig: state => state.events.eventConfig,
    })
  },
}
</script>

<style lang="scss" scoped>
@import "~css_vars";
@import '~mixins';
.navigation button {
  border: 1px solid $lightgrey-2;
  border-radius: 5px;
}
.payments-main{
  margin: 30px 80px 30px;
  padding-bottom: 50px;
  .radio-option{
    padding: 12px 40px 12px 10px;
    margin-top: 20px;
    width: 250px;
    img{
      margin-right: 5px;
      margin-bottom: -2px;
    }
  }
}


</style>
