<template> 
    <div class="summary-container m-b-10 m-t-20 p-20 card-border">
        <div class="flexed justify-center align-center" v-if="loading">
            <loading></loading>
        </div>
      <div v-else>
        <div class="" v-if="!mobileView">
            <div class="image">
                <div class="img" v-if="activityDetails.image">
                    <img :src="activityDetails.image" height="190px" width="100%">
                  </div>
                <!-- <div class="no-img" v-else>
                    <i class="el-icon-picture-outline no-img-icon"></i>
                    <span>No Image Found</span>
                  </div> -->
            </div>
            <h3 class="m-b-0" >Rate Details</h3>
            <div class="charges-data">
            <div class="flexed justify-between"><p>Total Amount</p><p>{{ total | currency(currency)}}</p></div>
            <div class="flexed justify-between"><p>Total Taxes</p><p>{{priceDetails.total_taxes | currency(currency)}}</p></div>
            <div class="p-t-20" style="border-bottom: 1px solid #000 ;"></div>
            <div class="flexed justify-between bold"><p>Grand Total</p><p>{{ priceDetails.grand_total | currency(currency)}}</p></div>
          </div>
        </div>
        <div  v-if="mobileView">
          <!-- <el-button @click="drawer = true" type="primary" style="margin-left: 16px;">
            open
          </el-button> -->
          <div class="flexed justify-between align-center">
            <div class="flexed justify-between m-t-0 m-b-0"><p class="m-t-0 m-b-0 m-r-20">Grand Total</p><p class="m-t-0 m-b-0">{{ priceDetails.grand_total | currency(currency)}}</p></div>
            <!-- <ChevronDownIcon  @click="drawer = true" class="caret-icon"></ChevronDownIcon> -->
            <div><i @click="drawer = true" class="el-icon-caret-bottom icon"></i></div>
          </div>
            <el-drawer
              title=""
              class="drawer"
              :visible.sync="drawer"
              :direction="direction"
              :before-close="handleClose">
              <div class="m-b-10 m-t-20 p-20">

              <div class="image">
                <div class="img" v-if="activityDetails.image">
                    <img :src="activityDetails.image" height="190px" width="100%">
                  </div>
                <!-- <div class="no-img" v-else>
                    <i class="el-icon-picture-outline no-img-icon"></i>
                    <span>No Image Found</span>
                  </div> -->
              </div>
              <h4 class="m-t-20 m-b-5" >Rate Details</h4>
              <div class="charges-data">
                <div class="flexed justify-between"><p>Total Amount</p><p>{{ total | currency(currency)}}</p></div>
                <div class="flexed justify-between"><p>Total Taxes</p><p>{{priceDetails.total_taxes | currency(currency)}}</p></div>
                <div class="p-t-20" style="border-bottom: 1px solid #000 ;"></div>
                <div class="flexed justify-between bold"><p>Grand Total</p><p>{{ priceDetails.grand_total | currency(currency)}}</p></div>

               
              </div>
            </div>

            </el-drawer>
        </div>
      </div>
    </div>
</template>


<script>
  import loading from '@/components/common/loading.vue';
  import { mapState } from 'vuex';
  import CurrencyData from '@/assets/currencies/currencies';
  import { ChevronDownIcon, ChevronUpIcon, MenuIcon, XIcon, SearchIcon, DollarSignIcon} from 'vue-feather-icons'

export default {
    name: 'RateDetailsSummary',
    components: {loading, ChevronDownIcon},
    data() {
      return {
        loading: false,
        currencyData: CurrencyData,
        drawer: false,
        direction: 'ttb',
      }
    },
    computed: {
      ...mapState({
        currency: state => state.activities.propertyDetails.currency,
        activityDetails: state => state.activities.activityDetails,
        priceDetails: state => state.activities.priceDetails,
        property: state => state.activities.propertyDetails,
        mobileView: state => state.mobileView
      }),
      total() {
        return this.priceDetails.total_charge_adult + this.priceDetails.total_charge_children + this.priceDetails.base_price
      }
    },
    methods: {
      handleClose(done) {
            done();
      }
    }
}
</script>


<style scoped lang="scss" >
@import "~css_vars";
@import '~mixins';

.grid-container {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 10px;

  .grid-item {
    background-color: #ccc;
  }

  .grid-item-1 {
    grid-row: 1 / 3;
  }
}

.text-container {
  .points {
    margin-left: 24px;
    list-style: disc;
  }
}

.btn-proceed-booking {
  width: 100%;
  font-weight: bold;
}


.charges-data p {
  margin: 10px 0;
}

.summary-container{
  width: 30%;
  height: 100%;
}

.img-size {
  max-width: 100%;
}
  .large-image {
    grid-row: 1 / 3;
    width: 100%;
  }

  .covers {
    font-size: 14px;
    color: #adb5bd;
  }

  .aditional {
    margin-top: 10px;
  }

  .aditional p{
    margin: 0;
  }

  .select-time {
    margin: 0;
  }

  .base-price p{
    margin-top: 10px;
    margin-bottom: 0px;
  }

  .additinal-calculations {
    font-size: 14px;
    color: #adb5bd;
  }

  @include media("<=tablet") {
    .summary-container{
      width: 100%;
      height: 100%;
      margin-bottom: 10px;
    }

    .charges-data p {
      font-size: 14px;
    }

  }
  ::v-deep .el-drawer {
     height: 100% !important;
   }

  ::v-deep .el-drawer__header {
      margin-bottom: 0 !important;
      margin-top: 0 !important;
    //   font-weight: bold;
      color: #2c3e50;
    //   font-size: 20px;
    padding: 10px 20px;
    border-bottom: 1px solid #d4d4d4;
    button{
    min-width: auto !important;
    }
    .el-drawer__close-btn {
        padding: 0px;
    }

  
  }

  .icon {
      height: 100%;
      display: flex;
      align-content: center;
      align-items: center;
      font-size: 1.7em;
      color: var(--primary-color) !important;
    }

  // .no-img {
  //   margin-bottom: 51%;
  // }

</style>