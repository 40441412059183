<template>
    <div v-loading="loading">
         <div class="flexed justify-between align-center bakground-grey bordered border-radius-5 p-20 m-b-10">
            <div>Confirmation Number</div>
           <span class="grey">{{searchReservationResult.conf_num}}</span>
        </div>
        <div class="w-100 bakground-grey bordered border-radius-5 p-20 m-b-10 form-controls">
             <div class="flexed justify-between align-center title">
                 <div class="flexed gap-10">
                     <arrow-left-icon size="1.5x" class="pointer" @click="goTo('search-reservation-result')"></arrow-left-icon>
                     <span class="title">Modify Guest Details</span>
                 </div>
                <el-button type="primary"  @click="save()">Save</el-button>
            </div>
            <fd-input
                key="first_name"
                v-model="guest.first_name"
                v-validate="{ required: fieldRequired('first_name'), regex: addressRules.first_name_regex || notValidInput || ''}"
                :placeholder="$t('lbl_first_name')"
                :showAsterisk="fieldRequired('first_name')"
                border-radius-bottom-right="0"
                border-radius-bototm-left="0"
                :autocomplete="false"
                control-name="first_name"
                :error="vValidateErrorHandler('first_name')"
                class=""
            />
            <fd-input
                key="last_name"
                v-model="guest.last_name"
                v-validate="{ required: fieldRequired('last_name'), regex: addressRules.last_name_regex || notValidInput || ''}"
                :placeholder="$t('lbl_last_name')"
                :showAsterisk="fieldRequired('last_name')"
                border-radius-bottom-right="0"
                border-radius-bototm-left="0"
                :autocomplete="false"
                control-name="last_name"
                :error="vValidateErrorHandler('last_name')"
                class=""
            />
             <fd-input
                key="email"
                v-model="guest.email"
                v-validate="{ required: fieldRequired('email'), regex: addressRules.email_regex || ''}"
                :placeholder="$t('lbl_email')"
                :showAsterisk="fieldRequired('email')"
                border-radius-bottom-right="0"
                border-radius-bototm-left="0"
                :autocomplete="false"
                control-name="email"
                :error="vValidateErrorHandler('email')"
                class=""
            />
            <hk-phone-input :model.sync="guest.phone"
                ref="hkPhoneInput"
                :placeholder="$t('lbl_phone')"
                :showAsterisk="fieldRequired('phone')"
                key="phone"
                control-name="phone"
                autocomplete="new-password"
                v-validate="{ required: fieldRequired('phone') }"
                :error="errors.first('phone') && 'Required'"
                @blur="$validator.validate('phone')"
                @input="$validator.validate('phone')"
                defaultCountryCode="US"
                classes="primary-phone" />
             <fd-input
                key="street"
                v-model="guest.street"
                v-validate="{ required: fieldRequired('street'), regex: addressRules.address_regex || notValidInput || ''}"
                :placeholder="$t('lbl_street')"
                :showAsterisk="fieldRequired('street')"
                border-radius-bottom-right="0"
                border-radius-bototm-left="0"
                :autocomplete="false"
                control-name="street"
                :error="vValidateErrorHandler('street')"
                class=" street"
            />
            <fd-input
                key="street2"
                v-model="guest.street_2"
                v-validate="{ required: fieldRequired('street_2'), regex: addressRules.street_2_regex || notValidInput || ''}"
                :placeholder="$t('lbl_street_2')"
                :showAsterisk="fieldRequired('street_2')"
                border-radius-bottom-right="0"
                border-radius-bototm-left="0"
                :autocomplete="false"
                control-name="street_2"
                :error="vValidateErrorHandler('street_2')"
                class=" street"
            />
            <fd-input
                key="city"
                v-model="guest.city"
                v-validate="{ required: fieldRequired('city'), regex: notValidInput || ''}"
                :placeholder="$t('lbl_city')"
                :showAsterisk="fieldRequired('city')"
                border-radius-bottom-right="0"
                border-radius-bototm-left="0"
                :autocomplete="false"
                control-name="city"
                :error="vValidateErrorHandler('city')"
            />
             <fd-input
                key="state_required"
                v-model="guest.state"
                v-validate="{ required: fieldRequired('state_required'), regex: notValidInput || ''}"
                :placeholder="addressRules && addressRules.property_eligible_states && addressRules.property_eligible_states.length > 0 ? '' : $t('lbl_state')"
                :showAsterisk="fieldRequired('state_required')"
                data-vv-validate-on="input|blur"
                border-radius-bottom-right="0"
                border-radius-top-right="0"
                border-radius-top-left="0"
                control-name="state_required"
                justify-content="space-between"
                :error="vValidateErrorHandler('state_required')"
            >
            <span v-if="addressRules && addressRules.property_eligible_states && addressRules.property_eligible_states.length > 0" @click="$refs.selectState.focus()">
               <span v-if="fieldRequired('state_required')" class="asterisk">*</span>  {{$t('lbl_state')}}
            </span>
            <el-select
              v-if="addressRules && addressRules.property_eligible_states && addressRules.property_eligible_states.length > 0"
              v-model="guest.state"
              filterable
              :placeholder="$t('lbl_select_state')"
              class="border-0 text-right"
              size="mini"
              ref='selectState'
            >
              <el-option
                v-for="state in addressRules.property_eligible_states"
                :key="state.id"
                :label="state.state_code"
                :value="state.state_code"
              />
              <template slot="prefix" class="el-error" v-if="errors.has('state_required')"> <span class="el-error">Required</span> </template>
            </el-select>
          </fd-input>
            <fd-input
                key="zipcode_required"
                v-model="guest.zip_code"
                v-validate="{ required: fieldRequired('zipcode_required'), regex: addressRules.zipcode_regex || notValidInput || ''}"
                placeholder="Postal"
                :showAsterisk="fieldRequired('zipcode_required')"
                border-radius-bottom-right="0"
                border-radius-bototm-left="0"
                :autocomplete="false"
                control-name="zipcode_required"
                :error="vValidateErrorHandler('zipcode_required')"
                :loading="zip_loading"
                :show-button="true"
                @buttonClicked="callPostalApi()"
            />
            <fd-input
                key="country"
                v-model="guest.country"
                v-validate="{ required: fieldRequired('country')}"
                :placeholder="$t('lbl_country')"
                :showAsterisk="fieldRequired('country')"
                border-radius-bottom-right="0"
                border-radius-bototm-left="0"
                :autocomplete="false"
                control-name="country"
                :error="vValidateErrorHandler('country')"
                :disabled="true"
            />

        </div>
    </div>
</template>

<script>
import FdInput from '@/components/FdInput.vue'
import { mapMutations, mapState } from 'vuex'
import { ArrowLeftIcon} from 'vue-feather-icons'
import deepClone from 'clone'
import HkPhoneInput from '@/components/hk-tel-input/HkPhoneInput.vue'
import ApiCalls from '@/services/api-calls.service.js'
import CommonServices from "@/services/common.services"
    export default {
  components: { FdInput, ArrowLeftIcon, HkPhoneInput },
        name: 'GuestDetails',
        computed: {
           ...mapState({
                searchReservationResult:  state => state.searchReservationResult,
                mobileView: state => state.mobileView,
                propertyDetails: (state) => state.property?.details,
            }),
          notValidInput() {
            return '^([\\w -]){1,150}$'
          }
        },
        data() {
            return {
                loading: false,
                zip_loading: false,
                guest: {first_name: '', last_name: '', email: '', phone: '', street: '', street_2: '', city: '', state: ''
                        , zip_code: '', country: ''},
                addressRules: {}
            }
        },
        beforeMount () {
            this.guest = deepClone(this.searchReservationResult.reservation_details.primary_guest)
            this.getData()
        },
        mounted () {
            // this.$validator.validateAll();
        },
        methods: {
            ...mapMutations({
                SET_SEARCH_RESERVATION_RESULT: 'SET_SEARCH_RESERVATION_RESULT',
            }),
            goTo(name){
                this.$router.push({name: name}).catch(()=>{})
            },
            async save(){
                let isPhoneValid = true
                if(this.guest.phone) if(this.$refs.hkPhoneInput) isPhoneValid = await this.$refs.hkPhoneInput.buildResults()
                if(isPhoneValid && (await this.$validator.validateAll())){
                    let searchParams = {
                        conf_num: this.searchReservationResult.conf_num,
                        last_name: this.guest.last_name,
                        property_code: this.propertyDetails.code
                    }
                    this.loading = true
                    try {
                        (await ApiCalls.put(`web/update-guest`, this.guest, {request_id: this.searchReservationResult.request_token}, 'enterpriseUrl'))
                        let searchResult = (await ApiCalls.get('web/manage-booking', searchParams, 'enterpriseUrl'))?.data
                        this.SET_SEARCH_RESERVATION_RESULT({...searchResult, conf_num: searchParams.conf_num});
                        this.$notify.success({
                            title: 'Success',
                            message: "Saved Successfully"
                        })
                        this.$router.push({name: 'search-reservation-result'}).catch(()=>{})
                    } catch (error) {
                        this.handleError(error)
                    }
                    this.loading = false
                }
            },
            handleError(error){
                console.log(error)
                this.$notify.error({
                      title: this.$t('lbl_error'),
                      message: error?.response?.data?.message
                    })
            },
            getPaylod(){
                return{
                    first_name: this.guest.first_name,
                    last_name: this.guest.last_name,
                    email: this.guest.email,
                    phone: this.guest.phone,
                    street: this.guest.street,
                    street_2: this.guest.street_2,
                    city: this.guest.city,
                    state: this.guest.state,
                    zip_code: this.guest.zip_code,
                    country: this.guest.country,
                }
            },
            async getData(){
                let addressRulesparams = {country_code:this.guest.country, booking_flow: true }
                this.loading = true
                try {
                    this.addressRules = (await ApiCalls.get(`${this.propertyDetails.id}/address-rules`, addressRulesparams, 'baseAppUrlProperties'))?.data
                } catch (error) {
                    this.handleError(error)
                }
                this.loading = false
            },
             vValidateErrorHandler(fieldName) {
                let message = null;
                if (this.errors?.has(fieldName)) {
                    let feldRule = this.errors.items.find(item => item.field === fieldName);
                    if (feldRule?.rule === 'required') message = 'Required';
                    else if (feldRule?.rule === 'regex') message = 'Not valid';

                }
                return message;
            },
            fieldRequired(fieldName) {
                if(fieldName === 'state_required' || fieldName === 'zipcode_required')
                    return this.addressRules[fieldName] || false
                else
                    return this.addressRules.required_guest_fields?.some(m=>m == fieldName)
            },
            async callPostalApi() {
                if (!this.guest.zip_code) {
                    this.$notify.info({
                        title: 'Info',
                        message: 'Please input zip code before searching',
                    });
                    return;
                }
                try {
                    this.zip_loading = true;
                    const response = await CommonServices.searchZipCode({ zip_code: this.guest.zip_code, country: this.guest.country });
                    let  parsedRes  = JSON.parse(response.data)
                    let data = parsedRes?.[0] 


                    if (data.city && data.state) {
                        this.guest.city = data.city
                        this.guest.state = data.state
                    }
                    this.zip_loading = false;
                }
                catch (error) {
                    this.zip_loading = false
                    console.log(error)
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import '~css_vars';
    @import "~mixins";
    .form-controls{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px 10px;
        .street, .title{
            grid-column-start: 1;
            grid-column-end: -1;
        }
        .border-0 {
            ::v-deep  input {
                border: none  !important;
                text-align: right;
                font-size: 16px;
            }
        }
         .el-error {
            height: 100%;
            display: flex;
            align-items: center;
            color: #F56C6C !important;
            font-size: 10px;
            margin-right: 5px;
        }

    }
     @include media('<=600px') {
         .form-controls{
            grid-template-columns: 1fr;
         }
     }
    .asterisk{
        color: $hk-danger;
    }
</style>
