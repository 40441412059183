<template>
    <div class="reservation-info-mobile">
        Modify Stay Detils for #<span class="bold500 m-r-10">{{searchReservationResult.conf_num}}</span> 
        <alert-circle-icon size="1.5x" class="custom-class" @click="$emit('showReserDetails')"></alert-circle-icon>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { AlertCircleIcon } from 'vue-feather-icons'
    export default {
        name: 'ReservationInfoMobile',
         components: {
            AlertCircleIcon
        },
        computed: {
            ...mapState({
                searchReservationResult:  state => state.searchReservationResult,
            }),
        }
    }
</script>

<style lang="scss" scoped>
    .reservation-info-mobile{
        background-color: whitesmoke;
        width:100%;
        display: flex;
        justify-content: center;
        align-content: center;
        padding: 20px;
        margin-bottom: 10px;
    }
</style>