<template>
  <div >
    <div class="select-space-container">
      <h3> Select Activity </h3>
      <div class="flexed align-center" style="column-gap: 23px; align-content: center">
            <template>
              <div class="flexed-column">
                <div class="flexed align-center mobile-search">
                <el-date-picker
                  v-model="date"
                  type="date"
                  :picker-options="datePickerOptions"
                  value-format="yyyy-MM-dd"
                  format="MMM d, yyyy"
                  @change="changeDate()"
                ></el-date-picker>
              </div>
            </div>
            </template>

            <el-button class="searach-activity" @click="searchActivity" type="primary">Search</el-button>
          </div>
          <div class="flexed justify-center align-center" v-if="loading">
            <loading></loading>
          </div>
          <div v-else>
            <activity-list @bookActivity="bookActivity"  :selectedDate="date" :activities="activities"></activity-list>
          </div>
      <!-- <div class="navigation">
        <el-button class="next" type="primary" :loading="loading"
                    @click="nextStep()">
          {{ $t('lbl_next') }}</el-button>
      </div> -->
    </div>
  </div>
</template>

<script>
import loading from '@/components/common/loading.vue';
import apiCall from '@/services/activities/api-calls-activities.service'
import { mapState, mapMutations } from 'vuex';
import ActivityList from './ActivityList.vue';
import {formatDate} from "@/services/utilities.service.js"
  export default {
    name: 'SelectActivity',
    components: {
    loading,
    ActivityList
},
    data() {
      return {
        loading: false,
        date : null,
      }
    },
    computed:{
      ...mapState({
        property: state => state.activities.propertyDetails,
        activities: state => state.activities.activities,
        // activityDetails: state => state.activities.acitivityDetails
      }),
      datePickerOptions(){

      }
    },
    methods: {
      ...mapMutations({
        SET_ACTIVITIES: 'activities/SET_ACTIVITIES',
        SET_ACTIVITY_DETAILS:'activities/SET_ACTIVITY_DETAILS'
      }),
      changeDate(){
        let payload = {
          from: formatDate(this.date, 'YYYY-MM-DD'),
          to: formatDate(this.date, 'YYYY-MM-DD')
        }
        this.getActivities(payload)
      },
      searchActivity() {
        let payload = {
          from: formatDate(this.date,'YYYY-MM-DD'),
          to: formatDate(this.date, 'YYYY-MM-DD')
        }
        this.getActivities(payload);
      },
      async getActivities(payload){
        this.loading = true;
        try{
          let response = await apiCall.get(`${this.property.id}/web/activities/calendar`, payload, 'eventManagementApi')
          this.SET_ACTIVITIES(response.data)
        }
        catch(error){
          console.log(error)
        }
        this.loading = false;
      },
      async bookActivity(details) {
       await this.SET_ACTIVITY_DETAILS(details);
       await this.nextStep();
      },
      async nextStep() {
          this.$router.push({name: 'activity-details'}).catch(() => {})
      }
    },
    beforeMount() {
      this.date = formatDate(this.property.business_day?.date, 'YYYY-MM-DD')
      this.searchActivity()
    }

  }
</script>

<style lang="scss" scoped>
@import "@/assets/sass/config/mixins.scss";
@import "~css_vars";
 .select-space-container{
   margin: 30px 450px 60px 80px;
   .label{
     font-size: 14px;
     font-weight: bold;
     color: #5a5a5a;
     margin-bottom: 7px;
   }
   .mobile-date-time-picker{
     font-size: 14px;
     span{
       color: $hk-grey;
       font-weight: bold;
     }
   }
 }
@include media("<=tablet"){
  .select-space-container {
    margin: 30px 25px;
    .edit-icon{
      align-self: center;
      border: 1px solid black;
      border-radius: 50%;
      width: 25px;
      i{
        margin-left: 4px;
      }
    }

    .searach-activity {
      width: 100%;
      margin-left: -40px;
    }

    .mobile-search {
      width: 80%;
    }
  }
}
</style>
