<template>
   <div class="confirmation-main">
    <div class="flexed justify-center" v-if="loading">
      <loader></loader>
    </div>
    <div v-else>
      <div class="confirmation-container card-border">
        <div class="flexed justify-between mobile-confirmation">
          <div class="event-status">
             <!-- <h3>Confirmation</h3> -->
              <div class="image">
                <div class="img" v-if="activityDetails.image">
                    <img :src="activityDetails.image" class="img-mobile" height="232px" width="320px">
                </div>
                <!-- <div class="no-img" v-else>
                    <i class="el-icon-picture-outline no-img-icon"></i>
                    <span>No Image Found</span>
                  </div> -->
                
                  <div class="list-card-pic" v-else>
                      <div class="no-img">
                        <i class="el-icon-picture-outline no-img-icon"></i>
                        <span>No Image Found</span>
                      </div>
                  </div>
                
            </div>
            <!-- <h4>Event has been booked successfully</h4> -->
          </div>
          
          <div>
            <h4 class="flexed justify-between"> <span class="m-r-10">Confirmation Number</span><span style="color: var(--primary-color);">{{confirmationData.confirmation_no}}</span> </h4>
            <p class="bold">{{ fullName }}</p>
            <p>Activity Name: {{activityDetails.name}}</p>
              <p>
                <!-- <svg class="calendar-icon">
                  <use xlink:href="@/assets/calendar_event.svg#calendarIcon"></use>
                </svg> -->
                Activity Date: {{ activityDetails.selectedDate | formatDate }}
              </p>
              <p>
                <!-- <svg class="calendar-icon">
                  <use xlink:href="@/assets/calendar_event.svg#calendarIcon"></use>
                </svg> -->
                Activity Time: {{ activityDetails.from_time | formatTime }} - {{ activityDetails.to_time | formatTime }}
             </p>
              <p>
                <!-- <svg class="calendar-icon">
                  <use xlink:href="@/assets/calendar_event.svg#calendarIcon"></use>
                </svg> -->
                Booking Date: {{ confirmationData.booking_date | formatDate }}
              </p>
            <!-- <p>time ska</p> -->
            <p><svg class="adult-icon">
                        <use xlink:href="@/assets/adult_icon.svg#adultIcon"></use>
                    </svg> 
                    {{ confirmationData.adults }} Adults
            </p>
            <p>  
              <svg class="adult-icon">
                        <use xlink:href="@/assets/adult_icon.svg#adultIcon"></use>
                    </svg> 
                    {{ confirmationData.children }} Children
            </p>
            <el-button type="primary" @click="bookAnotherReservation()" class="w-100"> Book Another Activity</el-button>
          </div>
          <!-- <el-button type="primary"
                     @click="go_home"
          >Book a New Event</el-button> -->
        </div>

        <!-- <div class="flexed m-t-20">
          <p>testing</p>

          <div class="image">
            <div class="img" v-if="selectedEvent.image">
              <img :src="selectedEvent.image" height="232px" width="320px">
            </div>
           <div class="no-img" v-else>
              <i class="el-icon-picture-outline no-img-icon"></i>
              <span>No Image Found</span>
            </div>
          </div>
          <div class="m-l-30 space-data flex_1">
            <div class="m-t-0 bold">{{data.name}}</div>
            <div class="m-t-0 bold"> {{ data.guest.first_name + " " + data.guest.last_name }}</div>
            <div class="flexed justify-between"> <span><i class="el-icon-date"></i> {{ data.from | formatDate }} </span><span><i class="el-icon-user"></i> {{ data.sub_events[0].number_of_persons }}</span></div>
            <div><i class="el-icon-location-outline"></i> {{ data.sub_events[0].space.name }}</div>
            <div class="flexed justify-between"><p>Total Charges</p><p>{{ data.total_charges | currency(currency)}}</p></div>
            <div class="flexed justify-between"><p>Total Taxes</p><p>{{ data.total_taxes | currency(currency)}}</p></div>
            <div class="flexed justify-between bold"><p>Grand Total</p><p>{{ (data.total_charges + data.total_taxes) | currency(currency) }}</p></div>
          </div>
        </div> -->
           </div> 
        </div> 
    <!-- <div class="navigation">
      <button @click="previousStep()" >{{ $t('lbl_previous') }}</button>
    </div> -->
  </div>
</template>



<script>
import apiCall from '@/services/activities/api-calls-activities.service'
import {mapMutations, mapState} from "vuex";
import loader from '@/components/common/loading.vue';
// import calendarIcon from '../../../../assets/calendar.png'
// import adultsIcon from '@/assets/adult_icon.svg'
import dayjs from "dayjs";
import CurrencyData from '@/assets/currencies/currencies';
export default {
  name: "Confirmation",
  components:{
    loader
  },
  data() {
    return {
      loading: false,
      currencyData: CurrencyData,
      data: null,
    }
  },
  computed: {
    ...mapState({
      startActivityMap: state => state.activities.startActivityMap,
      priceDetails: state => state.activities.priceDetails,
      activityDetails: state => state.activities.activityDetails,
      property: state => state.activities.propertyDetails,
      confirmationData: state => state.activities.confirmationData
    }),
    fullName() {
      return `${this.confirmationData?.primary_guest?.first_name} ${this.confirmationData?.primary_guest?.last_name}`
    }
  },
  methods: {
    ...mapMutations({
      SET_CONFIRMATION_DATA: 'activities/SET_CONFIRMATION_DATA'
    }),
    bookAnotherReservation() {
      window.location.reload()
    },
    async getConfirmationData() {
      try {
        this.loading = true;
            // call api

          //https://00.us-east-1.dev.hotelkeyapp.com/event-management/hotelbrand/properties/
          //d55ce161-e360-4487-842a-bf4374e6bb66/new-activity-booking/e7b9f017-0d5f-4ac2-84e0-93bbf46b28e7/
          //confirm/886ec1aa-d033-462a-826e-1329354001ed

          //https://00.us-east-1.dev.hotelkeyapp.com/event-management/hotelbrand/properties/
          //d55ce161-e360-4487-842a-bf4374e6bb66/web/new-activity-booking/dac808c2-65d9-42f3-b275-67b73e26a1fc/
          //confirm/12cd1793-f2a5-412d-b379-059eaba37809
          const response = await apiCall.post(`${this.property.id}/web/new-activity-booking/${this.startActivityMap.id}/confirm/${this.startActivityMap.session_id}`,{}, null,'eventManagementApi')
          console.log(response.data);
          this.SET_CONFIRMATION_DATA(response.data);
          
          this.loading = false;
        }
        catch(error) {
        console.log(error)
      }
    },
    async previousStep() {
      this.$router.push({name: 'activity-payments'}).catch(() => {
      })
    }
  },
  beforeMount() {
      this.getConfirmationData()
      
  }
}
</script>

<style lang="scss" scoped>
@import '~css_vars';
@import "~mixins";

.confirmation-main{
  margin: 30px 80px 30px;
  padding-bottom: 50px;
  .confirmation-container{
    padding: 30px;
    max-width: 700px;
    margin: 50px auto 0;
    font-size: 14px;
    .event-status{
      h4 {
        margin: 0;
        padding:0;
      }
    }

    svg.adult-icon, svg.calendar-icon {
                    --color: red;
                    height: 15px !important;
                    width:15px;
                    margin-right: 3px;
                    margin-bottom: -2px;
                    fill: $hk-primary-color !important;
                }
    .space-data div{
      margin: 14px 0;
      p{
        margin: 0;
      }
    }
    h4{
      margin: 7px 0;
      font-size: 16px;
    }
    .img img{
      border-radius: 5px;
    }

      .list-card-pic{
        background-color: lightgray;
          max-width: 400px;
          min-width: 300px;
          display: flex;
          border-radius: 5px;
          align-items: center;
          justify-content: center;
          height: 200px;
        .no-img-icon{
          font-size: 24px;
          display: flex;
          justify-content: center;
          align-content: center;
          padding-bottom: 5px;
        }
      }
  }
}

@include media("<=tablet") {
  .mobile-confirmation {
    display: flex;
    flex-direction: column;
  }

  .confirmation-main {
    margin: 10px;
  }

  .img-mobile {
    width: 100%;
  }
}
</style>

