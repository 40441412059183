<template>
  <div class="filters-wrapper points-filter justify-start">
    <div class="flexed filters-display justify-between">
      <el-checkbox
        v-model="enable_book_with_points"
        class="filter"
        @change="changeEnableBookWithPoints"
      >{{$t('lbl_enable_book_with_points')}}</el-checkbox>
      <p v-if="logged_in_user_details.member_points.redeemable" class="m-t-0 m-b-0">
        You have <strong> {{ logged_in_user_details.member_points.redeemable | formatBigNumber}}</strong> points
      </p>
    </div>

  </div>
</template>

<script>
import {mapActions, mapMutations,mapState} from "vuex";
export default {
  name: "points-filter",
  data(){
    return{
      enable_book_with_points: false
    }
  },
  beforeMount() {
    this.enable_book_with_points = this.enable_book_with_points_state
  },
  watch:{
    enable_book_with_points_state(newValue){
      this.enable_book_with_points = newValue
    }
  },
  computed: {
    ...mapState({
      enable_book_with_points_state: state => state.enable_book_with_points,
      logged_in_user_details: state => state.logged_in_user_details
    }),
  },
  methods:{
    ...mapMutations(['SET_ENABLE_BOOK_WITH_POINTS']),
    ...mapActions({ updateSelectedRoom: 'updateSelectedRoom' }),
    changeEnableBookWithPoints(){
      this.SET_ENABLE_BOOK_WITH_POINTS(this.enable_book_with_points)
      //this.updateSelectedRoom({ action:"REMOVE_ALL" })
      this.$emit('bookWithPointsStatus')
      this.$emit('setActiveTab')
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../../assets/sass/config/mixins";
@import "../../../../assets/sass/config/variables";
.filters-wrapper .filters-display {
    border: solid 1px #dadada;
    border-radius: 5px;
    padding: 12px;
    margin-top: 10px;
    font-weight: 400;
    font-size: 14px;
    align-items: center;
    min-height: 50px;
    .filter {
      font-weight: 300;
    }
}
@include media('<=tablet') {
  ::v-deep .el-radio__label {
    font-size: 16px;
  }
  .filters-wrapper .filters-display {
      margin: 15px 25px 0;
      padding: 7px;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      -webkit-box-align: center;
      -ms-flex-align: center;
      height: 55px !important;
    }
}
</style>
